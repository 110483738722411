import React, { createRef, useCallback, useState, useEffect, useRef } from 'react';
import { FilterParams, InitialStorageFilters } from './Filters';
import {
  ApiBestPricePackageModel,
  ApiGetFlightTripPackageRequestFromJSON,
  ApiItemType,
  ApiPromotionCodeFromJSON
} from '@ibe/api';
import { ListRow, ListRowItem, useApi, useAppService, useConfig } from '@ibe/components';
import Keys from '../../Translations/generated/product-ibe.de.json.keys';
import { itemListEvent } from '@ibe/services';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { TFunction } from 'react-i18next';
import { isValidPromotionCode, PromotionCodeData } from '../../Components/PromotionCode';
import { formatPrice } from '../Util/utils';
import useThgConfig from '../../Hooks/useThgConfig';

dayjs.extend(localeData);

export const productIBEAnchor = 'iso-product-ibe-list';

const AVAILABILITY_LIMIT = 10;

const getAvailability = (availability?: number): { value: string; class: string } => {
  if (!!availability && availability > AVAILABILITY_LIMIT) {
    return { value: `> ${AVAILABILITY_LIMIT}`, class: 'simple-list__cell__availability--level2' };
  } else if (!!availability && availability === AVAILABILITY_LIMIT) {
    return { value: `${availability}`, class: 'simple-list__cell__availability--level2' };
  } else if (!!availability && availability <= AVAILABILITY_LIMIT) {
    return { value: `${availability}`, class: 'simple-list__cell__availability--level1' };
  } else {
    return { value: `< ${AVAILABILITY_LIMIT}`, class: 'simple-list__cell__availability--level1' };
  }
};

const usePackagesListFunctions = (
  earliestPossibleDate: string,
  latestPossibleDate: string,
  packageCodes: Array<{ code: string; smallGroup: boolean }>,
  t: TFunction,
  promotionCode: PromotionCodeData,
  initialStorageFilters: InitialStorageFilters,
  numberOfTravelers?: number,
  maintenanceMode?: boolean
) => {
  const api = useApi();
  const appService = useAppService();
  const config = useConfig();
  const thgConfig = useThgConfig();
  const imgBaseUrl = thgConfig.baseUrl || config.translationsUrl;

  const [possibleOrigins, setPossibleOrigins] = useState<string[]>([]);
  const [packages, setPackages] = useState<ApiBestPricePackageModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialRequestFinished, setInitialRequestFinished] = useState<boolean>(false);
  const promotionCodeRef = useRef<PromotionCodeData>(promotionCode);

  const getPackages = useCallback(
    async (filters?: FilterParams): Promise<void> => {
      if (maintenanceMode || !packageCodes || (!!packageCodes && packageCodes.length === 0)) {
        return;
      }
      setIsLoading(true);
      let hasPackages = false;
      try {
        const response = await api.searchFlightTripPackages(
          ApiGetFlightTripPackageRequestFromJSON({
            startDate: filters?.startDate || earliestPossibleDate,
            endDate: filters?.endDate || latestPossibleDate,
            roomContainer: [
              {
                adults: filters?.numberOfTravelers || numberOfTravelers || 2,
                children: 0,
                infants: 0,
                childrenAges: [],
                infantsAges: []
              }
            ],
            origins: filters?.origins,
            packageCodes: packageCodes
              ?.filter(code => !filters?.smallGroup || (filters?.smallGroup && code.smallGroup))
              .map(code => ({
                packageCode: code.code,
                smallGroupOnly: code.smallGroup
              })),
            subType: ApiItemType.PACKAGE,
            promotionCode: isValidPromotionCode(promotionCode.promotionCodeData)
              ? ApiPromotionCodeFromJSON({
                  code: promotionCode.promotionCodeData.code,
                  percentageValue: promotionCode.promotionCodeData.percentageValue,
                  absoluteValue: promotionCode.promotionCodeData.absoluteValue
                })
              : undefined
          })
        );
        if (response?.packages) {
          setPackages(response.packages);
          itemListEvent.broadcast({ items: response.packages });

          if (
            response.packages.some(
              (singlePackage: ApiBestPricePackageModel) => !!singlePackage.origin
            )
          ) {
            setPossibleOrigins(
              response.packages
                .filter((singlePackage: ApiBestPricePackageModel) => !!singlePackage.origin)
                .map((singlePackage: ApiBestPricePackageModel) => singlePackage.origin)
            );
          }
          hasPackages = true;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        if (hasPackages) {
          setTimeout(() => {
            const { hash } = location;
            if (!!hash) {
              const anchor = document.getElementById(hash.substring(1));
              if (!!anchor) {
                anchor.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }, 300);
        }
      }
    },
    [api, promotionCode, packageCodes, maintenanceMode, numberOfTravelers]
  );

  const hasPromotionCodeChanged = (val: PromotionCodeData): boolean => {
    return JSON.stringify(val) !== JSON.stringify(promotionCodeRef.current);
  };
  useEffect(() => {
    (async () => {
      if (
        !!packageCodes &&
        promotionCode.initialPromotionCodeChecked &&
        initialStorageFilters.initialFiltersChecked &&
        (!initialRequestFinished || hasPromotionCodeChanged(promotionCode))
      ) {
        if (!!initialStorageFilters.initialFilters) {
          await getPackages({ ...initialStorageFilters.initialFilters });
        } else {
          await getPackages();
        }
        setInitialRequestFinished(true);
        promotionCodeRef.current = promotionCode;
      }
    })();
  }, [
    earliestPossibleDate,
    latestPossibleDate,
    packageCodes,
    promotionCode,
    maintenanceMode,
    initialRequestFinished,
    initialStorageFilters
  ]);

  const packagesMappingCallback = useCallback(
    (singlePackage: ApiBestPricePackageModel): ListRow<ListRowItem> => {
      return {
        id: singlePackage.id,
        code: singlePackage.code,
        specialRow: singlePackage.smallGroup,
        nodeRef: createRef<HTMLElement | undefined>(),
        item: {
          startDateImg: {
            value: '',
            imgSrc: `${imgBaseUrl}/img/calendar.svg`,
            hideInDesktop: true,
            className: 'simple-list__cell__startDate--img'
          },
          startDate: {
            value: !!singlePackage.startDate
              ? `${
                  dayjs().localeData().weekdaysShort()[dayjs(singlePackage.startDate).day()]
                } ${dayjs(singlePackage.startDate).format(
                  config.displayFormatDate[appService.lang.replace('-', '_')]
                )}`
              : '',
            className: 'simple-list__cell__startDate'
          },
          ...(packages.some(innerSinglePackage => innerSinglePackage.smallGroup)
            ? {
                smallGroup: {
                  value: '',
                  imgSrc: `${imgBaseUrl}/img/kleingruppe.svg`,
                  hideInMobile: false,
                  className: `simple-list__cell__small-group ${
                    singlePackage.smallGroup ? '' : 'no-smallgroup'
                  }`
                }
              }
            : {}),
          originImg: {
            value: '',
            imgSrc:
              singlePackage.typeCode === 'PFLUG'
                ? `${imgBaseUrl}/img/airplane.svg`
                : `${imgBaseUrl}/img/bus.svg`,
            hideInDesktop: true,
            className: 'simple-list__cell__origin--img'
          },
          origin: {
            value: singlePackage.originName || '',
            className: 'simple-list__cell__origin'
          },
          availabilityImg: {
            value: '',
            imgSrc: `${imgBaseUrl}/img/seat.svg`,
            hideInDesktop: true,
            className: 'simple-list__cell__availability--img'
          },
          availability: {
            value: getAvailability(singlePackage.availability).value,
            className: `simple-list__cell__availability ${
              getAvailability(singlePackage.availability).class
            }`
          },
          price: {
            value: (
              <>
                <div
                  className={
                    isValidPromotionCode(promotionCode.promotionCodeData)
                      ? 'simple-list__cell__price__reduced'
                      : 'simple-list__cell__price__normal'
                  }
                >
                  {t(Keys.from)}{' '}
                  {!!singlePackage.price?.finalPrice
                    ? formatPrice(singlePackage.price.finalPrice)
                    : 0}
                </div>{' '}
                {isValidPromotionCode(promotionCode.promotionCodeData) && (
                  <div className={'simple-list__cell__price__striked'}>
                    {t(Keys.instead)}{' '}
                    <s>
                      {promotionCode.promotionCodeData?.absoluteValue
                        ? formatPrice(
                            singlePackage.price.finalPrice +
                              promotionCode.promotionCodeData.absoluteValue
                          )
                        : promotionCode.promotionCodeData?.percentageValue
                        ? formatPrice(
                            (singlePackage.price.finalPrice /
                              promotionCode.promotionCodeData.percentageValue) *
                              100
                          )
                        : 0}
                    </s>
                  </div>
                )}
              </>
            ),
            className: 'simple-list__cell__price'
          }
        }
      };
    },
    [packages]
  );

  return {
    packages,
    isLoading,
    possibleOrigins,
    getPackages,
    packagesMappingCallback
  };
};

export default usePackagesListFunctions;
