import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import {
  CustomInput,
  FormRef,
  Overlay,
  OverlayContainer,
  ScrollerService,
  useApi,
  useBookingService,
  useConfig,
  useTranslation
} from '@ibe/components';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router';
import { useMount } from 'react-use';
import {
  ApiBaseData,
  ApiChangeComponentsRequest,
  ApiComponentType,
  ApiInsurance,
  ApiItemType,
  ApiPaymentOption,
  ApiPreparePaymentRequestFromJSON,
  ApiTraveler,
  ApiTravelerToJSON,
  ApiTravelerType
} from '@ibe/api';
import {
  checkoutStepEvent,
  LoggerFactory,
  MaskBookingService,
  SessionStorage
} from '@ibe/services';
import { CheckoutStep, CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import ContentContainer from '../../../Components/ContentContainer';
import ProgressbarWrapper from './ProgressBarWrapper';
import CheckoutPageUrl from '../CheckoutPageUrl';
import { FormItemsProps } from '../../../Config/useTravellerFormConfigProductIBE';
import buildValidationSchema, { getAge } from './externals';
import BookingNoteAndTermsWrapper, {
  RenderTermsElementPropsType,
  TermType
} from './BookingNoteAndTerms';
import TravelerForms from './TravelerForms';
import ErrorWrapper from './ErrorWrapper';
import PageUrl from '../../../Models/PageUrl';
import StickySummary from './StickySummary';
import { SessionStorageType } from '../../../Components/Search';
import { productSearchSessionKey, productSearchSessionTempKey } from '../../../Config/config';
import { FlightAndAccommodationSummaryHeader } from './FlightAndAccommodationSummary';
import PaymentPopUp from './PaymentPopUp';
import useThgConfig from '../../../Hooks/useThgConfig';
import {
  Api,
  ApiNewsletterSubscriptionRequest,
  ApiNewsletterSubscriptionRequestToJSON
} from '../../../../api';
import useSearchApi from '../../../Util/useSearchApi';
import type { ValidationResult } from './LoyaltyCard';
import LoyaltyCard from './LoyaltyCard';
import { useAffiliateContext } from '../../../Util/AffiliateProvider';
import { TAXI_30KM_CODE, travelersSameAddressForms } from '../../Util/utils';
import { Element as ScrollTo } from 'react-scroll';
import type { ApiInsuranceUnitExtended } from './Insurance/InsuranceContainer';
import InsuranceContainer from './Insurance/InsuranceContainer';

const logger = LoggerFactory.get('Traveler');
const scrollElementError = 'travelerErrorScroller';
const scrollLoyaltyCardError = 'scrollLoyaltyCardError';
const scrollInsuranceError = 'scrollInsuranceError';
export const ibeFlightClassName = 'ibe__flight';
export const scrollElementErrorTravelerBase = 'travelerErrorScroller';
const productSessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);
const sessionStorageTemp = new SessionStorage<SessionStorageType>(productSearchSessionTempKey);

const Traveler = observer(function Traveler(props: CheckoutStepComponentProps) {
  const { store, rerouteUrl, navBarOffset, productIBEMode, isWhitelabel } = props;
  const { t } = useTranslation('Checkout', fallback);
  const config = useConfig();
  const navigate = useNavigate();
  const api = useApi();
  const bs = useBookingService();
  const booking = bs.booking;
  const bookingTravelers = booking?.travelers;
  const formRefs = useRef<Array<FormRef<Record<string, unknown>>>>([]);
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined);
  const [bookedClicked, setBookedClicked] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<string | null>(null);
  const [cardValid, setCardValid] = useState<ValidationResult>('NOT_SET');
  const [error, setError] = useState<string | undefined>(undefined);
  const [travelers, setTravelers] = useState<ApiTraveler[]>(bookingTravelers || []);
  const [sameAddressForms, setSameAddressForms] = useState<ApiTraveler[]>(
    travelersSameAddressForms.get() || []
  );
  const [externalData, setExternalData] = useState<{
    COUNTRIES: ApiBaseData[];
    SALUTATIONS: ApiBaseData[];
    NATIONALITIES: ApiBaseData[];
  }>({ COUNTRIES: [], SALUTATIONS: [], NATIONALITIES: [] });

  const [showInsuranceComponent, setShowInsuranceComponent] = useState<boolean>(false);
  const [skipReloadInsurances, setSkipReloadInsurances] = useState<boolean>(false);

  const firstAdult = useMemo(() => {
    return travelers.find(traveler => traveler.type === ApiTravelerType.ADULT) || travelers[0];
  }, [travelers]);
  const searchApi = useSearchApi();

  const showLoyaltyCard = !!useAffiliateContext().metaContent?.content?.loyaltyCard;

  store.setTravelerAssignmentValidationSchema(buildValidationSchema(bs, t));
  const selectedPostCode = sessionStorage.getItem('selectedPostCode');
  const busStopSelection = sessionStorage.getItem('busStopSelection');

  useMount(async () => {
    if (!booking || !booking.bookedItems || booking.bookedItems.length === 0) {
      navigate(PageUrl.HOME);
    }

    const response = await store.loadExternalData();
    setExternalData({
      COUNTRIES: response.countries,
      SALUTATIONS: response.salutations,
      NATIONALITIES: response.nationalities
    });

    if (config.outsideElementsContainerId) {
      ScrollerService.scrollTo(config.outsideElementsContainerId, { offset: -100 });
    }
  });

  useEffect(() => {
    (async () => {
      if (!skipReloadInsurances && bs.packageCart && bs.packageCart.packageModel.packageDetails) {
        const insuranceComp = bs.packageCart.packageModel.packageDetails[0].components.find(
          c => c.itemType === ApiItemType.INSURANCE && c.componentType === ApiComponentType.OPTIONAL
        );
        if (insuranceComp) {
          try {
            const request: ApiChangeComponentsRequest = {
              startDate: insuranceComp.startDate,
              modifiedComponentId: insuranceComp.id,
              checkComponentIds: [insuranceComp.id]
            };

            await bs.reloadComponents(request);

            const reloadedInsuranceComp =
              bs.packageCart &&
              bs.packageCart.packageModel.packageDetails &&
              bs.packageCart.packageModel.packageDetails[0].components.find(
                c =>
                  c.itemType === ApiItemType.INSURANCE &&
                  c.componentType === ApiComponentType.OPTIONAL
              );

            const apiInsurance = reloadedInsuranceComp?.selectableItems as ApiInsurance[];

            let hasNoInsurance = false,
              hasWithDeductible = false,
              hasWithoutDeductible = false;

            apiInsurance.forEach(i =>
              i.units.forEach(u => {
                const extendedMetaContent = (u as ApiInsuranceUnitExtended).metaInfo
                  ?.contentByMetaType?.insurance?.content;

                if (extendedMetaContent) {
                  hasNoInsurance = extendedMetaContent.isNoInsurance ? true : hasNoInsurance;
                  hasWithoutDeductible = extendedMetaContent.isRecommendation
                    ? true
                    : hasWithoutDeductible;
                  hasWithDeductible =
                    extendedMetaContent.isRecommendation && !extendedMetaContent.isNoInsurance
                      ? true
                      : hasWithDeductible;
                }
              })
            );

            setShowInsuranceComponent(hasNoInsurance && hasWithDeductible && hasWithoutDeductible);
          } catch (err) {
            logger.log(err);
            setShowInsuranceComponent(false);
          }
        } else {
          setShowInsuranceComponent(false);
        }
      }

      // set to true when selecting an insurance, see InsuranceContainer clickAction
      if (skipReloadInsurances) {
        setSkipReloadInsurances(false);
      }
    })();
  }, [bs.booking?.price.finalPrice]);

  useEffect(() => {
    (async () => {
      const firstTraveler = bs.booking?.travelers.find(tr => tr.id === '1');

      // delete success & error messages on newly entered card number
      // keep the number mismatch warning until "Anwenden" is clicked
      if (cardValid !== 'NOT_SET' && cardValid !== 'NUMBER_MISMATCH') {
        setCardValid('NOT_SET');
      }

      // input clear was clicked && it's not already empty -> update traveler loyaltyCard number
      if (cardNumber === '' && firstTraveler && firstTraveler.bonusCardNumber !== '') {
        await updateLoyaltyCardNumber();
      }
    })();
  }, [cardNumber]);

  const scrollToError = (errorId: string): void => {
    ScrollerService.scrollTo(errorId, { offset: -100 });
  };

  const handleBookingError = (errorMessage: string) => {
    setError(errorMessage);
    scrollToError(scrollElementError);
  };
  const { handleError, terms, toggleCheckBox } = useTermAndLegal();

  const legalChecked = !!terms.find(term => term.type === 'legal')?.checked;
  const legalError = terms.find(term => term.type === 'legal')?.error;
  const emailChecked = !!terms.find(term => term.type === 'email')?.checked;
  const globistaChecked = !!terms.find(term => term.type === 'globista')?.checked;

  const book = async (): Promise<void> => {
    const formResults = (
      await Promise.all(formRefs.current.map(ref => ref?.triggerSubmit()))
    ).filter(item => !!item);

    if (showInsuranceComponent) {
      setBookedClicked(true);
      const bookedInsurance = bs.booking?.bookedItems.find(
        item =>
          ApiItemType.INSURANCE === item.itemType &&
          ApiComponentType.OPTIONAL === item.componentType
      );

      if (!bookedInsurance) {
        scrollToError(scrollInsuranceError);
        return;
      }
    }

    const traveler1 = bs.booking?.travelers.find(tr1 => tr1.id === '1');
    if (showLoyaltyCard && traveler1 && traveler1.bonusCardNumber !== cardNumber) {
      setCardValid('NUMBER_MISMATCH');
      scrollToError(scrollLoyaltyCardError);
      return;
    }

    if (!legalChecked) {
      handleError('legal', t(Keys.summaryAcceptTaCError));
    }
    if (formResults.some(result => Object.keys(result).length === 0)) {
      const index = formResults.findIndex(result => Object.keys(result).length === 0);
      scrollToError(scrollElementErrorTravelerBase + index);
      return;
    }
    if (!legalChecked) {
      scrollToError(scrollElementError);
      return;
    }

    const firstAdultResult =
      formResults.find(result => result.id === firstAdult.id) || formResults[0];
    const finalResults = formResults.map(result => {
      if (!!sameAddressForms.find(form => form.id === result.id)) {
        return {
          ...result,
          street: firstAdultResult.street,
          city: firstAdultResult.city,
          zipCode: firstAdultResult.zipCode,
          country: firstAdultResult.country
        };
      } else {
        return { ...result };
      }
    });

    if (selectedPostCode && busStopSelection && JSON.parse(busStopSelection) === TAXI_30KM_CODE) {
      const firstAdultResultForPostCode =
        finalResults?.length > 1 ? (finalResults[1] as FormItemsProps) : undefined;
      const useFirstAddress = !!sameAddressForms.find(
        form => form.id === firstAdultResultForPostCode?.id
      );
      const parsedPostCode: ApiBaseData = JSON.parse(selectedPostCode);
      if (
        useFirstAddress &&
        firstAdultResultForPostCode &&
        firstAdultResultForPostCode.zipCode !== parsedPostCode.code
      ) {
        handleBookingError(t(Keys.errorInvalidPostCode));
        return;
      } else {
        const travelerPostCodeMismatch = finalResults.some(
          tr => tr.zipCode !== parsedPostCode.code
        );
        if (travelerPostCodeMismatch) {
          handleBookingError(t(Keys.errorInvalidPostCode));
          return;
        } else {
          setError(undefined);
        }
      }
    }

    const AllTravelers: ApiTraveler[] = finalResults.map(item => {
      const result = item as FormItemsProps;
      const travelerInfoInBooking = travelers.find(
        (traveler: ApiTraveler) => traveler.id === result.id
      );
      if (travelerInfoInBooking) {
        return ApiTravelerToJSON({
          ...travelerInfoInBooking,
          title: result.title,
          birthDate: result.birthDate,
          age: getAge(result.birthDate),
          salutation: result.salutation,
          communicationDetails: {
            phone: result.phone,
            email: result.email
          },
          firstName: result.firstname,
          lastName: result.lastname,
          nationality: result.nationality,
          address: {
            city: result.city,
            countryCode: result.country,
            postalCode: result.zipCode,
            street: result.street
          }
        });
      }
    });

    setLoading(true);
    checkoutStepEvent.broadcast({
      booking: MaskBookingService.maskBooking(bs.booking),
      item: null,
      step: t(Keys.participant)
    });
    try {
      await bs.updateTravelers(AllTravelers);

      const promisesReq = [];
      if (promoCode) promisesReq.push(bs.addPromoCodesToBooking([promoCode]));
      if (emailChecked)
        promisesReq.push(
          subscribeToNewsletter(searchApi, AllTravelers, externalData.SALUTATIONS, '/booking')
        );
      if (globistaChecked) {
        promisesReq.push(
          subscribeToNewsletter(searchApi, AllTravelers, externalData.SALUTATIONS, 'GLOBISTA')
        );
      }
      await Promise.all(promisesReq);

      setError(undefined);
      let url;
      if (bs && bs.booking && bs.booking.id) {
        url = await api.preparePayment(
          bs.booking.id,
          ApiPreparePaymentRequestFromJSON({
            isDeposit: false,
            paymentMethod: ApiPaymentOption.INVOICE
          })
        );
      }
      if (url) {
        navigate(url);
      }
    } catch (e) {
      handleBookingError(t(Keys.SummaryBookingError));
    } finally {
      setLoading(false);
    }
  };

  const goBack = async (): Promise<void> => {
    await updateTravelers();

    navigate(
      productIBEMode?.bookingHasComponents ? CheckoutPageUrl.EXTRAS : CheckoutPageUrl.SUMMARY
    );
  };

  const switchSessionData = (sessionToTemp: boolean) => {
    const copyFrom = sessionToTemp ? productSessionStorage : sessionStorageTemp;
    const copyTo = sessionToTemp ? sessionStorageTemp : productSessionStorage;
    const session = copyFrom.get();
    if (!!session) {
      copyTo.set(session);
      copyFrom.clear();
    }
  };

  const handleProgressbarClick = async (step: CheckoutStep): Promise<void> => {
    await updateTravelers();

    if (step.slug === 'packages') {
      switchSessionData(false);
    }
  };

  const updateLoyaltyCardNumber = async (): Promise<void> => {
    setLoading(true);

    const firstTrav = travelers.find(firstT => firstT.id === '1');

    if (firstTrav) {
      const newTrav = { ...firstTrav, bonusCardNumber: cardNumber } as ApiTraveler;

      await bs.updateTraveler(newTrav);

      if (cardNumber !== '') {
        setCardValid(bs.hasError ? 'ERROR' : 'VALID');
      } else {
        setCardValid('NOT_SET');
      }

      setLoading(false);
    }
  };

  const updateTravelers = async (): Promise<void> => {
    try {
      setLoading(true);
      await bs.updateTravelers([...travelers]);
    } catch (err) {
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    travelersSameAddressForms.set(sameAddressForms);
  }, [sameAddressForms]);

  return (
    <div className={`checkout-workflow ${ibeFlightClassName}`}>
      <div className="d-flex justify-content-between mb-4 checkout-nav">
        <Button
          color="secondary"
          className="d-block d-md-none"
          onClick={(): Promise<void> => goBack()}
        >
          {t(Keys.back)}
        </Button>
      </div>
      <ProgressbarWrapper
        onClick={handleProgressbarClick}
        store={store}
        rerouteUrl={rerouteUrl}
        isWhitelabel={isWhitelabel}
      />
      <Row>
        <Col md={8} sm={12} className=" traveler-sticky-main-content">
          <div className="checkout_main_container ">
            <div className="form-module">
              <OverlayContainer>
                <div className="checkout_main_container--shadow">
                  {bs?.booking?.id && (
                    <FlightAndAccommodationSummaryHeader store={store} booking={booking} />
                  )}
                  <TravelerForms
                    travelers={travelers}
                    setTravelers={setTravelers}
                    formRefs={formRefs}
                    sameAddressForms={sameAddressForms}
                    setSameAddressForms={setSameAddressForms}
                    bs={bs}
                    externalData={externalData}
                    initialValues={bookingTravelers?.map((traveler: ApiTraveler) => ({
                      title: traveler.title ?? '',
                      firstname: traveler.firstName ?? '',
                      lastname: traveler.lastName ?? '',
                      salutation: traveler.salutation ?? '',
                      birthDate: traveler.birthDate ?? '',
                      nationality: traveler.nationality ?? '',
                      city: traveler.address?.city ?? '',
                      country: traveler.address?.countryCode ?? '',
                      street: traveler.address?.street ?? '',
                      zipCode: traveler.address?.postalCode ?? '',
                      email: traveler.communicationDetails?.email ?? '',
                      phone: traveler.communicationDetails?.phone ?? ''
                    }))}
                  />
                </div>
                {showInsuranceComponent && (
                  <ScrollTo name={scrollInsuranceError}>
                    <ContentContainer>
                      <InsuranceContainer
                        bookedClicked={bookedClicked}
                        clickAction={setSkipReloadInsurances}
                      />
                    </ContentContainer>
                  </ScrollTo>
                )}
                {showLoyaltyCard && (
                  <ScrollTo name={scrollLoyaltyCardError}>
                    <LoyaltyCard
                      cardNumber={cardNumber}
                      setCardNumber={setCardNumber}
                      onSubmit={updateLoyaltyCardNumber}
                      cardValid={cardValid}
                    />
                  </ScrollTo>
                )}
                <ContentContainer className="paymentOptions">
                  <div className="traveler-header font-weight-bold mb-5">
                    {t(Keys.paymentOptions)}
                  </div>
                  <Row>
                    <Col className={'col-12'}>
                      <ContentContainer className="paymentOptions__container">
                        <CustomInput
                          type="radio"
                          checked={true}
                          label={t(Keys.invoice)}
                          id="invoice"
                          name="payment"
                          readOnly
                          className={'iso__checkout__paymentOption'}
                        >
                          &nbsp;&nbsp;
                          <PaymentPopUp />
                        </CustomInput>
                      </ContentContainer>
                    </Col>
                    <Col />
                  </Row>
                </ContentContainer>

                <BookingNoteAndTermsWrapper terms={terms} toggleCheckBox={toggleCheckBox} />
                <div className="d-none justify-content-between d-md-flex checkout-nav">
                  <Button color="secondary" onClick={(): Promise<void> => goBack()}>
                    {t(Keys.back)}
                  </Button>
                  <Button className="ml-2" color="primary" onClick={(): Promise<void> => book()}>
                    {t(Keys.book)}
                  </Button>
                </div>
                {(bs.isBusy || loading) && <Overlay />}
              </OverlayContainer>
              {legalError || bs.hasError || error ? (
                <ErrorWrapper
                  hasTermError={legalError}
                  error={error}
                  scrollElementError={scrollElementError}
                />
              ) : null}
            </div>
          </div>
        </Col>
        <Col sm={12} md={4} className="traveler-sticky-summary-wrapper">
          {bs.booking && (
            <StickySummary
              loading={bs.isBusy}
              booking={bs.booking}
              next={(): Promise<void> => book()}
              rightBottomText={t(Keys.book)}
              disabled={false}
              navBarOffset={navBarOffset}
              productIBEMode={productIBEMode}
              showPromotionCode
              handleBookingError={handleBookingError}
              travelers={
                travelers.some(traveler => !!traveler.firstName || !!traveler.lastName)
                  ? travelers
                  : bookingTravelers
              }
              isWhitelabel={isWhitelabel}
              store={store}
            />
          )}
        </Col>
      </Row>
    </div>
  );
});

export default Traveler;

const useTermAndLegal = () => {
  const { t } = useTranslation('Checkout', fallback);
  const thgConfig = useThgConfig();
  const showGlobista = !!useAffiliateContext().metaContent?.content?.globistaNewsletter;
  const [terms, setTerms] = useState<RenderTermsElementPropsType[]>([]);

  const toggleCheckBox = (a: TermType, value: boolean) => {
    setTerms(
      terms.map(term => {
        if (term.type !== a) return term;
        return { ...term, checked: value, error: undefined };
      })
    );
  };

  const handleError = (a: TermType, value?: string) => {
    setTerms(
      terms.map(term => {
        if (term.type !== a) return term;
        return { ...term, error: value };
      })
    );
  };

  useEffect(() => {
    setTerms([
      {
        type: 'email',
        title: t(Keys.summaryEmailMarketingTitle),
        text: `${t(
          Keys.summaryEmailMarketingText
        )}<a target="_blank" class="font-weight-bold" href="${thgConfig.baseUrl}${t(
          Keys.dataSecurityLink
        )}">${t(Keys.summaryEmailMarketingText2)}</a>`,
        checked: false,
        error: undefined,
        showCheckbox: true
      },
      ...(showGlobista
        ? [
            {
              type: 'globista',
              title: t(Keys.summaryGlobistaMarketingTitle),
              text: `${t(
                Keys.summaryGlobistaMarketingText
              )}<a target="_blank" class="font-weight-bold" href="${
                thgConfig.globistaDataSecurityLink
              }">${t(Keys.summaryGlobistaMarketingText2)}</a>`,
              checked: false,
              error: undefined,
              showCheckbox: true
            }
          ]
        : []),
      {
        type: 'legal',
        title: t(Keys.summaryTermsAndConditions),
        text: `${t(Keys.summaryAcceptTaC)} <a class="font-weight-bold" target="_blank" href="${
          thgConfig.baseUrl
        }${t(Keys.summaryTaCLink)}">${t(Keys.summaryTaCLinkText)}</a> ${t(Keys.summaryAcceptTac2)}`,
        checked: false,
        error: undefined,
        showCheckbox: true
      }
    ] as RenderTermsElementPropsType[]);
  }, [showGlobista]);

  return { terms, toggleCheckBox, handleError };
};

export const subscribeToNewsletter = (
  searchApi: Api,
  travelers: ApiTraveler[],
  salutations: ApiBaseData[],
  signUpSource: string
) => {
  const firstTraveler = travelers[0];
  return searchApi.subscribe(
    ApiNewsletterSubscriptionRequestToJSON({
      title: firstTraveler?.title,
      emailAddress: firstTraveler?.communicationDetails?.email,
      firstName: firstTraveler.firstName,
      lastName: firstTraveler.lastName,
      salutation: (
        salutations.find(salutation => salutation.code === firstTraveler.salutation)?.description ||
        ''
      ).trim(),
      signUpSource: signUpSource
    } as ApiNewsletterSubscriptionRequest)
  );
};
