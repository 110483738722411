import React from 'react';
import {
  ApiBookedItem,
  ApiBooking,
  ApiComponentType,
  ApiDurationType,
  ApiHotel,
  ApiImageContainer,
  ApiItemType
} from '@ibe/api';
import { BookingService, sanitize } from '@ibe/services';
import { useAppService, useBookingService, useConfig, useTranslation } from '@ibe/components';
import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@emotion/react';
import { Col } from 'reactstrap';
import { extendedBookingStorage, SelectedItemsType } from './ExtendedBooking/ContextAndProvider';
import CheckoutStore from '../CheckoutStore';

const FlightAndAccommodationSummaryHeader = ({
  selectedItems,
  store,
  booking,
  isWhiteLabel
}: {
  booking?: ApiBooking;
  selectedItems?: SelectedItemsType;
  store: CheckoutStore;
  isWhiteLabel?: boolean;
}) => {
  const bs = useBookingService();
  if (!bs?.bookedItems && !isWhiteLabel) return <></>;
  const { url, altText, dateToShow, description, name, location } = useFlightAccommodationHeader(
    store,
    booking,
    selectedItems || extendedBookingStorage.get()
  );

  return (
    <div className="iso__flight-and-accommodation d-block d-md-flex">
      <Col sm={12} md={'auto'} className="iso__flight-and-accommodation__img__col">
        <img src={url} className="iso__flight-and-accommodation__img" alt={altText || ''} />
      </Col>
      <Col sm={12} md={'auto'}>
        <div className="iso__flight-and-accommodation__header">
          <h2 dangerouslySetInnerHTML={{ __html: sanitize(name || '') }} />
          <p>{location}</p>
          {!isWhiteLabel && (
            <div className="iso__flight-and-accommodation__header__box">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>{dateToShow}</span>
              <span>{description}</span>
            </div>
          )}
        </div>
      </Col>
    </div>
  );
};

const useFlightAccommodationHeader = (
  store: CheckoutStore,
  booking?: ApiBooking,
  selectedItems?: SelectedItemsType
) => {
  const { t } = useTranslation('Checkout', fallback);
  const { duration } = selectedItems || {};
  const { extensionDuration } = duration || {};
  const theme = useTheme();
  const appService = useAppService();
  const config = useConfig();
  const dateFormat = config.displayFormatDate[appService.lang.replace('-', '_')];
  let bs = !!booking ? booking : useBookingService();

  const packageItem = bs?.bookedItems?.find(
    (it: ApiBookedItem) => it.itemType === ApiItemType.PACKAGE
  ) as ApiBookedItem;

  bs = useBookingService() as BookingService;
  const packageDetails = bs?.packageCart?.packageModel.packageDetails || [];
  const hotelComponents = packageDetails
    .flatMap(detail => detail.components)
    .filter(comp => comp.itemType === ApiItemType.HOTEL);
  const mainCompHotel = hotelComponents.find(hotel => hotel.componentType === ApiComponentType.MAIN)
    ?.selectedItems[0] as ApiHotel;
  let mainCompDuration = mainCompHotel ? mainCompHotel.durationInfo?.duration : -1;

  if (mainCompHotel && mainCompHotel.durationInfo?.type === ApiDurationType.NIGHT) {
    mainCompDuration += 1;
  }

  const seasonContent = packageItem?.metaInfo?.contentByMetaType?.season?.content;
  const apiImage = seasonContent?.image as ApiImageContainer | undefined;
  const image = apiImage?.small || apiImage?.extraSmall || apiImage?.large || apiImage?.extraLarge;
  const country = (seasonContent?.seasonCountry as { countryName: string } | undefined)
    ?.countryName as string | undefined;

  const altText = store?.hotelInformation?.image?.altText || image?.altText;
  const name = ((seasonContent?.productName as unknown) as string) || store?.hotelInformation?.name;
  const url = store?.hotelInformation?.image?.url || image?.url || theme?.placeholderImages.hotel;
  const location = store?.hotelInformation?.country || country;

  const overlappingDay = 1;

  const isItemsDuration =
    duration &&
    extensionDuration &&
    (selectedItems?.steps === undefined ||
      selectedItems?.steps?.some(s => s.isSuccess == false && s.type === undefined)) &&
    extensionDuration > 0;

  const { startDate: travelStartDate, endDate: travelEndDate } = packageItem || {};
  const mainDurationBooking =
    dayjs.duration(dayjs(travelEndDate).diff(dayjs(travelStartDate))).days() + 1;

  const packageTotalDuration = isItemsDuration
    ? mainCompDuration + (extensionDuration ? extensionDuration - overlappingDay : 0 || 0)
    : mainDurationBooking;

  const dateToShow = isItemsDuration
    ? `${dayjs(mainCompHotel.start).format(dateFormat)} - ${
        extensionDuration
          ? dayjs(mainCompHotel.end)
              .subtract(overlappingDay, 'day')
              .add(extensionDuration, 'days')
              .format(dateFormat)
          : dayjs(mainCompHotel.end).format(dateFormat)
      }`
    : packageItem
    ? `${dayjs(packageItem.startDate).format(dateFormat)} - ${dayjs(packageItem.endDate).format(
        dateFormat
      )}`
    : '-1';

  const packageTotalDurationWithLabel = `${packageTotalDuration} 
    ${t(Keys.day, {
      count: packageTotalDuration
    })}`;

  let defaultDurationLabel = '';
  if (isItemsDuration) {
    if (extensionDuration) {
      defaultDurationLabel = `(${mainCompDuration} ${t(Keys.day, {
        count: mainCompDuration
      })} ${t(Keys.standardTripDuration)} + ${extensionDuration - overlappingDay} ${t(
        Keys.numberOfNight,
        {
          count: extensionDuration
        }
      )} ${t(Keys.extension)})`;
    } else {
      defaultDurationLabel = `(${mainCompDuration} ${t(Keys.day, {
        count: mainCompDuration
      })} ${t(Keys.standardTripDuration)})`;
    }
  } else {
    defaultDurationLabel = `(${mainDurationBooking} ${t(Keys.day, {
      count: mainDurationBooking
    })} ${t(Keys.standardTripDuration)})`;
  }

  return {
    altText,
    name,
    location,
    url: url,
    dateToShow,
    description: `, ${packageTotalDurationWithLabel} ${defaultDurationLabel}`
  };
};

export { FlightAndAccommodationSummaryHeader, useFlightAccommodationHeader };
