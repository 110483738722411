import {
  Overlay,
  OverlayContainer,
  ScrollerService,
  useBookingService,
  useConfig,
  useTranslation
} from '@ibe/components';
import {
  bookingItemAddedEvent,
  bookingItemRemovedEvent,
  BookingService,
  checkoutStartedEvent,
  checkoutStepEvent,
  MaskBookingService,
  SessionStorage
} from '@ibe/services';
import { observer } from 'mobx-react';
import { useMount } from 'react-use';
import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { workflowContext } from '../../../contexts';
import HorizontalSpread from '../../../Layouts/Helper/HorizontalSpread';
import { CheckoutStep, CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import ProgressbarWrapper from './ProgressBarWrapper';
import useUrlPersistence from '../../Util/useUrlPersistence';
import StickySummary, { StickySummaryProps } from './StickySummary';
import { SessionStorageType } from '../../../Components/Search';
import { productSearchSessionKey, productSearchSessionTempKey } from '../../../Config/config';
import { ApiBookedItem } from '@ibe/api';
import { productDetailsPageUrlStorage } from '../../Util/utils';
import ExtendedBooking from './ExtendedBooking/ExtendedBooking';
import { extendedBookingStorage, useExtendedBooking } from './ExtendedBooking/ContextAndProvider';
import { isValidWorkflow } from './ExtendedBooking/workflowValidation';

const sessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);
const sessionStorageTemp = new SessionStorage<SessionStorageType>(productSearchSessionTempKey);

export const trackAddedOrRemovedItem = (
  oldBookedItems: ApiBookedItem[] | null,
  newBookedItems: ApiBookedItem[] | null,
  bs: BookingService
): void => {
  if (!!oldBookedItems && !!newBookedItems) {
    if (oldBookedItems.length > newBookedItems.length) {
      const removedItem = oldBookedItems.find(
        item => !newBookedItems.find(innerItem => innerItem.id === item.id)
      );
      if (!!removedItem) {
        bookingItemRemovedEvent.broadcast({ booking: bs.booking, item: removedItem });
      }
    } else if (oldBookedItems.length < newBookedItems.length) {
      const addedItem = newBookedItems.find(
        item => !oldBookedItems.find(innerItem => innerItem.id === item.id)
      );
      if (!!addedItem) {
        bookingItemAddedEvent.broadcast({ booking: bs.booking, item: addedItem });
      }
    }
  }
};

const Summary = observer(function Summary(props: CheckoutStepComponentProps) {
  const { store, rerouteUrl, navBarOffset, productIBEMode, isWhitelabel } = props;
  const bs = useBookingService();
  const config = useConfig();
  const { t } = useTranslation('Checkout', fallback);
  const sessionPersistence = useUrlPersistence();

  const history = useHistory();
  const workflow = useContext(workflowContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const switchSessionData = (sessionToTemp: boolean) => {
    const copyFrom = sessionToTemp ? sessionStorage : sessionStorageTemp;
    const copyTo = sessionToTemp ? sessionStorageTemp : sessionStorage;
    const session = copyFrom.get();
    if (!!session) {
      copyTo.set(session);
      copyFrom.clear();
    }
  };

  useMount(async () => {
    checkoutStartedEvent.broadcast({
      booking: MaskBookingService.maskBooking(bs.booking),
      item: null
    });

    if (config.outsideElementsContainerId) {
      ScrollerService.scrollTo(config.outsideElementsContainerId, { offset: -100 });
    }
    switchSessionData(true);
  });

  const prev = (): void => {
    switchSessionData(false);

    if (!!isWhitelabel) {
      productDetailsPageUrlStorage.clear();
    }

    if (!!productDetailsPageUrlStorage.get()) {
      if (!extendedBookingStorage.get()) {
        const url = productDetailsPageUrlStorage.get();
        productDetailsPageUrlStorage.clear();
        if (!!url) {
          location.href = url;
        }
      } else {
        const detailsUrl = productDetailsPageUrlStorage.get();
        if (!!detailsUrl) {
          location.href = detailsUrl;
        }
      }
    } else if (!!rerouteUrl && !isWhitelabel) {
      location.href = rerouteUrl;
    } else {
      const url = sessionPersistence.get()?.url;
      history.push(url || workflow.prevStepSlug || '');
    }
  };

  const next = async (): Promise<void> => {
    checkoutStepEvent.broadcast({
      booking: MaskBookingService.maskBooking(bs.booking),
      item: null,
      step: t(Keys.summary)
    });
    history.push(workflow.nextStepSlug || '');
  };

  const handleProgressbarClick = (step: CheckoutStep): void => {
    if (step.slug === 'packages') {
      switchSessionData(false);
    }
  };

  const TopNav = () => (
    <HorizontalSpread className="checkout-nav">
      <Button color="secondary" className="mb-4 d-block d-md-none" onClick={(): void => prev()}>
        {t(Keys.prev)}
      </Button>
    </HorizontalSpread>
  );

  return (
    <OverlayContainer className="checkout-workflow">
      <TopNav />
      <ProgressbarWrapper
        onClick={handleProgressbarClick}
        store={store}
        rerouteUrl={rerouteUrl}
        isWhitelabel={isWhitelabel}
      />
      {bs.booking && (
        <ExtendedBooking
          loading={bs.isBusy || loading}
          booking={bs.booking}
          next={next}
          disabled={!!error}
          productIBEMode={productIBEMode}
          store={store}
          showPromotionCode
          isWhitelabel={isWhitelabel}
          render={extendedBookingComponent => {
            return (
              <Row>
                <Col md={8} xs={12} className="traveler-sticky-main-content">
                  <div className="checkout_main_container">
                    {extendedBookingComponent}
                    <Nav prev={prev} next={next} />
                  </div>
                </Col>
                <Col sm={12} md={4} className="traveler-sticky-summary-wrapper">
                  {bs.booking && (
                    <StickySummaryWrapper
                      loading={bs.isBusy || loading}
                      booking={bs.booking}
                      next={next}
                      disabled={!!error}
                      navBarOffset={navBarOffset}
                      productIBEMode={productIBEMode}
                      showPromotionCode
                      hideMobileBar
                      isWhitelabel={isWhitelabel}
                    />
                  )}
                </Col>
              </Row>
            );
          }}
        ></ExtendedBooking>
      )}
      {(bs.isBusy || loading) && <Overlay />}
    </OverlayContainer>
  );
});

export default Summary;

const Nav = ({ prev, next }: { prev: () => void; next: () => void }): JSX.Element => {
  const { selectedItems } = useExtendedBooking();
  const { t } = useTranslation('Checkout', fallback);
  const bs = useBookingService();

  const isDisabled: boolean = useMemo(() => {
    return !isValidWorkflow(selectedItems);
  }, [selectedItems]);

  return (
    <OverlayContainer className="checkout-nav">
      <div className="mt-6 mb-3 extended__booking__checkout__nav">
        <HorizontalSpread>
          <Button color="secondary" className="d-none d-md-block" onClick={prev}>
            {t(Keys.prev)}
          </Button>
          <Button
            color="primary"
            onClick={next}
            className="d-none d-md-block"
            disabled={isDisabled}
          >
            {t(Keys.next)}
          </Button>
        </HorizontalSpread>
      </div>
      {bs.isBusy && <Overlay />}
    </OverlayContainer>
  );
};

const StickySummaryWrapper = (props: StickySummaryProps) => {
  const { selectedItems } = useExtendedBooking();
  return <StickySummary {...props} extendedBookingItems={selectedItems} />;
};
