/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-01 10:05:53 */

const Keys = {
  total: 'total',
  basePrice: 'basePrice',
  extension: 'extension',
  with: 'with',
  numberOfPerson: 'numberOfPerson',
  numberOfPerson_plural: 'numberOfPerson_plural',
  participant: 'participant',
  totalPerPerson: 'totalPerPerson',
  voucher: 'voucher',
  airportSurcharge: 'airportSurcharge',
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  OPTIONAL_plural: 'OPTIONAL_plural',
  MAIN: 'MAIN',
  yourHotel: 'yourHotel',
  yourRoom: 'yourRoom',
  yourMeal: 'yourMeal',
  seasonalSurcharges: 'seasonalSurcharges',
  yourBusStop: 'yourBusStop',
  busStopSurcharge: 'busStopSurcharge'
}; export default Keys;