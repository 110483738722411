import React, { useMemo, JSX } from 'react';
import { observer } from 'mobx-react';
import { Col, Row, FormGroup } from 'reactstrap';
import { createUniqueId, sanitize } from '@ibe/services';
import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import { CustomInput, useTranslation } from '@ibe/components';
import ContentContainer from '../../../Components/ContentContainer';

export interface RenderTermsElementPropsType {
  type: TermType;
  hide?: boolean;
  checked: boolean;
  showCheckbox?: boolean;

  text: string;
  title?: string;
  error: string | undefined;
}
interface BookingNoteProps {
  note?: string;
  terms: RenderTermsElementPropsType[];
  toggleCheckBox: (type: TermType, value: boolean) => void;
}
export type TermType = 'legal' | 'email' | 'globista';

const BookingNoteAndTerms = observer(
  (props: BookingNoteProps): JSX.Element => {
    const keys: string[] = useMemo(() => {
      return props.terms.map(_ => createUniqueId());
    }, [props.terms.length]);

    return (
      <div className="iso__booking-note">
        <Wrapper>
          <RenderNote {...props} />
          {props.terms.map((term, index) => (
            <RenderTermsElement key={keys[index]} toggleCheckBox={props.toggleCheckBox} {...term} />
          ))}
        </Wrapper>
      </div>
    );
  }
);

const RenderTermsElement = ({
  error,
  toggleCheckBox,
  checked,
  title,
  hide,
  text,
  showCheckbox,
  type
}: RenderTermsElementPropsType & {
  toggleCheckBox: (type: TermType, value: boolean) => void;
}): JSX.Element => {
  if (hide) return <></>;

  if (!showCheckbox) {
    return (
      <>
        <p className="iso__booking-note-title mt-5">{title}</p>
        <RenderMarkup error={error} markup={text} />
      </>
    );
  }
  return (
    <>
      <p className="iso__booking-note-title mt-5">{title}</p>
      <div className="iso__booking-note-label">
        <FormGroup check={checked} className="pl-0 m-0">
          <CustomInput
            className="iso__booking-note-input"
            type="checkbox"
            id={`iso__booking-note-input--${type}`}
            checked={checked}
            onChange={() => toggleCheckBox(type, !checked)}
            label={<RenderMarkup error={error} markup={text} />}
          />
        </FormGroup>
      </div>
    </>
  );
};

const RenderNote = ({ note }: BookingNoteProps): JSX.Element => {
  const { t } = useTranslation('Checkout', fallback);
  if (!note) return <></>;
  return (
    <div className="iso__booking-note-note">
      <p className="iso__booking-note-title traveler-header">{t(Keys.packageTourNote)}</p>
      <RenderMarkup markup={note} />
    </div>
  );
};

const RenderMarkup = ({ markup, error }: { markup: string; error?: string }): JSX.Element => {
  return (
    <div
      className={`iso__booking-note-text ${error ? 'iso__booking-note-text-error' : ''}`}
      dangerouslySetInnerHTML={{ __html: sanitize(markup, true) }}
    />
  );
};

// Wrapper
const Wrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <ContentContainer>
      <Row>
        <Col>{children}</Col>
      </Row>
    </ContentContainer>
  );
};

const BookingNoteAndTermsWrapper = ({
  terms,
  toggleCheckBox
}: {
  terms: RenderTermsElementPropsType[];
  toggleCheckBox: (type: TermType, value: boolean) => void;
}): JSX.Element => {
  const { t } = useTranslation('Checkout', fallback);
  return (
    <BookingNoteAndTerms
      terms={terms}
      toggleCheckBox={toggleCheckBox}
      note={`<p>${t(Keys.packageTourNoteTextProductIBEMode)} <a target="_blank" href="${t(
        Keys.packageTourNoteLinkLink
      )}">${t(Keys.packageTourNoteLinkText)}</a></p>`}
    />
  );
};

export default BookingNoteAndTermsWrapper;
