import React from 'react';
import {
  ApiBookedItem,
  ApiBooking,
  ApiComponentType,
  ApiImageContainer,
  ApiItemType
} from '@ibe/api';
import { sanitize } from '@ibe/services';
import { useAppService, useBookingService, useConfig, useTranslation } from '@ibe/components';
import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@emotion/react';
import { Col } from 'reactstrap';
import { extendedBookingStorage, SelectedItemsType } from './ExtendedBooking/ContextAndProvider';
import CheckoutStore from '../CheckoutStore';

const FlightAndAccommodationSummaryHeader = ({
  selectedItems,
  store,
  isWhiteLabel,
  booking
}: {
  selectedItems?: SelectedItemsType;
  store: CheckoutStore;
  isWhiteLabel?: boolean;
  booking: ApiBooking | null;
}) => {
  const bs = useBookingService();
  if (!bs?.bookedItems && !isWhiteLabel) return <></>;
  const { url, altText, dateToShow, description, name, location } = useFlightAccommodationHeader(
    store,
    booking,
    selectedItems || extendedBookingStorage.get()
  );

  return (
    <div className="iso__flight-and-accommodation d-block d-md-flex">
      <Col sm={12} md={'auto'} className="iso__flight-and-accommodation__img__col">
        <img src={url} className="iso__flight-and-accommodation__img" alt={altText || ''} />
      </Col>
      <Col sm={12} md={'auto'}>
        <div className="iso__flight-and-accommodation__header">
          <h2 dangerouslySetInnerHTML={{ __html: sanitize(name || '') }} />
          <p>{location}</p>
          {!isWhiteLabel && (
            <div className="iso__flight-and-accommodation__header__box">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>{dateToShow}</span>
              <span>{description}</span>
            </div>
          )}
        </div>
      </Col>
    </div>
  );
};

const useFlightAccommodationHeader = (
  store: CheckoutStore,
  booking: ApiBooking | null,
  selectedItems?: SelectedItemsType
) => {
  const { t } = useTranslation('Checkout', fallback);
  const { duration } = selectedItems || {};
  const { extensionDuration } = duration || {};
  const theme = useTheme();
  const appService = useAppService();
  const config = useConfig();
  const dateFormat = config.displayFormatDate[appService.lang.replace('-', '_')];
  const packageItem = booking?.bookedItems?.find(
    (it: ApiBookedItem) => it.itemType === ApiItemType.PACKAGE
  ) as ApiBookedItem;

  const seasonContent = packageItem?.metaInfo?.contentByMetaType?.season?.content;
  const apiImage = seasonContent?.image as ApiImageContainer | undefined;
  const image = apiImage?.small || apiImage?.extraSmall || apiImage?.large || apiImage?.extraLarge;
  const country = (seasonContent?.seasonCountry as { countryName: string } | undefined)
    ?.countryName as string | undefined;

  const altText = store?.hotelInformation?.image?.altText || image?.altText;
  const name = ((seasonContent?.productName as unknown) as string) || store?.hotelInformation?.name;
  const url = store?.hotelInformation?.image?.url || image?.url || theme?.placeholderImages.hotel;
  const location = store?.hotelInformation?.country || country;

  const overlappingDay = 1;

  const isExtensionSelected =
    duration &&
    extensionDuration &&
    (selectedItems?.steps === undefined ||
      selectedItems?.steps?.some(s => s.isSuccess == false && s.type === undefined)) &&
    extensionDuration > 0;

  const mainHotel = booking?.bookedItems.find(item => {
    return item.componentType === ApiComponentType.MAIN && item.itemType === ApiItemType.HOTEL;
  });

  const mainCompDuration = getMainCompDuration(mainHotel);

  const extensionOverlap = extensionDuration ? extensionDuration - overlappingDay : 0;
  const packageTotalDuration = mainCompDuration + extensionOverlap;

  const dateToShow = getDateToShow(
    dateFormat,
    isExtensionSelected,
    mainHotel,
    extensionDuration,
    overlappingDay,
    packageItem
  );

  const packageTotalDurationWithLabel = `${packageTotalDuration} 
    ${t(Keys.day, {
      count: packageTotalDuration
    })}`;

  let defaultDurationLabel = `(${mainCompDuration} ${t(Keys.day, {
    count: mainCompDuration
  })} ${t(Keys.standardTripDuration)})`;

  if (isExtensionSelected && extensionDuration) {
    defaultDurationLabel = `(${mainCompDuration} ${t(Keys.day, {
      count: mainCompDuration
    })} ${t(Keys.standardTripDuration)} + ${extensionDuration - overlappingDay} ${t(
      Keys.numberOfNight,
      {
        count: extensionDuration
      }
    )} ${t(Keys.extension)})`;
  } else if (!extensionDuration) {
    defaultDurationLabel = `(${mainCompDuration} ${t(Keys.day, {
      count: mainCompDuration
    })} ${t(Keys.standardTripDuration)})`;
  }

  return {
    altText,
    name,
    location,
    url: url,
    dateToShow: dateToShow,
    description: `, ${packageTotalDurationWithLabel} ${defaultDurationLabel}`
  };
};

function getMainCompDuration(mainHotel: ApiBookedItem | undefined): number {
  if (mainHotel) {
    const startDate = dayjs(mainHotel.startDate);
    const endDate = dayjs(mainHotel.endDate);
    return endDate.diff(startDate, 'd') + 1;
  }
  return -1;
}

function getDateToShow(
  dateFormat: string,
  isExtensionSelected: boolean | 0 | undefined,
  mainHotel: ApiBookedItem | undefined,
  extensionDuration: number | undefined,
  overlappingDay: number,
  packageItem: ApiBookedItem
): string {
  let startDate;
  let endDate;
  if (mainHotel) {
    startDate = dayjs(mainHotel.startDate).format(dateFormat);
    endDate = dayjs(mainHotel.endDate).format(dateFormat);
    if (isExtensionSelected && extensionDuration) {
      endDate = dayjs(mainHotel.endDate)
        .subtract(overlappingDay, 'day')
        .add(extensionDuration, 'days')
        .format(dateFormat);
    }
  } else if (packageItem) {
    startDate = dayjs(packageItem.startDate).format(dateFormat);
    endDate = dayjs(packageItem.endDate).format(dateFormat);
  }

  return `${startDate} - ${endDate}`;
}

export { FlightAndAccommodationSummaryHeader, useFlightAccommodationHeader };
