import React, { ReactNode } from 'react';
import { ApiBooking } from '@ibe/api';
import { useTranslation } from '@ibe/components';
import { sanitize } from '@ibe/services';
import { Col, Row } from 'reactstrap';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HotLine from '../StickySummary/HotLine';
import useThgConfig from '../../../../Hooks/useThgConfig';
import { unEscape } from '../../../../Util/globals';
import { FlightAndAccommodationSummaryHeader } from '../FlightAndAccommodationSummary';
import CheckoutStore, { ProductIBEMode } from '../../CheckoutStore';
import SummaryContent from '../StickySummary/Content';
import { useAffiliateContext } from '../../../../Util/AffiliateProvider';
import { extendedBookingStorage } from '../ExtendedBooking/ContextAndProvider';

const ConfirmationPage = ({
  booking,
  children,
  store,
  productIBEMode
}: {
  booking: ApiBooking;
  children: ReactNode;
  store: CheckoutStore;
  productIBEMode: ProductIBEMode;
}) => {
  const { t } = useTranslation('Checkout', fallback);
  const thgConfig = useThgConfig();
  const confirmationCheckData =
    !!useAffiliateContext().metaContent?.content &&
    useAffiliateContext().metaContent?.content?.confirmationCheckData !== null
      ? useAffiliateContext().metaContent?.content?.confirmationCheckData + ''
      : t(Keys.CheckCustomerData);
  const extendedBookingItems = extendedBookingStorage.get();
  return (
    <Row>
      <Col sm={12} md={9} lg={8}>
        <div className="confirmation__booking__container">
          <div className="confirmation__booking">
            <FontAwesomeIcon className={'mr-5'} icon={faCheckCircle} />
            <div className="confirmation__booking__textblock">
              <div className="confirmation__booking__title">
                {t(Keys.ConfirmationThankYou)}
                <span className="confirmation__booking__title__trendtours">
                  {t(Keys.ConfirmationThankYouTrendtours)}
                </span>
                !
              </div>
              {booking?.bookingNumber && (
                <>
                  <div className="confirmation__booking-number mb-2">
                    {t(Keys.ConfirmationBookingNumber)}&nbsp;
                    <span className="confirmation__number">{booking.bookingNumber}</span>
                  </div>
                  <div className="confirmation__emailtext mb-3">
                    {t(Keys.ConfirmationDocuments)}
                  </div>
                  <span className="confirmation__text">{confirmationCheckData}</span>
                </>
              )}
            </div>
          </div>
          <FlightAndAccommodationSummaryHeader booking={booking} store={store} />

          <div className="confirmation__detail">
            <Row>
              <Col>
                <SummaryContent
                  booking={booking}
                  travelers={booking.travelers}
                  showPromotionCode={false}
                  productIBEMode={productIBEMode}
                  extendedBookingItems={extendedBookingItems}
                  isConfirmationPage
                  store={store}
                />
              </Col>
            </Row>
            {!!thgConfig.checkoutPaymentNotes && (
              <Row>
                <Col>
                  <div className="confirmation__notice">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitize(unEscape(thgConfig.checkoutPaymentNotes))
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
        <div className="d-none d-md-block">{children}</div>
      </Col>
      <Col sm={12} md={3} lg={4}>
        <HotLine />
      </Col>
      <Col sm={12} className="d-block d-md-none">
        {children}
      </Col>
    </Row>
  );
};

export default ConfirmationPage;
