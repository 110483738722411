import { FormConfig, useTranslation } from '@ibe/components';
import fallback from '../Translations/generated/traveller-builder.de.json';
import Keys from '../Translations/generated/traveller-builder.de.json.keys';
import { ConfigModel } from '@ibe/services';

export const getTravellerMockData = (): Record<string, string> => {
  return {
    salutation: 'male',
    firstname: 'ISO',
    lastname: 'Test',
    nationality: 'DE',
    birthDate: '01-02-1972',
    street: 'Zum Laurenburger Hof',
    city: 'Frankfurt',
    zipCode: '60594',
    country: 'DE',
    phone: '0911995940',
    email: 'isojupiter@gmail.com'
  };
};

export interface FormItemsProps {
  birthDate: string;
  city: string;
  country: string;
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  nationality: string;
  phone: string;
  salutation: string;
  street: string;
  zipCode: string;

  [a: string]: string;
}

const useTravellerFormConfigProductIBE = (
  isFirstItem: boolean,
  initialValues?: { [key: string]: string },
  config?: ConfigModel
): FormConfig => {
  const { t } = useTranslation('traveller-builder', fallback);
  const maxDate = new Date();
  if (config?.traveler.maxChildAge) {
    maxDate.setFullYear(maxDate.getFullYear() - (config.traveler.maxChildAge + 1));
  }

  return {
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'select',
            key: 'salutation',
            placeholder: t(Keys.salutation),
            initialValue: initialValues?.salutation || '',
            externalDataOptions: 'salutations',
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'title',
            placeholder: t(Keys.title),
            initialValue: initialValues?.title || '',
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'firstname',
            placeholder: t(Keys.firstname),
            initialValue: initialValues?.firstname || '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'lastname',
            placeholder: t(Keys.lastname),
            initialValue: initialValues?.lastname || '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'datePicker',
            key: 'birthDate',
            placeholder: t(Keys.dateOfBirth),
            initialValue: initialValues?.birthDate || '',
            withKeyboardSupport: true,
            displayFormat: 'DD.MM.YYYY',
            maxDate: isFirstItem ? maxDate : new Date(),
            inputClassName: 'form-control',
            useFormValidation: true,
            setValueToNullWhenFormatIsIncorrect: true,
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                ...[
                  {
                    type: 'shouldNotBeNull',
                    customMessage: t(Keys.shouldNotBeNull)
                  },
                  {
                    type: 'isBirthDateYoungerThanMaxAgeOnDate',
                    parameter: 124,
                    customMessage: t(Keys.mustBeLowerThan)
                  }
                ],
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                ...(isFirstItem
                  ? [
                      {
                        type: 'isOlderThanAgeOnDateString',
                        customMessage: t(Keys.mustBeAdult)
                      }
                    ]
                  : [
                      {
                        type: 'isOlderThanInfantOnDateString',
                        customMessage: t(Keys.mustBeValidDate)
                      }
                    ])
              ]
            }
          },
          {
            tag: 'select',
            key: 'nationality',
            placeholder: t(Keys.nationality),
            initialValue: initialValues?.nationality || '',
            externalDataOptions: 'nationalities',
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            }
          },
          {
            tag: 'external',
            key: 'sameAddressCheckbox',
            childIndex: 1,
            rowGrid: {
              xs: 0,
              md: 12
            },
            hideOnExternalChange: {
              property: 'isFirstAdult'
            }
          },
          {
            tag: 'input',
            key: 'street',
            initialValue: initialValues?.street || '',
            placeholder: t(Keys.street_houseNumber),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            },
            hideOnExternalChange: {
              property: 'hideFields'
            }
          },
          {
            key: 'external4',
            tag: 'external',
            childIndex: 0,
            rowGrid: {
              xs: 0,
              md: 6
            },
            hideOnExternalChange: {
              property: 'hideFields'
            }
          },
          {
            tag: 'input',
            key: 'city',
            placeholder: t(Keys.city),
            initialValue: initialValues?.city || '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            },
            hideOnExternalChange: {
              property: 'hideFields'
            }
          },
          {
            tag: 'input',
            key: 'zipCode',
            placeholder: t(Keys.zipCode),
            initialValue: initialValues?.zipCode || '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'zipCode',
                  customMessage: t(Keys.zipCodeError)
                }
              ]
            },
            rowGrid: {
              md: 6
            },
            hideOnExternalChange: {
              property: 'hideFields'
            }
          },
          {
            tag: 'select',
            key: 'country',
            placeholder: t(Keys.country),
            initialValue: initialValues?.country || '',
            externalDataOptions: 'countries',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            },
            hideOnExternalChange: {
              property: 'hideFields'
            }
          },
          {
            key: 'external2',
            tag: 'external',
            childIndex: 0,
            rowGrid: {
              xs: 0,
              md: 6
            },
            hideOnExternalChange: {
              property: 'hideFields'
            }
          },
          {
            tag: 'input',
            key: 'phone',
            placeholder: t(Keys.phone),
            initialValue: initialValues?.phone || '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'customRegExValidation',
                  parameter: /^[-+()0-9/]+$/,
                  customMessage: t(Keys.phoneError)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'email',
            initialValue: initialValues?.email || '',
            placeholder: isFirstItem ? t(Keys.email) : t(Keys.emailOptional),
            validations: {
              schema: 'string',
              types: [
                ...(isFirstItem
                  ? [
                      {
                        type: 'required',
                        customMessage: t(Keys.inputRequired)
                      },
                      {
                        type: 'customRegExValidation',
                        parameter: /^[_a-zA-Z0-9\-\.\+]+@([a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)+)$/,
                        customMessage: t(Keys.emailError)
                      }
                    ]
                  : [
                      {
                        type: 'customRegExValidation',
                        parameter: /^$|^[_a-zA-Z0-9\-\.\+]+@([a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)+)$/,
                        customMessage: t(Keys.emailError)
                      }
                    ])
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            key: 'external3',
            tag: 'external',
            childIndex: 0,
            rowGrid: {
              xs: 0,
              md: 2
            }
          }
        ]
      }
    ]
  };
};
export default useTravellerFormConfigProductIBE;
