/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-01 10:05:52 */

const Keys = {
  from: 'from',
  to: 'to',
  night: 'night',
  night_plural: 'night_plural',
  week: 'week',
  week_plural: 'week_plural',
  nightComma: 'nightComma',
  nightComma_plural: 'nightComma_plural',
  nights: 'nights',
  nights_plural: 'nights_plural',
  weekComma: 'weekComma',
  weekComma_plural: 'weekComma_plural',
  weeks: 'weeks',
  weeks_plural: 'weeks_plural',
  chooseDuration: 'chooseDuration',
  chooseRangeLabel: 'chooseRangeLabel',
  durationPlaceholder: 'durationPlaceholder',
  submit: 'submit',
  offer: 'offer',
  busy: 'busy',
  available: 'available',
  advancedNight: 'advancedNight',
  advancedNight_plural: 'advancedNight_plural',
  minimumStay: 'minimumStay',
  minimumStay_plural: 'minimumStay_plural',
  invalidDateFormat: 'invalidDateFormat',
  invalidDateWithFormat: 'invalidDateWithFormat',
  invalidMinDate: 'invalidMinDate',
  invalidMaxDate: 'invalidMaxDate',
  datePickerModalHeader: 'datePickerModalHeader'
}; export default Keys;