import React, { JSX, useState } from 'react';
import { CarouselCaption } from 'reactstrap';
import { Carousel, CarouselItem } from 'react-bootstrap';

import { useTheme } from '@emotion/react';

export interface SliderItem {
  altText: string;
  caption: string;
  key?: number;
  src?: string;
  srcSet?: string;
}

interface Props {
  sliderItems: SliderItem[];
}

const SingleHotelCarouselComponent = (props: Props): JSX.Element => {
  const { sliderItems } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const placeholderImg = theme.placeholderImages.hotel;

  const slides = sliderItems.map(item => {
    return (
      <CarouselItem key={item.srcSet}>
        <img
          src={!!item.srcSet ? undefined : placeholderImg}
          srcSet={!!item.srcSet ? item.srcSet : ''}
          alt={item.altText}
        />
        {!!item.caption && <CarouselCaption captionText={item.caption} />}
      </CarouselItem>
    );
  });

  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex);
  };
  return (
    !!sliderItems && (
      <Carousel
        onSelect={handleSelect}
        controls={true}
        activeIndex={activeIndex}
        indicators={false}
        interval={null}
        className="h-100"
        keyboard={true}
        fade={true}
        slide={true}
      >
        {slides}
      </Carousel>
    )
  );
};

export default SingleHotelCarouselComponent;
