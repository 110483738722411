import React, { useMemo } from 'react';
import { ExtendedApiTraveler } from '../../ContextAndProvider';
import { Select, useBookingService, useTranslation } from '@ibe/components';
import { createUniqueId } from '@ibe/services';
import fallback from '../../../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../../../Translations/generated/Checkout.de.json.keys';
import { Col, Row } from 'reactstrap';
import { ApiTraveler } from '@ibe/api';
import { DropDownItem } from './useRoomSelection';

const RoomTravelersAssignment = ({
  minOccupancy,
  roomNumber,
  travelers = [],
  updateTravelersToRoom,
  dropDownOptions,
  disableTravelerSelection
}: {
  minOccupancy: number;
  roomNumber: number;
  updateTravelersToRoom: (
    travelerID: string,
    position: number,
    roomNumber: number,
    add?: boolean
  ) => void;
  travelers?: ExtendedApiTraveler[];
  dropDownOptions: DropDownItem[];
  disableTravelerSelection: boolean;
}) => {
  const { t } = useTranslation('Checkout', fallback);
  const allTravelers = useBookingService()?.booking?.travelers || [];
  const maxPossibleTravelers = useMemo(() => {
    return Array(minOccupancy)
      .fill('')
      .map(a => createUniqueId(a));
  }, [minOccupancy]);

  const onChange = (val: DropDownItem, position: number, foundTraveler?: ApiTraveler) => {
    const add = !!val;
    const travelerID = val ? val.value : foundTraveler?.id;
    updateTravelersToRoom(travelerID || '', position, roomNumber, add);
  };

  return (
    <div className="extended__booking__room__assign">
      <div className="left-divider"></div>
      {maxPossibleTravelers.map((key, idx) => {
        const foundTraveler = travelers.find(trav => {
          return trav.position === idx;
        });
        const foundTravelerInfo = allTravelers.find(trav => {
          return trav.id === foundTraveler?.id;
        });
        const selectedOption = foundTravelerInfo
          ? {
              label: foundTravelerInfo.firstName + ' ' + foundTravelerInfo.lastName,
              value: foundTravelerInfo.id
            }
          : null;
        return (
          <Row key={key}>
            <Col md={12} lg={6} className="mt-2 mb-2">
              <Select
                options={dropDownOptions}
                getLabel={(option): string => option.label}
                getValue={(option): string => option.value}
                noOptionsMessage={(): string => `${t(Keys.noOptions)}`}
                onChange={(i: DropDownItem): void => {
                  onChange(i, idx, foundTraveler);
                }}
                placeholder={`${idx + 1} . ${t(Keys.chooseTraveler)}`}
                selectedOption={selectedOption}
                isClearable
                isDisabled={disableTravelerSelection}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default RoomTravelersAssignment;
