/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-01 10:05:53 */

const Keys = {
  travelDuration: 'travelDuration',
  day: 'day',
  day_plural: 'day_plural',
  perPerson: 'perPerson',
  more: 'more',
  less: 'less',
  add: 'add',
  selected: 'selected',
  change: 'change',
  checkboxesHeadline: 'checkboxesHeadline',
  totalPriceLabel: 'totalPriceLabel',
  cancel: 'cancel',
  submit: 'submit',
  traveler: 'traveler',
  hotelUpgrade: 'hotelUpgrade',
  blockedByService: 'blockedByService'
}; export default Keys;