import { SelectedItemsType } from './ContextAndProvider';

export const isValidWorkflow = (selectedItems: SelectedItemsType): boolean => {
  let workflowStepsDone =
    !!selectedItems.duration &&
    !!selectedItems.travelers &&
    !!selectedItems.hotelMain &&
    !!selectedItems.roomsMain &&
    !!selectedItems.cateringMain;

  if (selectedItems.duration?.selectedExtensionComponent) {
    workflowStepsDone =
      workflowStepsDone &&
      !!selectedItems.hotelOptional &&
      !!selectedItems.roomsOptional &&
      !!selectedItems.cateringOptional;
  }

  if (selectedItems.busStation) {
    workflowStepsDone = workflowStepsDone && !!selectedItems.busStation;
  }

  return workflowStepsDone;
};
