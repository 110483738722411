import React, { ReactNode, JSX } from 'react';
import { ComponentWrapper, isNumber, isRailAndFlyReturn, MetaInfoCmsContent } from './additionals';
import Extra, { ExtraProps } from './Extra';
import { parseInt } from 'lodash-es';
import { useBookingService } from '@ibe/components';

const getMetaInfoCmsContent = (extra: ComponentWrapper): MetaInfoCmsContent | undefined => {
  return (!!extra.unitLevel ? extra.unitLevel.metaInfo : extra.itemLevel.metaInfo)
    ?.contentByMetaType?.extra?.content;
};

const mapDesignToIndex = (
  design: MetaInfoCmsContent['design'],
  extrasGroupOrder?: [string, string]
): number => {
  if (
    design === (!!extrasGroupOrder && !!extrasGroupOrder[0] ? extrasGroupOrder[0] : 'attractions')
  ) {
    return 0;
  } else if (
    design === (!!extrasGroupOrder && extrasGroupOrder[1] ? extrasGroupOrder[1] : 'extras')
  ) {
    return 1;
  } else {
    return 2;
  }
};

const getHighestPrioritySelection = (components: ComponentWrapper[]): number => {
  return (
    components.reduce((total: number, current: ComponentWrapper) => {
      const prioritySelection = getMetaInfoCmsContent(current)?.prioritySelection;
      return isNumber(prioritySelection) && prioritySelection > total ? prioritySelection : total;
    }, 0) || 10000
  );
};

type ReduceTotal = Record<number, Array<ComponentWrapper>>;

const getSortedExtrasGroups = (
  extras: Array<ComponentWrapper>,
  travelStartDate?: string,
  extrasGroupOrder?: [string, string]
): Array<[string, Array<ComponentWrapper>]> => {
  return Object.entries(
    extras
      .filter(
        extra =>
          !travelStartDate ||
          (!!travelStartDate && !isRailAndFlyReturn(extra.component, travelStartDate))
      )
      .reduce((total: ReduceTotal, current: ComponentWrapper) => {
        return {
          ...total,
          [mapDesignToIndex(getMetaInfoCmsContent(current)?.design, extrasGroupOrder)]: !!total[
            mapDesignToIndex(getMetaInfoCmsContent(current)?.design, extrasGroupOrder)
          ]
            ? [
                ...total[
                  mapDesignToIndex(getMetaInfoCmsContent(current)?.design, extrasGroupOrder)
                ],
                current
              ]
            : [current]
        };
      }, {} as ReduceTotal)
  ).sort(([aKey], [bKey]) => parseInt(aKey, 10) - parseInt(bKey, 10));
};

const Extras = ({
  extras,
  mode,
  extrasHeadContent,
  extrasGroupOrder,
  isLoading
}: {
  extras: Array<ComponentWrapper>;
  mode?: ExtraProps['mode'];
  extrasHeadContent?: Array<ReactNode>;
  extrasGroupOrder?: [string, string];
  isLoading?: boolean;
}): JSX.Element => {
  const bs = useBookingService();

  return (
    <>
      {getSortedExtrasGroups(extras, bs.booking?.travelStartDate, extrasGroupOrder).map(
        ([key, componentList]) => (
          <div key={key}>
            {!!extrasHeadContent && !!extrasHeadContent[parseInt(key, 10)] ? (
              extrasHeadContent[parseInt(key, 10)]
            ) : (
              <></>
            )}
            <div className="optional-offer__extra__list">
              {componentList
                .sort((a, b) => {
                  const aPriority = getMetaInfoCmsContent(a)?.prioritySelection;
                  const bPriority = getMetaInfoCmsContent(b)?.prioritySelection;
                  const highestPriority = getHighestPrioritySelection(componentList);
                  return (
                    (isNumber(aPriority) ? aPriority : highestPriority + 1) -
                    (isNumber(bPriority) ? bPriority : highestPriority + 1)
                  );
                })
                .map(extra => (
                  <Extra
                    key={`${extra.componentIndex}${extra.itemIndex}${extra.unitIndex || 0}`}
                    extra={extra}
                    mode={mode}
                    metaInfoCmsContent={getMetaInfoCmsContent(extra)}
                    extras={extras}
                    isLoading={isLoading}
                  />
                ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Extras;
