import React, { MouseEvent, JSX } from 'react';
import { ApiBestPricePackageModel, ApiItem } from '@ibe/api';
import { useTheme } from '@emotion/react';
import {
  PackageItemSelection,
  ThreeColumnListItemLayout,
  useTranslation,
  DateDisplay,
  PackageInfo,
  StarRating
} from '@ibe/components';
import { LoggerFactory } from '@ibe/services';
import { Col, Row } from 'reactstrap';
import { faCalendarAlt, faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Keys from '../../Translations/generated/bestPricePackageListItem.de.json.keys';
import fallback from '../../Translations/generated/bestPricePackageListItem.de.json';

type Props = {
  onSelect?: (selection: Partial<PackageItemSelection>) => void;
  index?: number;
  item: ApiItem;
};

const logger = LoggerFactory.get('PackagesPage');

const PackageListItem = (props: Props): JSX.Element => {
  const theme = useTheme();
  const onDetailsSelect = props.onSelect || logger.warn;
  const { t } = useTranslation('bestPricePackageListItem', fallback);

  const item = props.item as ApiBestPricePackageModel;
  const location =
    item.geoAssignment && item.geoAssignment.location ? item.geoAssignment.location : null;

  const stars = item.category ? Number(item.category.description || '0') : 0;

  const singleDuration = item.duration;

  const defaultRoom = item.rooms?.find(room => room.isDefault);

  const defaultMealDescription =
    defaultRoom && defaultRoom.defaultMeal ? defaultRoom.defaultMeal.description : t(Keys.roomOnly);

  const onDetailSelect = (): void => {
    const selection = { item };
    onDetailsSelect(selection);
  };

  const handleClick = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    onDetailSelect();
  };

  const Center = (): JSX.Element => {
    return (
      <div className="package-list-item__center d-flex flex-column h-100">
        <div className="flex-grow-1">
          <PackageLocation />
          <div className="mt-3 mb-2">{item.shortDescription}</div>
        </div>
        <div className="center-lower">
          <Row>
            <Col>
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-body d-inline" />
              <DateDisplay date={item.startDate} /> - <DateDisplay date={item.endDate} />
            </Col>
            <Col>
              <FontAwesomeIcon icon={faClock} className="mr-2 text-body d-inline" />
              <span className="mr-2">
                {singleDuration.duration} {t(Keys.nights)}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const PackageBtn = (): JSX.Element => (
    <button
      type="button"
      className="iso__package-item__button btn btn-primary"
      onClick={handleClick}
    >
      {t(Keys.choose)}
    </button>
  );

  const TopRight = (): JSX.Element => (
    <PackageInfo
      price={item.price}
      board={defaultRoom?.description}
      room={defaultMealDescription}
      origin={item.originName}
      className="package-info__package"
    />
  );

  const PackageLocation = (): JSX.Element | null =>
    location ? (
      <div className="package-list-item__location mb-3">
        <FontAwesomeIcon icon={faMapMarkerAlt} /> {location}
      </div>
    ) : null;

  return (
    <div className="package-list-item">
      <ThreeColumnListItemLayout
        image={item.media?.mainImage?.small}
        onClick={handleClick}
        center={<Center />}
        topRight={<TopRight />}
        headline={
          <>
            {item.name} &nbsp;
            <StarRating numberOfStars={stars} />
          </>
        }
        button={<PackageBtn />}
        placeholderImage={theme.placeholderImages.package}
        fullAreaClick
        mobileViewInLg
      />
    </div>
  );
};

export default PackageListItem;
