import { ApiBookedItem, ApiBooking } from '@ibe/api';
import React, { ReactNode } from 'react';
import { Collapse } from 'reactstrap';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Price, useBookingService } from '@ibe/components';
import { GroupType, Props, useCustomPerTravelerSummaryItem } from './external';
import { PriceFactory } from '@ibe/services';

export type BookedItemType = {
  item: ApiBookedItem & { useValuePriceDisplay?: boolean };
  amount: number;
};

const CustomPerTravelerSummaryItem = (props: Props) => {
  const { isOpen, onCollapseToggle, travelerIdx, booking } = props;
  const { name, summaryElements, totalPricePerPerson } = useCustomPerTravelerSummaryItem(props);

  return (
    <>
      <hr />
      <SummaryItemHeader
        isOpen={isOpen}
        toggleCollapsible={() => onCollapseToggle && onCollapseToggle(travelerIdx)}
      >
        <div className="d-flex align-items-center justify-content-between flex-grow-1">
          <div className="summary__traveler__info">
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            <div className="summary__traveler">{name}</div>
          </div>
          <div className="summary__traveler__price">
            {totalPricePerPerson ? (
              <Price price={totalPricePerPerson} removeZeroDecimals displayInline />
            ) : null}
          </div>
        </div>
      </SummaryItemHeader>
      <SummaryItemContent isOpen={isOpen}>
        {summaryElements.map((elt, idx) => (
          <GroupElement booking={booking} key={idx} {...elt} />
        ))}
      </SummaryItemContent>
    </>
  );
};

export default CustomPerTravelerSummaryItem;

interface CollapsibleSummaryProps {
  isOpen?: boolean;
  children: ReactNode;
}

const SummaryItemHeader = ({
  children,
  toggleCollapsible,
  isOpen
}: {
  children: ReactNode;
  toggleCollapsible: () => void;
  isOpen?: boolean;
}) => {
  return (
    <div
      onClick={toggleCollapsible}
      className={`mt-3 summary__collapsible__header ${
        isOpen ? 'summary__collapsible__header--open' : ''
      }`}
    >
      {children}
    </div>
  );
};

const SummaryItemContent = ({ children, isOpen }: CollapsibleSummaryProps) => {
  return <Collapse isOpen={isOpen}>{children}</Collapse>;
};

const GroupElement = ({
  hidePrice,
  basePrice,
  isBasisPrice,
  label,
  lists,
  isDiscount,
  booking
}: GroupType & { booking: ApiBooking }) => {
  const isDiscountClass = isDiscount ? 'summary__group--discount' : '';
  const bs = useBookingService();
  return (
    <div className={isDiscountClass + ` summary__group`}>
      <div className="d-flex align-items-center justify-content-between flex-grow-1">
        <div className="summary__group__header">{label}</div>
        {!hidePrice ? (
          <span className={`iso__summary__price__container iso__summary__price__container--bold`}>
            <Price
              price={basePrice}
              removeZeroDecimals
              displayInline
              prefix={
                !isBasisPrice && basePrice?.finalPrice && basePrice?.finalPrice >= 0 ? '+ ' : ''
              }
            />
          </span>
        ) : null}
      </div>
      <div className="summary__group__lists">
        {lists.map((item, index) => {
          const { text, priceDifference } = item;
          if (!bs.booking?.price?.currencyCode && !booking?.price?.currencyCode) return <></>;
          return (
            <div
              key={index}
              className={` d-flex align-items-start justify-content-between flex-grow-1`}
            >
              <div className="text-muted iso__summary__description">{text}</div>
              <div>
                <span className="iso__summary__price__container">
                  {priceDifference === null ? (
                    '-'
                  ) : (
                    <Price
                      className="bold"
                      price={PriceFactory.create(
                        priceDifference,
                        bs.booking?.price?.currencyCode || booking?.price?.currencyCode
                      )}
                      removeZeroDecimals
                      displayInline
                      prefix={priceDifference >= 0 ? '+ ' : ''}
                    />
                  )}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
