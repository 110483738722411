import React, { Fragment, useMemo, useState } from 'react';
import CommonWrapper, { RemountWrapper } from '../../CommonWrapper';
import { CustomInput, Info, Price, useTranslation } from '@ibe/components';
import fallback from '../../../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../../../Translations/generated/Checkout.de.json.keys';
import { ExtendedApiTraveler, SelectedRoom, useExtendedBooking } from '../../ContextAndProvider';
import { DropDownItem, useRoomSelection } from './useRoomSelection';
import { createUniqueId, sanitize } from '@ibe/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'reactstrap';
import {
  ApiComponent,
  ApiHotel,
  ApiHotelRoom,
  ApiRelatedServiceRelationType,
  ApiRoomRate,
  ApiTraveler,
  ApiTravelerType
} from '@ibe/api';
import RoomTravelersAssignment from './RoomTravelersAssignment';

const halfRoomCodes = ['RHDZ', 'HDZ'];

const RoomSelectionMainComponent = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems } = useExtendedBooking();
  const hotel = selectedItems.hotelMain;
  const { mainComponent } = selectedItems?.duration || {};
  return (
    <RoomSelectionWrapper
      idx={idx}
      hotel={hotel}
      remount={remount}
      component={mainComponent}
      selectedExtensionComponent={selectedItems.duration?.selectedExtensionComponent}
    />
  );
};

const RoomSelectionOptionalComp = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems } = useExtendedBooking();
  const hotel = selectedItems.hotelOptional;
  const selectedMainRooms = selectedItems.roomsMain;

  const { selectedExtensionComponent } = selectedItems?.duration || {};
  return (
    <RoomSelectionWrapper
      component={selectedExtensionComponent}
      idx={idx}
      hotel={hotel}
      remount={remount}
      isOptional={true}
      selectedMainRooms={selectedMainRooms}
    />
  );
};

const RoomSelectionWrapper = (props: {
  idx: number;
  hotel?: ApiHotel;
  component?: ApiComponent;
  isOptional?: boolean;
  remount?: () => void;
  selectedMainRooms?: SelectedRoom[];
  selectedExtensionComponent?: ApiComponent;
}) => {
  const { t } = useTranslation('Checkout', fallback);
  const {
    idx,
    hotel,
    component,
    isOptional,
    remount,
    selectedMainRooms,
    selectedExtensionComponent
  } = props;

  if (!hotel || !component) {
    return (
      <CommonWrapper
        idx={idx}
        title={t(Keys.yourRoom)}
        description={t(Keys.extBookingSelectYourRoom)}
        successNotes={[]}
      >
        <></>
      </CommonWrapper>
    );
  }
  return (
    <RoomSelection
      component={component}
      hotel={hotel}
      idx={idx}
      isOptional={isOptional}
      remount={remount}
      selectedMainRooms={selectedMainRooms}
      selectedExtensionComponent={selectedExtensionComponent}
    />
  );
};

const RoomSelection = ({
  idx,
  hotel,
  component,
  isOptional,
  remount,
  selectedMainRooms,
  selectedExtensionComponent
}: {
  idx: number;
  hotel: ApiHotel;
  component: ApiComponent;
  isOptional?: boolean;
  remount?: () => void;
  selectedMainRooms?: SelectedRoom[];
  selectedExtensionComponent?: ApiComponent;
}) => {
  const { t } = useTranslation('Checkout', fallback);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const {
    numberOfRooms,
    selectedRooms,
    updateNumberOfRooms,
    availableRooms,
    cheapestHotelRoom,
    updateRoomRateSelection,
    updateTravelersToRoom,
    maxNumberRoom,
    minNumberRoom,
    disableButton,
    successNotes,
    onNext,
    dropDownOptions,
    roomKeys
  } = useRoomSelection(component, hotel, isOptional, true);

  const restrictedSelection =
    selectedMainRooms &&
    selectedMainRooms.some(selectedRoom => {
      return (
        !!selectedRoom.room.relatedServices &&
        selectedRoom.room.relatedServices.some(
          rS => rS.relationType == ApiRelatedServiceRelationType.EXCLUDE
        )
      );
    });

  const clearWarningAndUpdateRoomRateSelection = (
    a: ApiHotelRoom,
    roomNumber: number,
    travelers: ExtendedApiTraveler[]
  ): void => {
    if (!isOptional) {
      setShowWarning(false);
    }
    updateRoomRateSelection(a, roomNumber, travelers);
  };

  const validateBeforeNext = (): boolean => {
    if (isOptional || !selectedExtensionComponent || hotel.relatedServices?.length === 0) {
      onNext();
    } else {
      // get possible hotels which are not excluded by selected main hotels
      const allowedExtensionHotels = selectedExtensionComponent.selectableItems.filter(
        item => !hotel.relatedServices?.some(rs => rs.code === (item as ApiHotel).code)
      );
      // check per hotel as rooms must be ok within one hotel

      const possibleHotels = allowedExtensionHotels.filter(extHotelItem => {
        return checkIfExtensionHotelCanMatchMainHotelRooms(extHotelItem as ApiHotel, selectedRooms);
      });
      if (possibleHotels.length > 0) {
        setShowWarning(false);
        onNext();
      } else {
        setShowWarning(true);
        return false;
      }
    }
    return true;
  };

  return (
    <CommonWrapper
      idx={idx}
      title={isOptional ? t(Keys.yourRoomOptional) : t(Keys.yourRoom)}
      description={t(Keys.extBookingSelectYourRoom)}
      successNotes={successNotes}
      onNext={onNext}
      disableButton={disableButton}
      onClose={remount}
      validateBeforeNext={validateBeforeNext}
    >
      <>
        <div className="d-flex align-items-center mt-2 mb-2">
          {!restrictedSelection && (
            <>
              <div className="extended__booking__room__number ">{t(Keys.numberOfRooms)}</div>
              <div className="extended__booking__room__plus_minus">
                <button
                  onClick={() => {
                    !isOptional && setShowWarning(false);
                    numberOfRooms && updateNumberOfRooms(numberOfRooms - 1, false);
                  }}
                  className="btn extended__booking__room__plus_minus__minus"
                  disabled={!numberOfRooms || !minNumberRoom || numberOfRooms <= minNumberRoom}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <span className="extended__booking__room__plus_minus__value">{numberOfRooms}</span>
                <button
                  onClick={() => {
                    !isOptional && setShowWarning(false);
                    numberOfRooms && updateNumberOfRooms(numberOfRooms + 1, true);
                  }}
                  className="btn extended__booking__room__plus_minus__plus"
                  disabled={!numberOfRooms || !maxNumberRoom || numberOfRooms >= maxNumberRoom}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </>
          )}
        </div>
        <div className="extended__booking__room__wrapper">
          {roomKeys.map((roomKey, roomNumber) => {
            return (
              <RoomElement
                key={roomKey + roomNumber}
                roomNumber={roomNumber}
                updateRoomRateSelection={clearWarningAndUpdateRoomRateSelection}
                selectedRooms={selectedRooms}
                availableRooms={availableRooms}
                cheapestHotelRoom={cheapestHotelRoom}
                updateTravelersToRoom={updateTravelersToRoom}
                dropDownOptions={dropDownOptions}
                currentHotel={hotel}
                referenceMainRoom={restrictedSelection ? selectedMainRooms[roomNumber] : undefined}
              />
            );
          })}
        </div>
        {showWarning && (
          <div className="text-wrap">
            <Info message={t(Keys.extBookingExclusionHotelSelectionWarning)} />
          </div>
        )}
      </>
    </CommonWrapper>
  );
};

const RoomElement = ({
  availableRooms,
  roomNumber,
  selectedRooms,
  updateRoomRateSelection,
  updateTravelersToRoom,
  dropDownOptions,
  cheapestHotelRoom,
  currentHotel,
  referenceMainRoom
}: {
  availableRooms: ApiHotelRoom[];
  cheapestHotelRoom: ApiHotelRoom;
  roomNumber: number;
  selectedRooms: {
    assignedRoomNumber: number;
    room: ApiHotelRoom;
    travelers?: ApiTraveler[] | undefined;
  }[];
  updateRoomRateSelection: (
    a: ApiHotelRoom,
    roomNumber: number,
    travelers: ExtendedApiTraveler[]
  ) => void;
  updateTravelersToRoom: (
    travelerID: string,
    position: number,
    roomNumber: number,
    add?: boolean
  ) => void;
  dropDownOptions: DropDownItem[];
  currentHotel: ApiHotel;
  referenceMainRoom?: SelectedRoom;
}) => {
  const { t } = useTranslation('Checkout', fallback);

  const sortedAvailableRooms = useMemo(() => {
    const sortedRoms = getSortedHotelRooms(availableRooms);
    if (referenceMainRoom) {
      return sortedRoms.filter(
        value =>
          !referenceMainRoom.room.relatedServices?.some(
            relatedService =>
              relatedService.unitCode === value.roomCode &&
              relatedService.code === currentHotel.code
          ) && value.minOccupancy === referenceMainRoom.travelers.length
      );
    }
    return sortedRoms;
  }, [availableRooms, referenceMainRoom, currentHotel]);

  const cheapestRoom = cheapestHotelRoom;

  const cheapestRoomRate = getCheapestRoomRate(cheapestRoom);

  const cheapestRoomRatePrice = getPersonPriceFromRate(cheapestRoomRate);

  const keys = useMemo(() => {
    return availableRooms.map(_ => createUniqueId('room-'));
  }, [availableRooms, roomNumber]);

  return (
    <div className="extended__booking__room">
      <div className="extended__booking__room__title">{`${t(Keys.room)} ${roomNumber + 1}`}</div>
      {sortedAvailableRooms.map((room, index) => {
        const { id, description, minOccupancy } = room;
        const findSelected = selectedRooms.find(
          selectedRoom =>
            selectedRoom.room.id === id && roomNumber === selectedRoom.assignedRoomNumber
        );
        const isInclusive =
          cheapestRoom && cheapestRoom.id === room.id && room.availability >= minOccupancy;

        const thisRoomCheapestPrice = getPersonPriceFromRate(
          room.roomrates.sort((rate1, rate2) => {
            return getPersonPriceFromRate(rate1) - getPersonPriceFromRate(rate2);
          })[0]
        );
        const priceDifference = thisRoomCheapestPrice - cheapestRoomRatePrice;

        const isHalfRoom = halfRoomCodes.includes(room.roomCode);

        const onClick = () => {
          updateRoomRateSelection(
            room,
            roomNumber,
            referenceMainRoom ? referenceMainRoom.travelers : []
          );
        };
        const isAvailable = !!findSelected || checkRoomAvailability(selectedRooms, room);
        return (
          <Fragment key={keys[index] + roomNumber}>
            <Row>
              <Col>
                <CustomInput
                  id={id + roomNumber}
                  type="radio"
                  checked={!!findSelected}
                  disabled={!isAvailable}
                  onClick={!!findSelected ? undefined : onClick}
                  className={`extended__booking__custom__input ${
                    !isAvailable ? 'extended__booking__custom__input--disabled' : ''
                  }`}
                  readOnly
                  label={
                    <div
                      className={`extended__booking__item ${
                        !!findSelected ? 'extended__booking__item--selected' : ''
                      }`}
                    >
                      <span className="custom-checkbox"></span>
                      <div className="extended__booking__whitespace">
                        <span className="extended__booking__whitespace__description">
                          {description}
                        </span>
                        <NumberOfPerson
                          numberOfGuest={minOccupancy}
                          hideIcon
                          isHalfRoomSelection={isHalfRoom}
                        />
                      </div>
                    </div>
                  }
                />
              </Col>
              <Col className="text-right">
                {isAvailable && isInclusive && (
                  <span className="extended__booking__roomSelection__inclusive">
                    {t(Keys.inclusive)}
                  </span>
                )}
                {isAvailable && !isInclusive && (
                  <Price
                    className="extended__booking__item__price"
                    price={{
                      ...cheapestRoomRate?.totalPrice,
                      finalPrice: priceDifference,
                      currencyCode: cheapestRoomRate?.totalPrice?.currencyCode
                    }}
                    prefix={priceDifference > 0 ? '+ ' : ''}
                    removeZeroDecimals={false}
                    displayInline
                  />
                )}
                {!isAvailable && (
                  <span className="extended__booking__whitespace extended__booking__roomSelection__unavailable">
                    {t(Keys.extBookingUnavailable)}
                  </span>
                )}
                {isAvailable && !isInclusive && (
                  <span className="extended__booking__roomSelection__per-person">
                    {t(Keys.extBookingPerPerson)}
                  </span>
                )}
              </Col>
            </Row>
            {!!findSelected ? (
              <RoomTravelersAssignment
                minOccupancy={minOccupancy}
                roomNumber={roomNumber}
                updateTravelersToRoom={updateTravelersToRoom}
                travelers={findSelected.travelers}
                dropDownOptions={dropDownOptions}
                disableTravelerSelection={referenceMainRoom !== undefined}
              />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

const NumberOfPerson = ({
  numberOfGuest,
  hideIcon,
  isHalfRoomSelection
}: {
  numberOfGuest: number;
  hideIcon?: boolean;
  isHalfRoomSelection?: boolean;
}) => {
  const { t } = useTranslation('Checkout', fallback);

  const allIcons: string[] = Array(numberOfGuest)
    .fill('')
    .map(a => createUniqueId(a));
  return (
    <>
      <span className="extended__booking__room__person">
        {!hideIcon ? (
          <>
            &nbsp;&nbsp;&nbsp;
            <span className="extended__booking__room__icon">
              {allIcons.map(item => {
                return <FontAwesomeIcon key={`${item}`} icon={faUser} />;
              })}
            </span>
          </>
        ) : null}
        <span className="extended__booking__room__number__guest">
          ({numberOfGuest} {t(Keys.person, { count: numberOfGuest })})
        </span>
      </span>

      {isHalfRoomSelection && (
        <span
          className={'extended__booking__room__half'}
          dangerouslySetInnerHTML={{ __html: sanitize(t(Keys.halfRoomSelectionInfo)) }}
        ></span>
      )}
    </>
  );
};

export const RoomSelectionMain = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={RoomSelectionMainComponent} props={{ idx }} />
);

export const RoomSelectionOptional = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={RoomSelectionOptionalComp} props={{ idx }} />
);
export const getPersonPriceFromRate = (
  rate: ApiRoomRate,
  travelerType?: ApiTravelerType
): number => {
  return (
    rate.pricingInfo.find(info => info.travelerType === (travelerType || ApiTravelerType.ADULT))
      ?.price.finalPrice || 0
  );
};

const checkRoomAvailability = (
  selectedRooms: {
    assignedRoomNumber: number;
    room: ApiHotelRoom;
    travelers?: ApiTraveler[] | undefined;
  }[],
  room: ApiHotelRoom
) => {
  if (!room.availability) return false;
  const foundSelectedRooms = selectedRooms.filter(
    selectedRoom => selectedRoom?.room?.id === room.id
  );
  return foundSelectedRooms.length < room.availability;
};

export const getSortedHotelRooms = (availableRooms: ApiHotelRoom[]) => {
  return [
    ...availableRooms.sort((a, b) => {
      const cheapestRoomOneRate = a.roomrates.sort((rate1, rate2) => {
        return getPersonPriceFromRate(rate1) - getPersonPriceFromRate(rate2);
      })[0];
      const cheapestRoomTwoRate = b.roomrates.sort((rate1, rate2) => {
        return getPersonPriceFromRate(rate1) - getPersonPriceFromRate(rate2);
      })[0];
      return (
        getPersonPriceFromRate(cheapestRoomOneRate) - getPersonPriceFromRate(cheapestRoomTwoRate)
      );
    })
  ];
};

export const getCheapestRoomRate = (cheapestRoom: ApiHotelRoom) => {
  return cheapestRoom.roomrates.sort((rate1, rate2) => {
    return getPersonPriceFromRate(rate1) - getPersonPriceFromRate(rate2);
  })[0];
};

export const checkIfExtensionHotelCanMatchMainHotelRooms = (
  extensionHotel: ApiHotel,
  selectedRooms: {
    room: ApiHotelRoom;
    travelers?: ExtendedApiTraveler[];
  }[]
): boolean => {
  return selectedRooms.every(selectedMainRoom => {
    // get all allowed rooms from ext Hotel and check if one has enough availability
    const possibleRooms = extensionHotel.rooms.filter(
      extensionRoomCandidate =>
        selectedMainRoom.travelers &&
        extensionRoomCandidate.minOccupancy >= selectedMainRoom.travelers.length &&
        extensionRoomCandidate.maxOccupancy <= selectedMainRoom.travelers.length &&
        extensionRoomCandidate.availability > 0 &&
        (selectedMainRoom.room.relatedServices === undefined ||
          !selectedMainRoom.room.relatedServices.some(
            rs => rs.code === extensionHotel.code && rs.unitCode === extensionRoomCandidate.roomCode
          ))
    );
    return possibleRooms.length > 0;
  });
};
