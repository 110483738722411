import React, { createContext, useContext, useState } from 'react';
import { ApiComponent, ApiHotel, ApiHotelRoom, ApiTraveler } from '@ibe/api';
import { SessionStorage } from '@ibe/services';
import { extendedBookingSessionKey } from '../../../../Config/config';
import { CollapsibleWizardItemType } from '../CollapsibleWizard/ContextAndProvider';
import CheckoutStore from '../../../../Pages/Checkout/CheckoutStore';

export interface ExtendedApiTraveler extends ApiTraveler {
  position?: number;
}

export interface SelectedItemsType {
  duration?: {
    mainComponent: ApiComponent;
    selectedExtensionComponent?: ApiComponent;
    extensionDuration: number;
  };
  hotelMain?: ApiHotel;
  travelers?: ApiTraveler[];
  cateringMain?: {
    mealTypeCode: string;
    mealTypeDescription: string;
    rateId: string;
  };

  hotelOptional?: ApiHotel;
  cateringOptional?: {
    mealTypeCode: string;
    mealTypeDescription: string;
    rateId: string;
  };

  roomsMain?: SelectedRoom[];

  roomsOptional?: SelectedRoom[];

  steps?: Omit<CollapsibleWizardItemType, 'component'>[];

  hotelComponents?: ApiComponent[];
}

export interface SelectedRoom {
  room: ApiHotelRoom;
  travelers: ExtendedApiTraveler[];
}

interface ExtendedBookingContextProps {
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  store: CheckoutStore;
  selectedItems: SelectedItemsType;
  setSelectedItems: React.Dispatch<React.SetStateAction<SelectedItemsType>>;
}

const ExtendedBookingContext = createContext<ExtendedBookingContextProps | null>(null);

export const useExtendedBooking = () => {
  const context = useContext(ExtendedBookingContext);
  if (!context) {
    throw new Error('useExtendedBooking must be used within an ExtendedBookingProvider');
  }
  return context;
};
export const useExtendedBookingForPriceOverview = () => {
  const context = useContext(ExtendedBookingContext);
  return context ? context.selectedItems : null;
};
export const ExtendedBookingProvider: React.FC<{
  children: React.ReactNode;
  store: CheckoutStore;
}> = ({ children, store }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SelectedItemsType>(
    extendedBookingStorage.get() || ({} as SelectedItemsType)
  );

  return (
    <ExtendedBookingContext.Provider
      value={{
        isLoading,
        setIsLoading,
        selectedItems,
        setSelectedItems,
        store
      }}
    >
      {children}
    </ExtendedBookingContext.Provider>
  );
};

export const extendedBookingStorage = new SessionStorage<SelectedItemsType>(
  extendedBookingSessionKey
);
