import React, { JSX, useEffect, useState } from 'react';
import Keys from '../../../../../Translations/generated/Checkout.de.json.keys';
import fallback from '../../../../../Translations/generated/Checkout.de.json';
import CommonWrapper, { RemountWrapper } from '../CommonWrapper';
import {
  CustomInput,
  DistanceUtil,
  RentalCarOfficeWithDistanceWrapper,
  useBookingService,
  useTranslation
} from '@ibe/components';
import {
  ApiBaseData,
  ApiComponent,
  ApiComponentType,
  ApiItemType,
  ApiRentalCarOffice
} from '@ibe/api';
import { Col, Row } from 'reactstrap';
import { packageIdSessionStorage, SELF_ARRIVAL_CODE, TAXI_30KM_CODE } from '../../../../Util/utils';
import { useExtendedBooking } from '../ContextAndProvider';
import { FlightAndAccommodationSummaryHeader } from '../../FlightAndAccommodationSummary';
import { useComponentSelectionState } from './Duration';

interface AvailableStation extends RentalCarOfficeWithDistanceWrapper {
  isSelected: boolean;
}

const BusStopsComponent = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems, setSelectedItems } = useExtendedBooking();
  const [availableStations, setAvailableStations] = useState<AvailableStation[]>([]);
  const bs = useBookingService();
  const packageDetails = bs?.packageCart?.packageModel.packageDetails || [];
  const hotelComponents = packageDetails
    .flatMap(detail => detail.components)
    .filter(comp => comp.itemType === ApiItemType.HOTEL);
  const mainComponent = hotelComponents.find(
    comp => comp.componentType === ApiComponentType.MAIN
  ) as ApiComponent;
  const { store } = useComponentSelectionState();
  const { t } = useTranslation('Checkout', fallback);

  const stationCodes = packageIdSessionStorage.get()?.stationCodes;
  const selectedPostCode = sessionStorage.getItem('selectedPostCode');
  const selectedStations = sessionStorage.getItem('selectedStations');

  const isSelfArrivalOption = () => {
    if (selectedPostCode) {
      const parsedPostCode: ApiBaseData = JSON.parse(selectedPostCode);
      return parsedPostCode.code === SELF_ARRIVAL_CODE;
    }
    return false;
  };

  /**
   * Special function to determine whether the taxi lift if available for a selected package
   * Rules:
   * - selected package has to offer station with special code 00001 (TAXI_30KM_CODE) - first condition to even call this function
   * - first station should have distance > 35 km
   * - there must be a station within the distance 35 - 50 km
   * - first two digits of the station from range 35 - 50 km should match to the search criteria postal code first two digits
   * @param sortedStations
   */
  const verifyAndAdd30kmStation = (
    sortedStations: RentalCarOfficeWithDistanceWrapper[]
  ): RentalCarOfficeWithDistanceWrapper[] => {
    const stationsInRange = sortedStations.filter(
      station => station.distance >= 35 && station.distance < 51
    );

    if (selectedPostCode) {
      const parsedPostCode: ApiBaseData = JSON.parse(selectedPostCode);
      if (
        sortedStations[0].distance > 35 &&
        stationsInRange.length > 0 &&
        parsedPostCode.code &&
        stationsInRange.some(station =>
          station.code?.startsWith(parsedPostCode.code.substring(0, 2))
        )
      ) {
        sortedStations.push({
          code: TAXI_30KM_CODE,
          distance: -1,
          description: t(Keys.stopMax30kmFromResidence)
        } as RentalCarOfficeWithDistanceWrapper);
      }
    }
    return sortedStations;
  };

  useEffect(() => {
    const loadOptions = () => {
      if (!selectedPostCode || !selectedStations) return;
      const parsedPostCode: ApiBaseData = JSON.parse(selectedPostCode);
      const parsedSelectedStations: RentalCarOfficeWithDistanceWrapper[] = JSON.parse(
        selectedStations
      );
      if (parsedPostCode.code !== SELF_ARRIVAL_CODE) {
        try {
          const filteredBusStations = parsedSelectedStations.filter(
            s => stationCodes && s.code && stationCodes.includes(s.code)
          );
          let sortedByHaversine = DistanceUtil.sortByHaversineDistance(
            filteredBusStations,
            parsedPostCode
          );
          if (stationCodes?.includes(TAXI_30KM_CODE) && sortedByHaversine.length > 0) {
            sortedByHaversine = verifyAndAdd30kmStation(sortedByHaversine);
          }
          setAvailableStations(sortedByHaversine as AvailableStation[]);
        } catch (err) {
          console.error(err);
        }
      } else {
        const selfArrivalOption: AvailableStation = {
          description: t(Keys.extBookingOwnArrivalsAndDepartures),
          code: SELF_ARRIVAL_CODE,
          distance: -1,
          isSelected: false
        };
        setAvailableStations([selfArrivalOption]);
      }
    };

    loadOptions();
  }, []);

  const onNext = async () => {
    if (selectedStation) {
      await bs.updateBusStations(selectedStation as ApiRentalCarOffice);
      setSelectedItems({
        ...selectedItems,
        busStation: selectedStation as ApiRentalCarOffice,
        duration: {
          mainComponent,
          extensionDuration: 0
        }
      });
      store.setBusInformation({ busStationDescription: selectedStation.description });
    }
  };

  const [selectedStation, setSelectedStation] = useState<AvailableStation | null>(null);

  const handleOnChange = (station: AvailableStation) => {
    availableStations.forEach(s => (s.isSelected = false));
    station.isSelected = true;
    setSelectedStation(station);
    sessionStorage.setItem('busStopSelection', JSON.stringify(station.code));
  };

  return (
    <>
      {bs?.booking && (
        <FlightAndAccommodationSummaryHeader
          selectedItems={selectedItems}
          store={store}
          booking={bs.booking}
        />
      )}
      <CommonWrapper
        idx={idx}
        title={t(Keys.extBookingYourArrival)}
        description={
          isSelfArrivalOption()
            ? t(Keys.extBookingOwnArrivalsAndDeparturesInfo)
            : t(Keys.extBookingSelectOneOfTheFollowingStations)
        }
        successNotes={selectedStation?.description ? [selectedStation.description] : []}
        onNext={onNext}
        onClose={remount}
        disableButton={!selectedStation}
      >
        <div className="mt-0 mt-lg-3">
          {availableStations.map(station => {
            const { isSelected } = station;
            return (
              <StationRow
                key={station.description}
                station={station}
                isSelected={isSelected}
                handleOnChange={handleOnChange}
              ></StationRow>
            );
          })}
        </div>
      </CommonWrapper>
    </>
  );
};

interface StationRowProps {
  station: AvailableStation;
  isSelected: boolean;
  handleOnChange: (station: AvailableStation) => void;
}

const StationRow = (props: StationRowProps): JSX.Element => {
  const { station, isSelected = false, handleOnChange } = props;
  const distanceInformation =
    station.distance !== -1 ? ' (' + Math.round(station.distance) + ' km)' : '';
  const taxiLift = TAXI_30KM_CODE === station.code;
  return (
    <Row
      className={taxiLift ? 'mt-3 pt-3 pb-2 taxi-bus-stop' : 'pt-2 pb-2'}
      key={station.description}
    >
      <Col>
        <CustomInput
          id={`extended__booking__item-${station.description}`}
          name={`extended__booking__item-${station.description}`}
          className={`extended__booking__custom__input`}
          type="radio"
          label={
            <div
              className={`extended__booking__item ${
                isSelected ? 'extended__booking__item--selected' : ''
              }`}
            >
              <span className="custom-checkbox"></span>
              <div className="extended__booking__whitespace">
                {station.description + distanceInformation}
              </div>
            </div>
          }
          checked={isSelected}
          onChange={() => handleOnChange(station)}
        />
      </Col>
    </Row>
  );
};

export const BusStations = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={BusStopsComponent} props={{ idx }} />
);
