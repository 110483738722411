import React, { useCallback, useRef, useState, JSX } from 'react';
import SingleSelection from '../../Components/SingleSelection';
import fallback from '../../Translations/generated/product-ibe.de.json';
import Keys from '../../Translations/generated/product-ibe.de.json.keys';
import { isEqual } from 'lodash-es';
import {
  PackageDropdownFilter,
  PackageSearchParams,
  useConfig,
  useTranslation
} from '@ibe/components';
import { PackageCode, ProductIBEProps } from './PackagesList';
import { ApiBaseData, ApiBestPricePackageModel, ApiGeoUnit, ApiRentalCarOffice } from '@ibe/api';
import dayjs from 'dayjs';
import { PromotionCodeData } from '../../Components/PromotionCode';
import useThgConfig from '../../Hooks/useThgConfig';

export type FilterParams = {
  numberOfTravelers: number;
  origins: string[];
  busStations: string[];
  startDate: string;
  endDate: string;
  smallGroup: boolean;
  selectedPostalData: ApiBaseData | undefined;
};

export type InitialStorageFilters = {
  initialFilters?: FilterParams;
  initialFiltersChecked: boolean;
};

const filterPropsMapper = (
  smallGroup: boolean,
  searchParams?: Partial<PackageSearchParams>
): FilterParams => {
  return {
    numberOfTravelers: !!searchParams?.occupancy ? searchParams.occupancy[0].adults : 0,
    origins: searchParams?.origins?.map((origin: ApiGeoUnit) => origin.id) || [],
    busStations: searchParams?.busStations?.map((office: ApiRentalCarOffice) => office.code) || [],
    startDate: searchParams?.startDate || '',
    endDate: searchParams?.endDate || '',
    smallGroup
  } as FilterParams;
};

const Filters = ({
  earliestPossibleDate,
  latestPossibleDate,
  onChange,
  origins,
  packageCodes,
  maintenanceMode,
  numberOfTravelers,
  packages = [],
  promotionCode
}: {
  earliestPossibleDate: string;
  latestPossibleDate: string;
  onChange: (filters: FilterParams, ignoreNumberOfTravelers?: boolean) => void;
  origins: string[];
  packageCodes: ProductIBEProps['packageCodes'];
  maintenanceMode?: boolean;
  numberOfTravelers: number;
  packages: ApiBestPricePackageModel[];
  promotionCode: PromotionCodeData;
}): JSX.Element => {
  const config = useConfig();
  const { t } = useTranslation('product-ibe', fallback);

  type Mode = 'selectsStart' | 'selectsEnd' | 'none';
  const dateRangeRef = useRef<{
    focus?: (a: Mode) => void;
  }>({ focus: () => {} });

  const [smallGroupChecked, setSmallGroupChecked] = useState<boolean>(false);
  const [packageSearchParams, setPackageSearchParams] = useState<PackageSearchParams | undefined>(
    undefined
  );

  const thgConfig = useThgConfig();
  const imgBaseUrl = thgConfig.baseUrl || config.translationsUrl;

  const handleFilterChange = useCallback(
    (searchParams: Partial<PackageSearchParams>): void => {
      if (!isEqual(searchParams, packageSearchParams)) {
        setPackageSearchParams(searchParams);
        onChange(filterPropsMapper(smallGroupChecked, searchParams));
      }
    },
    [onChange, promotionCode]
  );

  const handleSmallGroupChecked = useCallback(
    (isChecked: boolean): void => {
      if (isChecked !== smallGroupChecked) {
        setSmallGroupChecked(isChecked);
        onChange(filterPropsMapper(isChecked, packageSearchParams), true);
      }
    },
    [onChange, promotionCode, smallGroupChecked]
  );
  const onToggle = useCallback(
    ({ isOpen, endDate, startDate }: { isOpen?: boolean; endDate?: Date; startDate?: Date }) => {
      if (isOpen && !endDate && startDate && dateRangeRef && dateRangeRef.current?.focus) {
        dateRangeRef.current?.focus('selectsEnd');
      }
    },
    [dateRangeRef]
  );

  return (
    <>
      {packageCodes.some(code => code.smallGroup) &&
      !packageCodes.every(code => code.smallGroup) &&
      (packageCodes.reduce(
        (total: string[], current: PackageCode) =>
          !total.includes(current.code) ? [...total, current.code] : [...total],
        []
      ).length > 1 ||
        !packageCodes.some(code => code.smallGroup)) &&
      packages.some(singlePackage => singlePackage.smallGroup) &&
      !maintenanceMode ? (
        <SingleSelection
          selectionId={'small-group-selection'}
          isChecked={smallGroupChecked}
          setIsChecked={handleSmallGroupChecked}
          infoText={t(Keys.smallGroupInfo)}
          checkboxLabel={t(Keys.smallGroupLabel)}
          imgSrc={`${imgBaseUrl}/img/kleingruppe.svg`}
          promotionCode={promotionCode}
        />
      ) : (
        <></>
      )}
      <PackageDropdownFilter
        onChange={handleFilterChange}
        datepickerMaxDate={latestPossibleDate}
        datepickerMinDate={earliestPossibleDate}
        origins={origins}
        disabled={maintenanceMode}
        defaultDate={{
          startDate: dayjs(earliestPossibleDate).isBefore(dayjs(new Date()))
            ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
            : earliestPossibleDate,
          endDate: latestPossibleDate
        }}
        onToggle={{
          calendar: onToggle
        }}
        dateRangeRef={dateRangeRef}
        numberOfTravelers={numberOfTravelers}
        memoDeps={[promotionCode]}
        showNumberSelector
      />
    </>
  );
};

export default Filters;
