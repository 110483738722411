import React, { PropsWithChildren, JSX } from 'react';
import useFixedElementScroll from './useFixedElementScroll';

const FixedElementScroll = (
  props: PropsWithChildren<{
    width?: number;
  }>
): JSX.Element => {
  const { children } = props;
  const [stickyBoxRef, containerRef] = useFixedElementScroll();
  return (
    <>
      <div ref={containerRef}></div>
      <div ref={stickyBoxRef} className="fixed-scroll__container d-none d-md-flex sticky-box">
        <div className="fixed-scroll__element">{children}</div>
      </div>
    </>
  );
};

export default FixedElementScroll;
