import React, { useCallback, useEffect, useState } from 'react';
import { ApiBooking } from '@ibe/api';
import {
  SessionData,
  useApi,
  useBookingService,
  useConfig,
  useTranslation,
  LoadingOverlay,
  ScrollerService,
  useExternalSearchParams
} from '@ibe/components';
import {
  checkoutFinishedEvent,
  LoggerFactory,
  MaskBookingService,
  SessionPersistence,
  SessionStorage,
  SessionStoragePersistence
} from '@ibe/services';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { Button } from 'reactstrap';
import { CheckoutStepComponentProps } from '../../../../Models/CheckoutStep';
import PageUrl from '../../../../Models/PageUrl';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';
import { usePaymentStatus } from '../../../../Util/usePaymentStatus';
import ProgressbarWrapper from '../ProgressBarWrapper';
import ConfirmationPage from './ConfirmationPage';
import { SessionStorageType } from '../../../../Components/Search';
import { productSearchSessionKey } from '../../../../Config/config';
import {
  clearStorage,
  packageIdSessionStorage,
  productIbeModeStorage,
  travelersSameAddressForms,
  whitelabelSeasonStorage
} from '../../../Util/utils';
import { BOOKINGID_STORAGE_KEY } from '../../../../Util/constant';
import { extendedBookingStorage } from '../ExtendedBooking/ContextAndProvider';
import { stepSessionStorage } from '../../../../Util/globals';

const sessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);

const Confirmation = observer((props: CheckoutStepComponentProps) => {
  const { store, rerouteUrl, homepageUrl, productIBEMode, isAgencyMode } = props;
  const logger = LoggerFactory.get('Confirmation');
  const bs = useBookingService();
  const { t } = useTranslation('Checkout', fallback);
  const config = useConfig();
  const history = useHistory();
  const { bookingId, hasPaymentError, paymentWasCancelled } = usePaymentStatus();
  const [retrievedBooking, setRetrievedBooking] = useState<ApiBooking | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useExternalSearchParams();

  const api = useApi();

  const forceBookingInit = async (): Promise<void> => {
    if (bs.booking === null) {
      return;
    }
    let session;
    if (!!context?.widgetSessionKeyPrefix) {
      session = new SessionStoragePersistence<SessionData>(SessionData);
      session.useKey(context.widgetSessionKeyPrefix + config.sessionKeyCart);
    } else {
      session = new SessionPersistence<SessionData>(SessionData);
      session.useKey(config.sessionKeyCart);
    }

    try {
      await bs.init();
      session.clear();
      const packageCartSession = new SessionStoragePersistence<string>();
      packageCartSession.useKey(context?.widgetSessionKeyPrefix + config.sessionKeyPackageCart);
      packageCartSession.clear();
    } catch (err) {
      logger.error(err);
    }
  };

  const retrieveBooking = useCallback(async (): Promise<void> => {
    const bidSessionStorage = new SessionStoragePersistence<string>();
    bidSessionStorage.useKey(context?.widgetSessionKeyPrefix + BOOKINGID_STORAGE_KEY);
    let useBookingId = null;
    if (bookingId === null) {
      useBookingId = bidSessionStorage.get();
    } else {
      useBookingId = bookingId;
      bidSessionStorage.set(bookingId);
    }
    if (useBookingId !== null) {
      let res: ApiBooking | null = null;
      try {
        res = await api.getBooking(useBookingId);
        if (res && bookingId !== null) {
          checkoutFinishedEvent.broadcast({
            booking: MaskBookingService.maskBookingWithClient(res),
            item: null
          });
        }
        setRetrievedBooking(res);
      } catch (err) {
        logger.error(err);
      }
    }
  }, [api, bookingId, logger]);

  useMount(async () => {
    sessionStorage.clear();
    setIsLoading(true);
    await Promise.all([await retrieveBooking(), await forceBookingInit()]);
    setIsLoading(false);
    // clear SessionStorage after confirmation loading
    if (isAgencyMode) {
      !!stepSessionStorage.get() ? stepSessionStorage.clear() : '';
      !!extendedBookingStorage.get() ? extendedBookingStorage.clear() : '';
      !!packageIdSessionStorage.get() ? packageIdSessionStorage.clear() : '';
      !!travelersSameAddressForms.get() ? travelersSameAddressForms.clear() : '';
      !!whitelabelSeasonStorage.get() ? whitelabelSeasonStorage.clear() : '';
      !!productIBEMode ? productIbeModeStorage.set(productIBEMode) : '';
    }
  });

  useEffect(() => {
    if (config.outsideElementsContainerId) {
      ScrollerService.scrollTo(config.outsideElementsContainerId, { offset: -100 });
    }
  }, []);

  const home = (): void => {
    clearStorage();
    if (!!homepageUrl) {
      location.href = homepageUrl;
    } else if (!!rerouteUrl) {
      location.href = rerouteUrl;
    } else {
      history.push(PageUrl.HOME);
    }
  };

  return (
    <LoadingOverlay isLoading={isLoading} className="checkout-workflow">
      <div className="confirmation-progress-bar">
        <ProgressbarWrapper store={store} disableClick />
      </div>
      <>
        {!(hasPaymentError || paymentWasCancelled) && retrievedBooking && productIBEMode && (
          <ConfirmationPage
            booking={retrievedBooking}
            store={store}
            productIBEMode={productIBEMode}
          >
            <div className="confirmation__back checkout-nav">
              <Button onClick={home}>{t(Keys.ConfirmationBackHome)}</Button>
            </div>
          </ConfirmationPage>
        )}
        {(hasPaymentError || paymentWasCancelled) && (
          <div className="confirmation__error">
            <h2>{t(Keys.PaymentErrorTitle)}</h2>
            <div>{t(Keys.PaymentError)}</div>
          </div>
        )}
      </>
    </LoadingOverlay>
  );
});
export default Confirmation;
