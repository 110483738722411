import React, { useEffect, useMemo, useState } from 'react';
import fallback from '../../../../Translations/generated/optional-offers.de.json';
import Keys from '../../../../Translations/generated/optional-offers.de.json.keys';
import {
  ApiAddItemRequest,
  ApiExtraService,
  ApiItemType,
  ApiPrice,
  ApiPriceFromJSON,
  ApiTraveler
} from '@ibe/api';
import { useTheme } from '@emotion/react';
import { Button, Col, CustomInput, Modal, ModalBody, Row } from 'reactstrap';
import { TFunction } from 'react-i18next';
import { ComponentWrapper, getCurrentSelectionState, MetaInfoCmsContent } from './additionals';
import { addAdditionalServicesEvent, sanitize } from '@ibe/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Price, useBookingService, useConfig, useTranslation } from '@ibe/components';
import { observer } from 'mobx-react';

const srcSetWidths: number[] = [480, 768, 1280, 1920];

const ExtraModal = observer(function ExtraModal({
  setModalOpen,
  onSubmit,
  modalOpen,
  price,
  extra,
  itemsBookingOptions,
  metaInfoCmsContent
}: {
  setModalOpen: (modelOpen: boolean) => void;
  modalOpen: boolean;
  onSubmit: (selectedTravelerIndices: string[]) => void;
  price: ApiPrice;
  extra: ComponentWrapper;
  itemsBookingOptions: { [key: string]: ApiAddItemRequest };
  metaInfoCmsContent?: MetaInfoCmsContent;
  isLoading?: boolean;
}): JSX.Element {
  const { t } = useTranslation('optional-offers', fallback, Keys);
  const config = useConfig();
  const theme = useTheme();
  const [selectedTravelers, setSelectedTravelers] = useState<string[]>([]);
  const bs = useBookingService();
  const imageTile = useMemo(() => metaInfoCmsContent?.imagePopup, [metaInfoCmsContent]);

  const getRestrictedExtraSelected = (travelerId: string): string[] => {
    if (
      extra.component.itemType === ApiItemType.EXTRA &&
      extra.component.selectedItems.length > 0
    ) {
      const currentExtra = extra.itemLevel as ApiExtraService;
      return extra.component.selectedItems
        .filter(item => {
          const candidate: ApiExtraService = item as ApiExtraService;
          return (
            candidate.code !== currentExtra.code &&
            candidate.relatedServices &&
            candidate.relatedServices.some(related => related.code === currentExtra.code) &&
            candidate.extraUnits &&
            candidate.extraUnits[0].unitRates &&
            itemsBookingOptions[candidate.extraUnits[0].unitRates[0].id] &&
            itemsBookingOptions[candidate.extraUnits[0].unitRates[0].id].travelers.some(
              trav => trav.id === travelerId
            )
          );
        })
        .map(item => {
          const blocker: ApiExtraService = item as ApiExtraService;
          return blocker.name;
        });
    }
    return [];
  };

  const getLabel = (traveler: ApiTraveler, tfunc: TFunction, index: number): string => {
    if (!!traveler.lastName || !!traveler.firstName) {
      return `${traveler.firstName || ''}${!!traveler.firstName && !!traveler.lastName ? ' ' : ''}${
        traveler.lastName || ''
      }`;
    } else {
      return `${index + 1}. ${tfunc(Keys.traveler)}`;
    }
  };

  const handleCheckboxChange = (travelerId: string): void => {
    let newSelectedTravelers = [...selectedTravelers];
    if (newSelectedTravelers.includes(travelerId)) {
      newSelectedTravelers.splice(newSelectedTravelers.indexOf(travelerId), 1);
    } else {
      newSelectedTravelers = [...newSelectedTravelers, travelerId];
    }
    setSelectedTravelers(newSelectedTravelers);
  };

  useEffect(() => {
    if (!!bs.booking?.travelers && !!itemsBookingOptions && modalOpen) {
      setSelectedTravelers(getCurrentSelectionState(itemsBookingOptions, extra));
    }
  }, [itemsBookingOptions, modalOpen]);

  const handleSubmit = (): void => {
    const selectedTravelersToSubmit = [...selectedTravelers];
    setSelectedTravelers([]);
    if (selectedTravelersToSubmit.length === 0) {
      const currentExtra = extra.itemLevel as ApiExtraService;
      const serviceType = currentExtra?.serviceType?.description;

      addAdditionalServicesEvent.broadcast({
        service_type: serviceType ?? extra.component.name,
        service_name: currentExtra?.name ?? '',
        additional_service: currentExtra?.name ?? '',
        remove: true
      });
    }
    onSubmit(selectedTravelersToSubmit);
  };

  const buildSrcSet = (urlString: string | undefined): string =>
    srcSetWidths.map(width => `${prepareUrlString(urlString, width)} ${width}w,`).join(' ');

  const prepareUrlString = (
    urlString: string | undefined,
    width: number,
    height?: number
  ): string | undefined =>
    urlString
      ?.replace(/_WIDTH_/, !!width ? `${width}` : '')
      .replace(/_HEIGHT_/, !!height ? `${height}` : '')
      .replaceAll(' ', '%20');

  const hasBlocker = useMemo(() => {
    return !!bs.booking?.travelers
      .flatMap((traveler: ApiTraveler) => !!getRestrictedExtraSelected(traveler.id).length)
      .find(block => block);
  }, [bs.booking?.travelers, extra]);

  return (
    <Modal
      className="optional-offer__modal modal-dialog--goldenRatio"
      isOpen={modalOpen}
      toggle={(): void => setModalOpen(!modalOpen)}
      backdrop
      container={
        !!config.outsideElementsContainerId
          ? document.getElementById(config.outsideElementsContainerId) || undefined
          : undefined
      }
    >
      <ModalBody>
        <div className="optional-offer__modal__close" onClick={(): void => setModalOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {!metaInfoCmsContent?.hidePopupImage && (
          <div className="optional-offer__modal__image">
            <img
              srcSet={
                !!imageTile && !!imageTile.focalPointImages?.goldenRatio
                  ? buildSrcSet(imageTile.focalPointImages?.goldenRatio)
                  : ''
              }
              src={
                !!imageTile && !!imageTile.focalPointImages?.goldenRatio
                  ? ''
                  : theme.placeholderImages.package
              }
              alt={imageTile?.imageAlt || ''}
            />
          </div>
        )}
        <div className="optional-offer__modal__content">
          {!!metaInfoCmsContent?.displayName ? (
            <h3 dangerouslySetInnerHTML={{ __html: sanitize(metaInfoCmsContent.displayName) }} />
          ) : (
            <h3>{extra?.unitLevel?.name || extra?.itemLevel?.name || ''}</h3>
          )}
          {!!metaInfoCmsContent?.descriptionPopup ? (
            <p
              dangerouslySetInnerHTML={{ __html: sanitize(metaInfoCmsContent.descriptionPopup) }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="optional-offer__modal__divider" />
        <div className="optional-offer__modal__selection">
          <div>{t(Keys.checkboxesHeadline)}</div>
          <div>
            {bs.booking?.travelers.map((traveler: ApiTraveler, idx: number) => {
              const blockers = getRestrictedExtraSelected(traveler.id);
              const nameCol = hasBlocker ? 5 : 12;
              const blockerCol = hasBlocker ? 7 : 0;
              return (
                <Row key={traveler.id} className="optional-offer__modal__checkbox">
                  <Col xs={9} sm={10} md={10}>
                    <Row>
                      <Col md={nameCol}>
                        <CustomInput
                          id={`option-offer-checkbox-${traveler.id}`}
                          type="checkbox"
                          label={getLabel(traveler, t, idx)}
                          checked={selectedTravelers.includes(traveler.id)}
                          onChange={(): void => handleCheckboxChange(traveler.id)}
                          disabled={getRestrictedExtraSelected(traveler.id).length > 0}
                        />
                      </Col>
                      {blockers.length > 0 && (
                        <Col md={blockerCol}>
                          <div className={'optional-offer__modal__message'}>
                            {t(Keys.blockedByService, { services: blockers.join(', ') })}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs={3} sm={2} md={2}>
                    <Price
                      price={price}
                      removeZeroDecimals
                      className={blockers.length > 0 ? 'text-muted' : ''}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
          <div className="optional-offer__modal__buttons__container">
            <div className="optional-offer__modal__total-price">
              {selectedTravelers.length * (price.finalPrice || 0) > 0 ? (
                <>
                  <span className="optional-offer__modal__total-price__label">
                    {t(Keys.totalPriceLabel)}
                  </span>
                  <Price
                    price={ApiPriceFromJSON({
                      ...price,
                      finalPrice: selectedTravelers.length * (price.finalPrice || 0)
                    })}
                    removeZeroDecimals
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="optional-offer__modal__buttons">
              <Button color="secondary" onClick={(): void => setModalOpen(false)}>
                {t(Keys.cancel)}
              </Button>
              <Button color="primary" onClick={handleSubmit}>
                {t(Keys.submit)}
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});

export default ExtraModal;
