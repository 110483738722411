import React, { useState, JSX } from 'react';
import { ApiHotel, ApiMetaInfo } from '@ibe/api';
import Carousel from 'react-multi-carousel';
import HotelDisplay from '../../../HotelDisplay';
import Keys from '../../../../../../Translations/generated/Checkout.de.json.keys';
import { useTranslation } from '@ibe/components';
import fallback from '../../../../../../Translations/generated/Checkout.de.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

type ApiHotelExtended = ApiHotel & {
  metaInfo?: ApiMetaInfo & {
    contentByMetaType?: {
      accommodation?: {
        content?: {
          tileDescription?: string;
          hotelName?: string;
          more?: string;
        };
      };
    };
  };
};

interface Props {
  isOptional: boolean | undefined;
  hotels: ApiHotelExtended[];
  selectedHotel?: ApiHotel;
  initialHotel: ApiHotel;
  onChange: (selectedHotel: ApiHotel) => void;
}

const HotelCarouselComponent = (props: Props): JSX.Element => {
  const { hotels, selectedHotel, initialHotel, onChange, isOptional } = props;
  const { t } = useTranslation('Checkout', fallback);
  const [hotelModalOpen, setHotelModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleHotelOpen = (isOpened: boolean) => setHotelModalOpen(isOpened);

  const LeftArrow = (arrowProps: { onClick?: () => void }) => {
    const { onClick } = arrowProps;
    return (
      <div
        className="swiper-button swiper-button-prev"
        role="button"
        tabIndex={0}
        aria-label={t(Keys.sliderPreviousHotel)}
        onClick={() => onClick && onClick()}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>
    );
  };

  const RightArrow = (arrowProps: { onClick?: () => void }) => {
    const { onClick } = arrowProps;
    return (
      <div
        className="swiper-button swiper-button-next"
        role="button"
        tabIndex={0}
        aria-label={t(Keys.sliderPreviousHotel)}
        onClick={() => onClick && onClick()}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    );
  };

  const handleBeforeChange = (nextIndex: number) => {
    setActiveIndex(nextIndex);
  };

  const isOutOfView = (index: number) => {
    if (window.innerWidth <= 575) {
      return index !== activeIndex;
    }
    return index < activeIndex || index >= activeIndex + 2;
  };

  return (
    !!hotels && (
      <Carousel
        arrows
        centerMode={false}
        containerClass="carousel-slides-container py-2"
        customLeftArrow={<LeftArrow />}
        customRightArrow={<RightArrow />}
        focusOnSelect={false}
        beforeChange={nextIndex => handleBeforeChange(nextIndex)}
        responsive={{
          // "Buchungsübersicht" col hidden
          mobileXS: {
            breakpoint: {
              max: 575,
              min: 0
            },
            items: 1
          },
          mobileSM: {
            breakpoint: {
              max: 767,
              min: 576
            },
            items: 2
          },
          // "Buchungsübersicht" col displayed
          tablet: {
            breakpoint: {
              max: 991,
              min: 768
            },
            items: 2
          },
          desktopLG: {
            breakpoint: {
              max: 1199,
              min: 992
            },
            items: 2
          },
          desktopXL: {
            breakpoint: {
              max: 3000,
              min: 1200
            },
            items: 2
          }
        }}
        swipeable={!hotelModalOpen}
        draggable={!hotelModalOpen}
        keyBoardControl={!hotelModalOpen}
      >
        {hotels.map((hotel, index) => {
          const opacity = isOutOfView(index) ? 0.3 : 1;

          return (
            <div
              className="d-flex col"
              key={hotel.id}
              style={{
                opacity: opacity,
                transition: 'opacity 0.3s ease-in-out'
              }}
            >
              <HotelDisplay
                isOptional={isOptional}
                hotel={hotel}
                selectedHotel={selectedHotel}
                initialHotel={initialHotel}
                onChange={onChange}
                modalStateChanged={toggleHotelOpen}
              />
            </div>
          );
        })}
      </Carousel>
    )
  );
};

export default HotelCarouselComponent;
