import { useBookingService } from '@ibe/components';
import React, { ComponentType, createContext, useContext, useMemo, useState } from 'react';

interface CollapsibleWizardItemComponentProps {
  idx: number;
}

export interface CollapsibleWizardItemType {
  component: ComponentType<CollapsibleWizardItemComponentProps>;
  hide?: boolean;
  type?: 'SEPARATOR' | 'ITEM';
  itemIndex?: number;
  isSuccess?: boolean;
  isActive?: boolean;
}

export interface Props {
  wizardCssSetting?: {
    fadeInDuration?: number;
    fadeoutDuration?: number;
  };
  items: CollapsibleWizardItemType[];
}

type WizardContextProps = {
  items: Props['items'];
  partitionedItems: Props['items'][];
  nextStep: (currentIndex: number, isSuccessful?: boolean) => void;
  getData: (idx: number) => CollapsibleWizardItemType | undefined;
  goTo: (idx: number) => void;
  getNextItem: (currentIndex: number) => Props['items'][0] | undefined;
  showRange: (start: number, end: number) => void;
  hideRange: (start: number, end: number) => void;
  closeAndMoveToNextMissingSuccesStep: (currentIndex: number) => void;
  getCurrentIndex: () => number;
};

const initialState: WizardContextProps = {
  items: [],
  partitionedItems: [],
  nextStep: () => null,
  goTo: (index: number) => null,
  getNextItem: () => undefined,
  getData: (idx: number) => undefined,
  hideRange: () => null,
  showRange: () => null,
  closeAndMoveToNextMissingSuccesStep: () => null,
  getCurrentIndex: () => 0
};
const CollapsibleWizardContext = createContext<WizardContextProps>(initialState);

export const useCollapsibleWizard = (): WizardContextProps => {
  const context = useContext(CollapsibleWizardContext);
  if (!context) {
    throw new Error('useCollapsibleWizard must be used within an CollapsibleWizardProvider');
  }
  return context;
};

const partitionItemsBySeparators = (wizardItems: CollapsibleWizardItemType[]) => {
  const partitionedItems: CollapsibleWizardItemType[][] = [];
  let currentGroup: CollapsibleWizardItemType[] = [];
  let groupIndex = 0;
  wizardItems.forEach(item => {
    if (item.type === 'SEPARATOR') {
      partitionedItems.push(currentGroup);
      partitionedItems.push([item]);
      currentGroup = [];
    } else {
      currentGroup.push({ ...item, itemIndex: groupIndex });
      groupIndex++;
    }
  });
  if (currentGroup.length > 0) partitionedItems.push(currentGroup);
  return partitionedItems;
};
export const CollapsibleWizardProvider = ({
  children,
  ...props
}: { children: JSX.Element } & Props) => {
  const [items, setItems] = useState(props.items);

  const closeStep = (currentIndex: number) => {
    if (currentIndex <= items.length) {
      items[currentIndex].isActive = false;
      setItems([...items]);
    }
  };

  const partitionedItems = useMemo(() => partitionItemsBySeparators(items), [items]);
  const closeAndMoveToNextMissingSuccesStep = (currentIndex: number) => {
    const nextItemToWorkOn = items.find(i => i.isSuccess === false && !i.hide);

    if (nextItemToWorkOn && nextItemToWorkOn.itemIndex) {
      goTo(nextItemToWorkOn.itemIndex);
    } else if (nextItemToWorkOn && !nextItemToWorkOn.itemIndex) {
      goTo(items.findIndex(i => i.component === nextItemToWorkOn.component));
    } else {
      closeStep(currentIndex);
    }
  };

  const getCurrentIndex = () => {
    return items.findIndex(item => item.isActive === true);
  };

  const bs = useBookingService();
  const nextStep = (currentIndex: number, isSuccessful?: boolean) => {
    for (let i = 0; i < items.length; i++) {
      if (i > currentIndex) {
        items[i].isSuccess = false;
        items[i].isActive = false;
      }
    }

    items[currentIndex].isSuccess = isSuccessful;
    items[currentIndex].isActive = false;

    const nextIndex = ++currentIndex;
    if (items[nextIndex]) {
      if (nextIndex <= items.length) {
        items[nextIndex].isActive = true;
      }
    }
    setItems([...items]);
  };

  const getData = (idx: number) => {
    return items[idx];
  };

  const getNextItem = (idx: number) => {
    if (items[idx + 1]) {
      return items[idx + 1];
    }
  };

  const goTo = (index: number) => {
    const newItems = items.map((item, idx) => {
      if (idx === index) {
        return { ...item, isActive: true };
      }
      return { ...item, isActive: false };
    });

    setItems(newItems);
  };

  const hideRange = (start: number, end: number) => {
    const newWizardItems = items.map((item, idx) => {
      if (idx >= start && idx <= end) {
        return { ...item, hide: true };
      }
      return item;
    });
    setItems(newWizardItems);
  };
  const showRange = (start: number, end: number) => {
    const newWizardItems = items.map((item, idx) => {
      if (idx >= start && idx <= end) {
        return { ...item, hide: false };
      }
      return item;
    });
    setItems(newWizardItems);
  };

  return (
    <CollapsibleWizardContext.Provider
      value={{
        items,
        partitionedItems,
        nextStep,
        getData,
        getNextItem,
        goTo,
        hideRange,
        showRange,
        closeAndMoveToNextMissingSuccesStep,
        getCurrentIndex
      }}
    >
      {children}
    </CollapsibleWizardContext.Provider>
  );
};
