import React, { JSX, useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Error, ScrollerService, useTranslation } from '@ibe/components';
import { workflowContext } from '../contexts';
import { Element as ScrollTo } from 'react-scroll';
import CheckoutStore, { ProductIBEMode } from '../Pages/Checkout/CheckoutStore';
import { CheckoutStep } from '../Models/CheckoutStep';
import PageUrl from '../Models/PageUrl';
import { useBookingError } from '../Util/useBookingError';
import fallback from '../Translations/generated/Checkout.de.json';
import Keys from '../Translations/generated/Checkout.de.json.keys';
import { productDetailsPageUrlStorage } from '../Pages/Util/utils';
import useUrlPersistence from '../Pages/Util/useUrlPersistence';
import { stepSessionStorage } from '../Util/globals';
import { PackageCode } from '../Pages/Products/PackagesList';
import { ThgConfigModel } from '../Config/ThgConfigModel';
import { useAffiliateContext } from '../Util/AffiliateProvider';

const scrollElementError = 'checkoutErrorScroller';

const StepSwitch = (props: {
  baseUrl: string;
  steps: CheckoutStep[];
  store: CheckoutStore;
  rerouteUrl?: string;
  homepageUrl?: string;
  navBarOffset?: number;
  productIBEMode?: ProductIBEMode;
  isWhiteLabel?: boolean;
  isAgencyMode?: boolean;
  agencyNumber?: string;
  packageCodes?: PackageCode[];
  thgConfig?: ThgConfigModel;
  promotionCode?: string;
  packageType?: string;
}): JSX.Element => {
  const workflow = useContext(workflowContext);
  const {
    steps,
    store,
    rerouteUrl,
    homepageUrl,
    navBarOffset,
    productIBEMode,
    isWhiteLabel,
    isAgencyMode,
    agencyNumber,
    packageCodes,
    thgConfig,
    promotionCode,
    packageType
  } = props;
  const { step } = useParams<{ step: string }>();
  const currentStep = steps.find(s => s.slug === step);
  workflow.setCurrentStep(currentStep || null);

  useEffect(() => {
    if (currentStep) {
      stepSessionStorage.set(currentStep.slug);
    }
  }, [currentStep]);

  if (currentStep) {
    store.setActiveStep(currentStep.type);
    const CheckoutComponent = currentStep.component;
    if (CheckoutComponent) {
      return (
        <>
          <CheckoutComponent
            store={store}
            rerouteUrl={rerouteUrl}
            homepageUrl={homepageUrl}
            navBarOffset={navBarOffset}
            productIBEMode={productIBEMode}
            isWhitelabel={isWhiteLabel}
            isAgencyMode={isAgencyMode}
            agencyNumber={agencyNumber}
            packageCodes={packageCodes}
            thgConfig={thgConfig}
            promotionCode={promotionCode}
            packageType={packageType}
          ></CheckoutComponent>
        </>
      );
    } else {
      return <Navigate to={PageUrl.PACKAGES} />;
    }
  }

  return <Navigate to="/404" />;
};

const CheckoutWorkflow = (props: {
  baseUrl: string;
  steps: CheckoutStep[];
  store: CheckoutStore;
  rerouteUrl?: string;
  homepageUrl?: string;
  navBarOffset?: number;
  productIBEMode?: ProductIBEMode;
  isWhitelabel?: boolean;
  agencyNumber?: string;
  isAgencyMode?: boolean;
  packageCodes?: PackageCode[];
  thgConfig?: ThgConfigModel;
  promotionCode?: string;
  packageType?: string;
}): JSX.Element => {
  const workflow = useContext(workflowContext);
  const {
    baseUrl,
    steps,
    store,
    rerouteUrl,
    homepageUrl,
    navBarOffset,
    productIBEMode,
    isWhitelabel,
    agencyNumber,
    isAgencyMode,
    packageCodes,
    thgConfig,
    promotionCode,
    packageType
  } = props;
  const { t } = useTranslation('Checkout', fallback);
  const [error, setError] = useState<string | undefined>(undefined);
  const affiliate = useAffiliateContext();
  const hasBookingError = useBookingError();
  workflow.setSteps(steps);

  const sessionPersistence = useUrlPersistence();
  const navigate = useNavigate();
  const loc = useLocation();
  const previousPath = useRef<string>(loc.pathname);

  const affiliateBookingErrorMessage = !!affiliate?.metaContent?.content?.bookingErrorMessage
    ? `${affiliate.metaContent.content.bookingErrorMessage}`
    : undefined;

  const scrollToError = (): void => {
    ScrollerService.scrollTo(scrollElementError);
  };

  const prev = () => {
    if (!productDetailsPageUrlStorage.get()) {
      if (!!rerouteUrl) {
        location.href = rerouteUrl;
      } else {
        const url = sessionPersistence.get()?.url;
        navigate(PageUrl.CHECKOUT + '/' + (url || workflow.prevStepSlug || ''));
      }
    } else {
      const url = productDetailsPageUrlStorage.get();
      productDetailsPageUrlStorage.clear();
      if (!!url) {
        location.href = url;
      }
    }
  };

  useEffect(() => {
    if (hasBookingError) {
      setError(
        affiliateBookingErrorMessage
          ? affiliateBookingErrorMessage
          : t(Keys.bookingAttemptErrorText)
      );
      scrollToError();
    }
  }, [hasBookingError, affiliateBookingErrorMessage]);

  useEffect(() => {
    if (loc.pathname === '/' && previousPath.current.includes('summary')) {
      prev();
    }
    previousPath.current = loc.pathname;
  }, [loc, previousPath, history, sessionPersistence, productIBEMode, isWhitelabel, rerouteUrl]);

  return (
    <>
      <ScrollTo name={scrollElementError}>
        {error && (
          <>
            <button className={'mb-4 btn btn-secondary'} onClick={prev} aria-label={t(Keys.prev)}>
              {t(Keys.prev)}
            </button>
            <Error message={error || ''} />
          </>
        )}
      </ScrollTo>
      <Routes>
        <Route
          path={baseUrl}
          element={
            <>
              {isWhitelabel ? (
                <Navigate to={`${baseUrl}/${steps[0].slug}`} />
              ) : (
                <Navigate to={`${baseUrl}/${steps[1].slug}`} />
              )}
            </>
          }
        />
        <Route
          path={`${baseUrl}/:step`}
          element={
            <StepSwitch
              steps={steps}
              baseUrl={baseUrl}
              store={store}
              rerouteUrl={rerouteUrl}
              homepageUrl={homepageUrl}
              navBarOffset={navBarOffset}
              productIBEMode={productIBEMode}
              isWhiteLabel={isWhitelabel}
              agencyNumber={agencyNumber}
              isAgencyMode={isAgencyMode}
              packageCodes={packageCodes}
              thgConfig={thgConfig}
              promotionCode={promotionCode}
              packageType={packageType}
            />
          }
        />
      </Routes>
    </>
  );
};
export default CheckoutWorkflow;
