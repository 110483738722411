import React, { createContext, PropsWithChildren, useContext, JSX } from 'react';

export const BookingErrorContext = createContext<boolean>(false);

export const BookingErrorProvider = ({
  children,
  hasBookingError
}: PropsWithChildren<{ hasBookingError: boolean }>): JSX.Element => {
  return (
    <BookingErrorContext.Provider value={hasBookingError}>{children}</BookingErrorContext.Provider>
  );
};

export const useBookingError = (): boolean => {
  return useContext<boolean>(BookingErrorContext);
};
