import React, { useMemo } from 'react';
import { ApiBooking, ApiTraveler } from '@ibe/api';
import CheckoutStore, { ProductIBEMode } from '../../../../Pages/Checkout/CheckoutStore';
import { useExtendedBooking } from './ContextAndProvider';
import { isValidWorkflow } from './workflowValidation';
import { MobileBar } from '../StickySummary';
import { useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../Translations/generated/Checkout.de.json.keys';

interface Props {
  booking: ApiBooking;
  next: () => void;
  disabled: boolean;
  loading: boolean;
  store: CheckoutStore;
  productIBEMode?: ProductIBEMode;
  showPromotionCode?: boolean;
  handleBookingError?: (errorMessage: string) => void;
  travelers?: ApiTraveler[];
  isWhitelabel?: boolean;
}

const FixedBottomBar = (props: Props) => {
  const {
    booking,
    loading,
    next,
    productIBEMode,
    showPromotionCode,
    handleBookingError,
    travelers,
    isWhitelabel,
    store
  } = props;

  const { selectedItems } = useExtendedBooking();

  const { t } = useTranslation('Checkout', fallback);

  const nextButtonIsDisabled = useMemo(() => {
    return !isValidWorkflow(selectedItems);
  }, [selectedItems]);

  return (
    <div>
      <MobileBar
        booking={booking}
        next={next}
        disabled={nextButtonIsDisabled}
        loading={loading}
        rightBottomText={t(Keys.extBookingMobileBarNext)}
        productIBEMode={productIBEMode}
        showPromotionCode={showPromotionCode}
        handleBookingError={handleBookingError}
        travelers={travelers}
        isWhitelabel={isWhitelabel}
        store={store}
      />
    </div>
  );
};

export default FixedBottomBar;
