import * as ReactHabitat from 'react-habitat';
import WhitelabelWidget from '../widgets/WhitelabelWidget';
import { CustomHabitatFactory } from './CustomHabitatFactory';

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const builder = new ReactHabitat.ContainerBuilder();
    builder.factory = new CustomHabitatFactory();
    builder.register(WhitelabelWidget).as('ThgWhitelabel');
    this.setContainer(builder.build());
  }
}

export default new Habitat();
