import React from 'react';

import {
  ApiBookedItem,
  ApiBooking,
  ApiComponentType,
  ApiFlightItem,
  ApiHotel,
  ApiItemType
} from '@ibe/api';
import dayjs, { PluginFunc } from 'dayjs';
import * as dur from 'dayjs/plugin/duration';
import * as iUTC from 'dayjs/plugin/utc';
import { useBookingService, useTranslation } from '@ibe/components';

import fallback from '../../../../Translations/generated/price-overview-header.de.json';
import Keys from '../../../../Translations/generated/price-overview-header.de.json.keys';

import { observer } from 'mobx-react';
import { useExtendedBookingForPriceOverview } from '../ExtendedBooking/ContextAndProvider';
import { computeDuration } from '../../../Util/utils';

dayjs.extend((iUTC as unknown) as PluginFunc<unknown>);
dayjs.extend((dur as unknown) as PluginFunc<unknown>);

interface Props {
  booking: ApiBooking;
  title?: string;
  withHotel?: boolean;
  withFlight?: boolean;
  withDuration?: boolean;
  withTravelDates?: boolean;
}

const PriceOverviewHeader = observer(
  ({
    booking,
    title,
    withFlight,
    withHotel = false,
    withDuration = true,
    withTravelDates = true
  }: Props) => {
    //--------fetch Items-------------------
    // - selectedItems
    const selectedItems = useExtendedBookingForPriceOverview();
    const { duration } = selectedItems || {};
    let { extensionDuration } = duration || {};

    const isItemsDuration =
      duration &&
      extensionDuration &&
      (selectedItems?.steps === undefined ||
        selectedItems?.steps?.some(s => s.isSuccess == false && s.type === undefined)) &&
      extensionDuration > 0;

    // - bookedItems
    let bookedPackage = booking.bookedItems.find(
      it => it.itemType === ApiItemType.PACKAGE
    ) as ApiBookedItem;
    // When no extension is selected the dates need to adhere to the mainhotel
    if (extensionDuration === 0) {
      bookedPackage = booking.bookedItems?.find(
        (it: ApiBookedItem) =>
          it.itemType === ApiItemType.HOTEL && it.componentType === ApiComponentType.MAIN
      ) as ApiBookedItem;
    }
    const { startDate: travelStartDate, endDate: travelEndDate } = bookedPackage || {};
    //---------get Values-----------------

    // - selectedItems

    extensionDuration =
      duration && duration.extensionDuration > 0 ? duration.extensionDuration - 1 : 0;

    const mainDurationSelected = duration
      ? computeDuration((duration?.mainComponent.selectedItems[0] as ApiHotel).durationInfo)
      : 0;
    const startDate = dayjs(duration?.mainComponent.startDate);
    const endDate = dayjs(duration?.mainComponent.endDate).add(extensionDuration, 'days');
    const totalDurationItems = mainDurationSelected + extensionDuration;

    // - bookedItems
    const mainDurationBooking =
      dayjs.duration(dayjs(travelEndDate).diff(dayjs(travelStartDate))).days() + 1;

    //-----------decide duration--------------
    const showDuration = isItemsDuration ? totalDurationItems : mainDurationBooking;

    const travelers = booking?.travelers;
    const numberOfTravelers = travelers?.length || 0;

    const flights = booking?.items.filter(
      it => it.itemType === ApiItemType.FLIGHT
    ) as ApiFlightItem[];
    const outBoundFlight = flights[0];

    const hotel = booking?.items.find(it => it.itemType === ApiItemType.HOTEL) as ApiHotel;

    // internationalization of duration
    const { t } = useTranslation('price-overview-header', fallback);
    const numberOfDaysLabel = t(Keys.day, {
      count: showDuration,
      numberOfDay: showDuration
    });

    //-- selectedItems
    const startDateToShowItem = startDate.format('DD.MM.YYYY');
    const endDateToShowItem = endDate.format('DD.MM.YYYY');

    //-- bookedItems
    const startDateToShowBooking = `${dayjs.utc(travelStartDate).format('DD.MM.YYYY')}`;
    const endDateToShowBooking = `${dayjs.utc(travelEndDate).format('DD.MM.YYYY')}`;

    const stick = ' - ';

    const dateToShow = !isItemsDuration
      ? startDateToShowBooking + stick + endDateToShowBooking
      : startDateToShowItem + stick + endDateToShowItem;

    const bs = useBookingService();
    const selectablePackageOffers = !!bs.selectablePackageOffers
      ? [...bs.selectablePackageOffers]
      : [];
    return (
      <div className="iso__price__overview__header">
        <h4>{title ?? t(Keys.priceOverview)}</h4>
        {withTravelDates ? (
          <div>
            <span>{t(Keys.travelDate)} </span>
            <span> {dateToShow}</span>
          </div>
        ) : (
          <></>
        )}
        <div>
          <span>{t(Keys.numberParticipants)}</span>
          <span>{numberOfTravelers}</span>
        </div>
        {withDuration ? (
          <div>
            <span>{t(Keys.duration)}</span>
            <span>{numberOfDaysLabel}</span>
          </div>
        ) : (
          <></>
        )}
        {withHotel ? (
          <div>
            <span>{t(Keys.hotel)}</span>
            <span>{bookedPackage.description}</span>
          </div>
        ) : (
          <></>
        )}
        {withHotel && selectablePackageOffers.length > 1 ? (
          <div>
            <span>{t(Keys.hotel)}</span>
            <span>{hotel.name}</span>
          </div>
        ) : (
          <></>
        )}

        {outBoundFlight && withFlight && (
          <div>
            <span>{t(Keys.departureAirport)}</span>
            <span>
              {outBoundFlight?.segment[0]?.origin?.description} (
              {outBoundFlight?.segment[0]?.origin?.code})
            </span>
          </div>
        )}
      </div>
    );
  }
);

export default PriceOverviewHeader;
