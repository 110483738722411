import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import { ImageProgressbar, useTranslation } from '@ibe/components';
import fallback from '../../../Translations/generated/Checkout.de.json';
import CheckoutStore from '../CheckoutStore';
import { productIBEWorkflowCheckout, workflowCheckout } from '../../../steps';
import CheckoutPageUrl from '../CheckoutPageUrl';
import useUrlPersistence from '../../Util/useUrlPersistence';
import { CheckoutStep } from '../../../Models/CheckoutStep';
import { ibeFlightClassName } from './Traveler';
import { productDetailsPageUrlStorage, productIbeModeStorage } from '../../Util/utils';
import { extendedBookingStorage } from './ExtendedBooking/ContextAndProvider';

const ProgressbarWrapper = observer(
  ({
    store,
    disableClick,
    rerouteUrl,
    onClick,
    isWhitelabel
  }: {
    store: CheckoutStore;
    disableClick?: boolean;
    rerouteUrl?: string;
    onClick?: (step: CheckoutStep) => void;
    isWhitelabel?: boolean;
  }) => {
    const { t } = useTranslation('Checkout', fallback);
    const navigate = useNavigate();
    const sessionPersistence = useUrlPersistence();
    const isProductIBEMode = store.isProductIBEMode
      ? store.isProductIBEMode
      : !extendedBookingStorage.get() && !!productIbeModeStorage.get();
    const productIBEMode = store.productIbeModeObject;

    const translatedSteps = useMemo(() => {
      return store.steps.map(step => {
        return { ...step, description: t(step.description) };
      });
    }, [store, t]);

    const handleClick = (stepIndex: number): void => {
      if (!disableClick) {
        const workflow = productIBEMode?.bookingHasComponents
          ? productIBEWorkflowCheckout
          : workflowCheckout;
        const step = workflow[stepIndex];
        if (!!step) {
          if (!!onClick) {
            onClick(step);
          }
          if (
            isProductIBEMode &&
            !!productDetailsPageUrlStorage.get() &&
            stepIndex === 0 &&
            !isWhitelabel
          ) {
            const url = productDetailsPageUrlStorage.get();
            productDetailsPageUrlStorage.clear();
            if (!!url) {
              location.href = url;
            }
          } else if (!!rerouteUrl && stepIndex === 0 && !isWhitelabel) {
            location.href = rerouteUrl;
          } else {
            let url = '';
            if (stepIndex === 0 && !isWhitelabel) {
              url = sessionPersistence.get()?.url;
            }
            navigate(
              url ||
                Object.values(CheckoutPageUrl).find(innerUrl => innerUrl.includes(step.slug)) ||
                ''
            );
          }
        }
      }
    };
    return (
      <div className={`mb-4 ${isProductIBEMode ? ibeFlightClassName : ''}`}>
        <ImageProgressbar steps={translatedSteps} onClick={handleClick} showNotActiveIcon />
      </div>
    );
  }
);

export default ProgressbarWrapper;
