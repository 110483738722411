import React, { JSX } from 'react';
import { useConfig } from '@ibe/components';
import useThgConfig from '../Hooks/useThgConfig';
import { PackageTypes } from '../Pages/Products/ProductIBE';

interface CustomAnimationSpinnerProps {
  packageType?: string;
}

const CustomAnimationSpinner = (props: CustomAnimationSpinnerProps): JSX.Element => {
  const config = useConfig();
  const thgConfig = useThgConfig();
  const baseUrlStr = thgConfig.baseUrl || config.translationsUrl;
  const { packageType } = props;
  const animationSrc =
    packageType === PackageTypes.PBUS
      ? 'ladebildschirm_900x506px_bus.gif'
      : 'ladebildschirm_900x506px_flug.gif';
  return (
    <div className={'animated-spinner-container'}>
      <img
        className="animated-spinner"
        src={`${baseUrlStr}/img/${animationSrc}`}
        alt="a loading spinner"
      />
    </div>
  );
};

export default CustomAnimationSpinner;
