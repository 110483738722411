import React, { ReactNode, useMemo, JSX } from 'react';
import { ApiComponent, ApiPackageOffer } from '@ibe/api';
import { getExtras } from './additionals';
import Extras from './Extras';
import { observer } from 'mobx-react';

export interface Props {
  components: Array<ApiComponent>;
  onPackageOfferChange?: (packageOffer: ApiPackageOffer) => void;
  headContent?: ReactNode;
  extrasHeadContent?: Array<ReactNode>;
  extrasGroupOrder?: [string, string];
  isLoading?: boolean;
}

const OptionalOffers = observer(function (props: Props): JSX.Element {
  const { components, headContent, extrasHeadContent, extrasGroupOrder, isLoading } = props;

  const extras = useMemo(() => getExtras(components), [components]);

  return (
    <div>
      {!!headContent && extras.length > 0 ? headContent : <></>}
      {extras.length > 0 ? (
        <Extras
          extras={extras}
          extrasHeadContent={extrasHeadContent}
          extrasGroupOrder={extrasGroupOrder}
          isLoading={isLoading}
        />
      ) : (
        <></>
      )}
    </div>
  );
});

export default OptionalOffers;
