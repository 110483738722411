/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-01 10:05:52 */

const Keys = {
  checkoutHeader: 'checkoutHeader',
  noBooking: 'noBooking',
  summary: 'summary',
  extras: 'extras',
  participant: 'participant',
  confirmation: 'confirmation',
  travelDate: 'travelDate',
  order: 'order',
  prev: 'prev',
  next: 'next',
  summaryAcceptTaC: 'summaryAcceptTaC',
  summaryTaCLinkText: 'summaryTaCLinkText',
  summaryTaCLinkTextWhiteLabel: 'summaryTaCLinkTextWhiteLabel',
  summaryTaCLink: 'summaryTaCLink',
  summaryAcceptTac2: 'summaryAcceptTac2',
  summaryTermsAndConditions: 'summaryTermsAndConditions',
  summaryEmailMarketingTitle: 'summaryEmailMarketingTitle',
  summaryEmailMarketingText: 'summaryEmailMarketingText',
  summaryEmailMarketingText2: 'summaryEmailMarketingText2',
  summaryGlobistaMarketingTitle: 'summaryGlobistaMarketingTitle',
  summaryGlobistaMarketingText: 'summaryGlobistaMarketingText',
  summaryGlobistaMarketingText2: 'summaryGlobistaMarketingText2',
  dataSecurityLink: 'dataSecurityLink',
  summaryAcceptTaCError: 'summaryAcceptTaCError',
  SummaryBookingError: 'SummaryBookingError',
  ConfirmationHeadline: 'ConfirmationHeadline',
  ConfirmationBookingNumber: 'ConfirmationBookingNumber',
  ConfirmationThankYou: 'ConfirmationThankYou',
  ConfirmationThankYouTrendtours: 'ConfirmationThankYouTrendtours',
  ConfirmationDocuments: 'ConfirmationDocuments',
  CheckCustomerData: 'CheckCustomerData',
  ConfirmationBackHome: 'ConfirmationBackHome',
  PaymentErrorTitle: 'PaymentErrorTitle',
  PaymentError: 'PaymentError',
  AssignmentError: 'AssignmentError',
  errorDriverAge: 'errorDriverAge',
  errorAdultCount: 'errorAdultCount',
  errorChildCount: 'errorChildCount',
  errorChildrenAge: 'errorChildrenAge',
  errorInfantCount: 'errorInfantCount',
  errorMinLength: 'errorMinLength',
  errorMaxLength: 'errorMaxLength',
  errorInvalidName: 'errorInvalidName',
  errorInvalidFirstMiddleName: 'errorInvalidFirstMiddleName',
  errorInvalidChar: 'errorInvalidChar',
  errorRequiredField: 'errorRequiredField',
  errorInvalidAdult: 'errorInvalidAdult',
  errorInvalidChild: 'errorInvalidChild',
  errorInvalidPostCode: 'errorInvalidPostCode',
  errorEmail: 'errorEmail',
  errorPhone: 'errorPhone',
  errorPaxAssignmentNotUnique: 'errorPaxAssignmentNotUnique',
  bookingAttemptErrorText: 'bookingAttemptErrorText',
  fillInParticipantsData: 'fillInParticipantsData',
  customer: 'customer',
  ADULT: 'ADULT',
  CHILD: 'CHILD',
  INFANT: 'INFANT',
  sameAddressAsFirstAdult: 'sameAddressAsFirstAdult',
  customerNameInfo: 'customerNameInfo',
  customerInfo: 'customerInfo',
  book: 'book',
  requiredFields: 'requiredFields',
  back: 'back',
  consulting: 'consulting',
  officeHours: 'officeHours',
  isInclusiv: 'isInclusiv',
  numberOfNight: 'numberOfNight',
  numberOfNight_plural: 'numberOfNight_plural',
  adapt: 'adapt',
  adaptRoom: 'adaptRoom',
  FlightSummaryCardImageAlt: 'FlightSummaryCardImageAlt',
  AccomodationAndBoardImageAlt: 'AccomodationAndBoardImageAlt',
  packageTourNote: 'packageTourNote',
  packageTourNoteText: 'packageTourNoteText',
  packageTourNoteTextProductIBEMode: 'packageTourNoteTextProductIBEMode',
  packageTourNoteLinkText: 'packageTourNoteLinkText',
  packageTourNoteLinkLink: 'packageTourNoteLinkLink',
  numberOfPersons: 'numberOfPersons',
  numberOfPersons_plural: 'numberOfPersons_plural',
  getPaxRoomAssignmentsErrorSearch: 'getPaxRoomAssignmentsErrorSearch',
  insuranceHeader: 'insuranceHeader',
  insuranceIntroText: 'insuranceIntroText',
  insuranceDataLoading: 'insuranceDataLoading',
  insuranceWithDeductible: 'insuranceWithDeductible',
  insuranceWithoutDeductible: 'insuranceWithoutDeductible',
  insuranceNone: 'insuranceNone',
  insuranceRecommendation: 'insuranceRecommendation',
  insuranceLegalInfo: 'insuranceLegalInfo',
  insuranceNoneWanted: 'insuranceNoneWanted',
  insuranceTravelCancellation: 'insuranceTravelCancellation',
  insuranceHolidayWarranty: 'insuranceHolidayWarranty',
  insuranceHealth: 'insuranceHealth',
  insuranceSelect: 'insuranceSelect',
  insuranceSelected: 'insuranceSelected',
  insuranceAgb: 'insuranceAgb',
  insuranceAgbLink: 'insuranceAgbLink',
  insuranceNoneSelectedMessage: 'insuranceNoneSelectedMessage',
  insuranceYourInsurance: 'insuranceYourInsurance',
  insuranceNoInsuranceSelected: 'insuranceNoInsuranceSelected',
  insuranceNo: 'insuranceNo',
  loyaltyCard: 'loyaltyCard',
  loyaltyCardSubText: 'loyaltyCardSubText',
  loyaltyCardSubmit: 'loyaltyCardSubmit',
  loyaltyCardNotValid: 'loyaltyCardNotValid',
  loyaltyCardValid: 'loyaltyCardValid',
  loyaltyCardNumberMismatch: 'loyaltyCardNumberMismatch',
  paymentOptions: 'paymentOptions',
  invoice: 'invoice',
  promoCodeHeader: 'promoCodeHeader',
  promoCodeLabel: 'promoCodeLabel',
  person: 'person',
  persons: 'persons',
  Details: 'Details',
  hotlineNumber: 'hotlineNumber',
  priceDetail: 'priceDetail',
  totalPrice: 'totalPrice',
  close: 'close',
  travelOverview: 'travelOverview',
  paymentNoteTitle: 'paymentNoteTitle',
  paymentNote: 'paymentNote',
  priceOverview: 'priceOverview',
  yourFlight: 'yourFlight',
  optionalOffersHeadline: 'optionalOffersHeadline',
  optionalOffersSubHeadline: 'optionalOffersSubHeadline',
  extrasHeadline: 'extrasHeadline',
  extrasSubHeadline: 'extrasSubHeadline',
  excursionsHeadline: 'excursionsHeadline',
  excursionsSubHeadline: 'excursionsSubHeadline',
  firstname: 'firstname',
  lastname: 'lastname',
  firstnameEmpty: 'firstnameEmpty',
  lastnameEmpty: 'lastnameEmpty',
  invoicePaymentInfo: 'invoicePaymentInfo',
  extBookingYourArrival: 'extBookingYourArrival',
  extBookingSelectOneOfTheFollowingStations: 'extBookingSelectOneOfTheFollowingStations',
  extBookingOwnArrivalsAndDepartures: 'extBookingOwnArrivalsAndDepartures',
  extBookingOwnArrivalsAndDeparturesInfo: 'extBookingOwnArrivalsAndDeparturesInfo',
  extBookingYourHotel: 'extBookingYourHotel',
  extBookingYourHotelOptional: 'extBookingYourHotelOptional',
  extBookingSelectYourHotel: 'extBookingSelectYourHotel',
  extBookingSelectYourRoom: 'extBookingSelectYourRoom',
  extBookingSelectYourMealType: 'extBookingSelectYourMealType',
  extBookingMoreInfo: 'extBookingMoreInfo',
  extBookingRadioSelected: 'extBookingRadioSelected',
  extBookingRadioSelect: 'extBookingRadioSelect',
  extBookingPerPerson: 'extBookingPerPerson',
  extBookingTravelers: 'extBookingTravelers',
  extBookingEnterTravelers: 'extBookingEnterTravelers',
  extBookingEnteredTravelers: 'extBookingEnteredTravelers',
  extBookingMobileBarNext: 'extBookingMobileBarNext',
  extBookingUnavailable: 'extBookingUnavailable',
  extBookingCanotCombined: 'extBookingCanotCombined',
  extBookingExclusionWarning: 'extBookingExclusionWarning',
  extBookingExclusionHotelSelectionWarning: 'extBookingExclusionHotelSelectionWarning',
  tripDuration: 'tripDuration',
  yourCatering: 'yourCatering',
  yourCateringOptional: 'yourCateringOptional',
  sliderPreviousHotel: 'sliderPreviousHotel',
  sliderNextHotel: 'sliderNextHotel',
  standardTripDuration: 'standardTripDuration',
  extension: 'extension',
  day: 'day',
  day_plural: 'day_plural',
  night: 'night',
  night_plural: 'night_plural',
  numberOfRooms: 'numberOfRooms',
  inclusive: 'inclusive',
  yourRoom: 'yourRoom',
  yourRoomOptional: 'yourRoomOptional',
  room: 'room',
  roomExtension: 'roomExtension',
  person_plural: 'person_plural',
  extensionDetail: 'extensionDetail',
  yourExtension: 'yourExtension',
  apply: 'apply',
  change: 'change',
  chooseTraveler: 'chooseTraveler',
  noOptions: 'noOptions',
  roomOnly: 'roomOnly',
  selectYourHotel: 'selectYourHotel',
  halfRoomSelectionInfo: 'halfRoomSelectionInfo',
  stopMax30kmFromResidence: 'stopMax30kmFromResidence',
  yourBusStop: 'yourBusStop'
}; export default Keys;