import React, { useCallback, useMemo, useState } from 'react';
import {
  ApiBookedItem,
  ApiComponent,
  ApiComponentType,
  ApiHotel,
  ApiItemType,
  ApiPrice,
  ApiPriceFromJSON,
  ApiRelatedServiceRelationType
} from '@ibe/api';
import { Price, useBookingService, useTranslation } from '@ibe/components';
import CommonWrapper, { RemountWrapper } from '../CommonWrapper';
import fallback from '../../../../../Translations/generated/Checkout.de.json';
import Keys from '../../../../../Translations/generated/Checkout.de.json.keys';

import { Col, CustomInput, Row } from 'reactstrap';
import { useExtendedBooking } from '../ContextAndProvider';
import { useCollapsibleWizard } from '../../CollapsibleWizard/ContextAndProvider';
import { FlightAndAccommodationSummaryHeader } from '../../FlightAndAccommodationSummary';
import { getOrderedHotels } from './Hotel';
import { getPersonPriceFromCheapestSortedRates, getPersonPriceFromRate } from './Catering';
import { addTravelDetailsEvent, PriceFactory } from '@ibe/services';
import { getSelectableRoomNumbersCombinations } from './RoomSelection/useRoomSelection';
import dayjs from 'dayjs';
import { StepNames } from '../../../../../Tracking/TrackingService';
import { computeDuration } from '../../../../Util/utils';

const DurationComponent = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const {
    successNotes,
    getLabel,
    t,
    onRadioChange,
    onNext,
    sortedComponentsWithHotelInfo,
    selectedItems,
    store,
    bookingService
  } = useComponentSelectionState();

  const hotels = sortedComponentsWithHotelInfo[0].component.selectableItems as ApiHotel[];
  const showExtensionNote = hotels.some(hotel =>
    hotel.rooms.some(room => {
      return (
        !!room.relatedServices &&
        room.relatedServices.some(rS => rS.relationType == ApiRelatedServiceRelationType.EXCLUDE)
      );
    })
  );

  return (
    <>
      {bookingService?.booking && (
        <FlightAndAccommodationSummaryHeader selectedItems={selectedItems} store={store} />
      )}
      <CommonWrapper
        idx={idx}
        title={t(Keys.tripDuration)}
        successNotes={successNotes}
        onNext={onNext}
        onClose={remount}
      >
        <div className="mt-0 mt-lg-3">
          {sortedComponentsWithHotelInfo.map(sortedComponentWithHotelInfo => {
            const {
              componentSelectedHotel,
              isSelected,
              isMain,
              price
            } = sortedComponentWithHotelInfo;
            const isAvailable = componentSelectedHotel.availability !== 0;
            return (
              <Row className="pt-2 pb-2" key={componentSelectedHotel?.id}>
                <Col>
                  <CustomInput
                    id={`extended__booking__item-${componentSelectedHotel?.id}`}
                    name={`extended__booking__item-${componentSelectedHotel?.id}`}
                    className={`extended__booking__custom__input ${
                      !isAvailable ? 'extended__booking__custom__input--disabled' : ''
                    }`}
                    type="radio"
                    disabled={!isAvailable}
                    label={
                      <div
                        className={`extended__booking__item ${
                          !!isSelected ? 'extended__booking__item--selected' : ''
                        }`}
                      >
                        <span className="custom-checkbox"></span>
                        <div className="extended__booking__whitespace">
                          {getLabel(sortedComponentWithHotelInfo)}
                          {!isMain && isAvailable && isSelected && showExtensionNote && (
                            <div className="extended__booking__warning">
                              {t(Keys.extBookingExclusionWarning)}
                            </div>
                          )}
                        </div>
                        {!isAvailable && !isMain && (
                          <span className="extended__booking__whitespace text-right">
                            {t(Keys.extBookingUnavailable)}
                          </span>
                        )}
                      </div>
                    }
                    checked={isSelected}
                    onChange={() => onRadioChange(sortedComponentWithHotelInfo)}
                  />
                </Col>
                {!isMain && isAvailable && (
                  <Col className="text-right">
                    <Price
                      className="extended__booking__item__price"
                      prefix={'+ '}
                      price={ApiPriceFromJSON(price)}
                    />
                    <span className="extended__booking__duration__per-person">
                      {t(Keys.extBookingPerPerson)}
                    </span>
                  </Col>
                )}
              </Row>
            );
          })}
        </div>
      </CommonWrapper>
    </>
  );
};

export const Duration = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={DurationComponent} props={{ idx }} />
);

export interface FlattenedComponentToHotel {
  duration: number;
  componentSelectedHotel: ExtendedApiHotel;
  isMain?: boolean;
  isSelected?: boolean;
  price: ApiPrice;
  component: ApiComponent;
}

export const useComponentSelectionState = () => {
  const { selectedItems, setSelectedItems, store } = useExtendedBooking();
  const { duration } = selectedItems || {};
  const bookingService = useBookingService();
  const { t } = useTranslation('Checkout', fallback);
  const { showRange, hideRange } = useCollapsibleWizard();
  const numberOfTravelers = bookingService.booking?.travelers.length;
  const packageItem = bookingService?.bookedItems?.find(
    (it: ApiBookedItem) => it.itemType === ApiItemType.PACKAGE
  ) as ApiBookedItem;
  const packageDetails = bookingService?.packageCart?.packageModel.packageDetails || [];
  const hotelComponents = packageDetails
    .flatMap(detail => detail.components)
    .filter(comp => comp.itemType === ApiItemType.HOTEL);

  const mainComponent =
    duration?.mainComponent ||
    (hotelComponents.find(comp => comp.componentType === ApiComponentType.MAIN) as ApiComponent);

  const optionalComponent = duration?.selectedExtensionComponent;
  const [selectedComponentID, setSelectedComponentID] = useState<string | undefined>(
    optionalComponent?.id || mainComponent?.id
  );

  const selectedExtensionComponent = useMemo(() => {
    return hotelComponents.find(
      comp => comp.componentType !== ApiComponentType.MAIN && comp?.id === selectedComponentID
    );
  }, [selectedComponentID, duration]);

  const sortedComponentsWithHotelInfo: FlattenedComponentToHotel[] = useMemo(() => {
    return getSortedComponentsWithHotelInfo(
      hotelComponents,
      selectedComponentID,
      numberOfTravelers
    ).filter(element => {
      if (element.componentSelectedHotel != null && element.componentSelectedHotel.availability) {
        return element.componentSelectedHotel.availability > 0;
      }
      return false;
    });
  }, [hotelComponents, selectedComponentID, numberOfTravelers]);

  const onNext = () => {
    const findSelected = sortedComponentsWithHotelInfo.find(sortedHotel => sortedHotel.isSelected);
    const isEqual =
      duration?.mainComponent?.id === mainComponent?.id &&
      duration?.selectedExtensionComponent?.id === selectedExtensionComponent?.id;

    if (!isEqual) {
      if (findSelected) {
        setSelectedItems({
          duration: {
            mainComponent,
            selectedExtensionComponent,
            extensionDuration: !findSelected?.isMain ? findSelected?.duration : 0
          }
        });
      }
      // TIMEOUTS ARE NECESSARY TO AVOID REACT BATCHING.
      if (findSelected?.isMain) {
        setTimeout(() => hideRange(5, 8), 250);
      } else {
        setTimeout(() => showRange(5, 8), 250);
      }
    }

    let totalDuration = findSelected?.duration ? findSelected.duration : 0;
    if (selectedExtensionComponent?.startDate && selectedExtensionComponent?.endDate) {
      const extensionStartDate = dayjs(selectedExtensionComponent?.startDate);
      const extensionEndDate = dayjs(selectedExtensionComponent.endDate);
      const extensionDuration = extensionEndDate.diff(extensionStartDate, 'day');
      totalDuration += extensionDuration;
    }
    addTravelDetailsEvent.broadcast({
      amountTravellers: null,
      hotel: null,
      amountRooms: null,
      mealDescription: null,
      duration: totalDuration,
      booking: bookingService.booking,
      step: StepNames.DURATION_SELECTION
    });
  };

  const onRadioChange = (sortedHotel: typeof sortedComponentsWithHotelInfo[0]) => {
    setSelectedComponentID(sortedHotel.component?.id);
  };

  const getLabel = (sortedHotel: typeof sortedComponentsWithHotelInfo[0]) => {
    if (sortedHotel.isMain) return t(Keys.standardTripDuration);
    return `+ ${sortedHotel.duration - 1} ${t(Keys.night, {
      count: sortedHotel.duration - 1
    })} ${t(Keys.extension)}`;
  };

  const getSuccessNotes = useCallback(() => {
    const findSelected = sortedComponentsWithHotelInfo.find(sortedHotel => sortedHotel.isSelected);
    const mainComponentHotel = sortedComponentsWithHotelInfo.find(
      sortedHotel => sortedHotel.isMain
    );
    const { startDate: travelStartDate, endDate: travelEndDate } = packageItem || {};
    const mainDurationBooking =
      dayjs.duration(dayjs(travelEndDate).diff(dayjs(travelStartDate))).days() + 1;

    if (findSelected && mainComponentHotel) {
      if (findSelected.isMain) {
        const noteMain = `
          <span class='font-weight-bold'>
          ${mainDurationBooking} ${t(Keys.day, {
          count: mainDurationBooking
        })}
          </span>
          <span style="font-size:80%">
          (${mainDurationBooking} ${t(Keys.day, {
          count: mainDurationBooking
        })} ${t(Keys.standardTripDuration)}) 
          </span>`;

        return [noteMain];
      }

      const note = `
        <span class='font-weight-bold'>${mainDurationBooking + findSelected.duration - 1} ${t(
        Keys.day,
        {
          count: mainDurationBooking + findSelected.duration - 1
        }
      )}</span>
        <span style="font-size:80%" >
        (${mainComponentHotel?.duration} ${t(Keys.day, {
        count: mainComponentHotel?.duration
      })} ${t(Keys.standardTripDuration)} + ${findSelected.duration - 1} ${t(Keys.numberOfNight, {
        count: findSelected.duration - 1
      })} ${t(Keys.extension)})
        </span>`;
      return [note];
    }
    return [];
  }, [t, mainComponent, selectedExtensionComponent]);

  const successNotes = getSuccessNotes();

  return {
    sortedComponentsWithHotelInfo,
    setSelectedComponentID,
    selectedExtensionComponent,
    mainComponent,
    onRadioChange,
    onNext,
    successNotes,
    getLabel,
    t,
    selectedItems,
    store,
    bookingService
  };
};

interface ExtendedApiHotel extends ApiHotel {
  isOptional: boolean;
  component: ApiComponent;
}

export const getSortedComponentsWithHotelInfo = (
  hotelComponents: ApiComponent[],
  selectedComponentID?: string,
  numberOfTravelers?: number
) => {
  const allSelectedHotels = hotelComponents
    .flatMap(hotelComponent => {
      if (!numberOfTravelers) return hotelComponent;
      let isFeasible = false;
      hotelComponent.selectableItems.map(selectableItem => {
        if (!isFeasible) {
          const hotel = selectableItem as ApiHotel;
          const combinationsBasedOnAvailability = getSelectableRoomNumbersCombinations(
            numberOfTravelers,
            hotelComponent,
            hotel
          );
          if (combinationsBasedOnAvailability.length) isFeasible = true;
        }
      });
      if (!isFeasible) {
        return {
          ...hotelComponent,
          selectableItems: (hotelComponent.selectableItems as ApiHotel[]).flatMap(hotelItem => {
            return { ...hotelItem, availability: 0 } as ApiHotel;
          })
        } as ApiComponent;
      }
      return hotelComponent;
    })
    .map(hotelComponent => {
      if (hotelComponent.componentType === ApiComponentType.MAIN) {
        return {
          ...hotelComponent.selectedItems[0],
          isOptional: false,
          component: hotelComponent
        } as ExtendedApiHotel;
      }
      const orderedHotels = getOrderedHotels(hotelComponent.selectableItems as ApiHotel[]);
      const selected = orderedHotels.find(item => {
        const hotel = item as ApiHotel;
        return hotel.availability && hotel.availability > 0;
      }) as ApiHotel;
      if (selected) {
        return { ...selected, isOptional: true, component: hotelComponent } as ExtendedApiHotel;
      } else {
        return {
          ...orderedHotels[0],
          isOptional: true,
          component: hotelComponent
        } as ExtendedApiHotel;
      }
    })
    .filter(hotel => hotel !== undefined)
    .map(hotel => {
      const orderedRooms = hotel.rooms.sort((room1, room2) => {
        return (
          getPersonPriceFromCheapestSortedRates(room1.roomrates) -
          getPersonPriceFromCheapestSortedRates(room2.roomrates)
        );
      });
      const roomPrice = getPersonPriceFromRate(orderedRooms?.[0].roomrates?.[0]);
      return {
        duration: computeDuration(hotel.durationInfo),
        componentSelectedHotel: hotel,
        component: hotel.component,
        isSelected: selectedComponentID === hotel.component?.id,
        isMain: hotel.component.componentType === ApiComponentType.MAIN,
        price: PriceFactory.create(roomPrice, hotel.price.currencyCode)
      } as FlattenedComponentToHotel;
    })
    .sort((a, b) => {
      if (a.component.componentType === ApiComponentType.MAIN) return -1;
      if (b.component.componentType === ApiComponentType.MAIN) return 1;
      return a.duration - b.duration;
    });

  return allSelectedHotels;
};
