import React, { useContext, useEffect, useRef, useState } from 'react';
import { ExternalFormatPrice, useApi, useBookingService, useTranslation } from '@ibe/components';
import { observer, useLocalStore } from 'mobx-react';
import { LoggerFactory } from '@ibe/services';
import CheckoutStore, { HotelInformationType, ProductIBEMode } from './CheckoutStore';
import fallback from '../../Translations/generated/Checkout.de.json';
import CheckoutWorkflow from '../../Layouts/CheckoutWorkflow';
import PageUrl from '../../Models/PageUrl';
import { productIBEWhitelabelWorkflowCheckout, productIBEWorkflowCheckout } from '../../steps';
import PageLayout from '../../Layouts/PageLayout';
import { WidgetType } from '../../pages';
import { useHistory } from 'react-router-dom';
import { formatPrice, packageIdSessionStorage } from '../Util/utils';
import { ApiBookedItem, ApiImageContainer, ApiItemType } from '@ibe/api';
import { stepSessionStorage } from '../../Util/globals';
import { workflowContext } from '../../contexts';
import { PackageCode } from '../Products/PackagesList';
import { ThgConfigModel } from '../../Config/ThgConfigModel';

const logger = LoggerFactory.get('Checkout');

const Checkout = observer(
  ({
    rerouteUrl,
    homepageUrl,
    widgetType,
    navBarOffset,
    isWhitelabel,
    isAgencyMode,
    agencyNumber,
    packageCodes,
    thgConfig,
    promotionCode
  }: {
    rerouteUrl?: string;
    homepageUrl?: string;
    widgetType?: WidgetType;
    navBarOffset?: number;
    isWhitelabel?: boolean;
    isAgencyMode?: boolean;
    agencyNumber?: string;
    packageCodes?: PackageCode[];
    thgConfig?: ThgConfigModel;
    promotionCode?: string;
  }) => {
    const { t } = useTranslation('Checkout', fallback);
    const api = useApi();
    const bs = useBookingService();
    const bookedItems = bs?.bookedItems;
    const history = useHistory();
    const [productIBEMode, setProductIBEMode] = useState<ProductIBEMode | undefined>(undefined);
    const promotionCheckDone = useRef<boolean>(false);
    const workflow = useContext(workflowContext);

    const store = useLocalStore(() => new CheckoutStore(api, t));

    useEffect(() => {
      const packageData = packageIdSessionStorage.get();
      let decodedPackageId = undefined;
      let newProductIBEMode = undefined;
      if (!!packageData) {
        try {
          decodedPackageId = JSON.parse(atob(packageData.packageId));
          newProductIBEMode = {
            bookingHasComponents: true,
            promotionCode: decodedPackageId?.promotionCode,
            serviceCode: packageData?.packageCode
          };
          if (!!bookedItems) {
            const packageItem = bookedItems.find(
              (item: ApiBookedItem) => item.itemType === ApiItemType.PACKAGE
            );
            if (!!packageItem?.metaInfo?.contentByMetaType?.season?.content) {
              const seasonContent = packageItem.metaInfo.contentByMetaType.season.content;
              const image = seasonContent?.image as ApiImageContainer | undefined;
              const icon = seasonContent?.icon as ApiImageContainer | undefined;
              store.setHotelInformation({
                name: packageItem.description,
                country: (seasonContent?.seasonCountry as { countryName: string } | undefined)
                  ?.countryName as string | undefined,
                additionalServices: (seasonContent?.additionalServices as unknown) as HotelInformationType['additionalServices'],
                image: image?.small || image?.extraSmall || image?.large || image?.extraLarge,
                icon: icon?.small || icon?.extraSmall || icon?.large || image?.extraLarge
              });
            }
          }
          setProductIBEMode({ ...newProductIBEMode });
          store.setProductIBEMode({ ...newProductIBEMode });
        } catch (err) {
          logger.error(err);
        }
      } else if (isWhitelabel) {
        const config = {
          bookingHasComponents: true,
          serviceCode: packageCodes && packageCodes.length > 0 ? packageCodes[0].code : '',
          promotionCode
        };

        setProductIBEMode(config);
        store.setProductIBEMode(config);
      }

      if (widgetType === 'CHECKOUT') {
        const persistedStep = stepSessionStorage.get();
        const currentStep = workflow.steps.find(s => s.slug === persistedStep);
        if (!!currentStep) {
          history.push(PageUrl.CHECKOUT + '/' + currentStep.slug);
        } else {
          history.push(PageUrl.CHECKOUT);
        }
      }
    }, []);

    useEffect(() => {
      (async () => {
        if (!!productIBEMode?.promotionCode && !!bookedItems && !promotionCheckDone.current) {
          try {
            promotionCheckDone.current = true;
            await bs.addPromoCodesToBooking([productIBEMode.promotionCode]);
          } catch (err) {
            logger.error(err);
          }
        }
      })();
    }, [productIBEMode, bookedItems]);

    return (
      <ExternalFormatPrice externalFormatPrice={formatPrice}>
        <PageLayout>
          <CheckoutWorkflow
            baseUrl={PageUrl.CHECKOUT}
            steps={isWhitelabel ? productIBEWhitelabelWorkflowCheckout : productIBEWorkflowCheckout}
            store={store}
            rerouteUrl={rerouteUrl}
            homepageUrl={homepageUrl}
            navBarOffset={navBarOffset}
            productIBEMode={productIBEMode}
            isAgencyMode={isAgencyMode}
            isWhitelabel={isWhitelabel}
            agencyNumber={agencyNumber}
            packageCodes={packageCodes}
            thgConfig={thgConfig}
            promotionCode={promotionCode}
          />
        </PageLayout>
      </ExternalFormatPrice>
    );
  }
);
export default Checkout;
