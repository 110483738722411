import React, { JSX, useCallback, useMemo, useState } from 'react';
import {
  Info,
  initBookingService,
  LoadingOverlay,
  Overlay,
  OverlayContainer,
  PackageSimpleListWrapper,
  SessionData,
  useAppService,
  useBookingService,
  useConfig,
  useExternalSearchParams,
  useTranslation,
  Warning
} from '@ibe/components';
import fallback from '../../Translations/generated/product-ibe.de.json';
import Keys from '../../Translations/generated/product-ibe.de.json.keys';
import Filters, { FilterParams, InitialStorageFilters } from './Filters';
import dayjs from 'dayjs';
import { isNumber } from 'lodash-es';
import localeData from 'dayjs/plugin/localeData';
import usePackagesListFunctions, { productIBEAnchor } from './usePackagesListFunctions';
import { PromotionCodeData } from '../../Components/PromotionCode';
import {
  packageIdSessionStorage,
  productDetailsPageUrlStorage,
  validPromotionCodeStorage
} from '../Util/utils';
import { Col, Row } from 'reactstrap';
import { useMount } from 'react-use';
import { packageItemSelectEvent } from '../../Tracking/events';
import { stepSessionStorage } from '../../Util/globals';
import { extendedBookingStorage } from '../Checkout/Components/ExtendedBooking/ContextAndProvider';
import { summaryOverviewSession } from '../Checkout/Components/StickySummary/Content';
import { ApiUpdateAffiliateRequestFromJSON } from '@ibe/api';
import CustomAnimationSpinner from '../../Components/CustomAnimationSpinner';
import PBusFilters from './PBusFilters';
import {
  AnalyticsService,
  bookingInitEvent,
  MaskBookingService,
  SessionStoragePersistence
} from '@ibe/services';
import { BOOKINGID_STORAGE_KEY } from '../../Util/constant';
import { PackageTypes } from './ProductIBE';
import CustomInfo from '../../Components/CustomInfo';

dayjs.extend(localeData);

export type PackageCode = { code: string; smallGroup: boolean };

export interface ProductIBEProps {
  packageCodes: Array<PackageCode>;
  earliestPossibleDate: string;
  latestPossibleDate: string;
  numberOfTravelers?: number;
  promotionCode?: string;
  checkoutUrl?: string;
  maintenanceMode?: boolean;
  agencyNumber?: string;
  isWhitelabel?: boolean;
}

const PackagesList = (
  props: Omit<ProductIBEProps, 'promotionCode'> & {
    promotionCode: PromotionCodeData;
    handlePackageSelection?: () => void;
    packageType?: string;
  }
): JSX.Element => {
  const { t } = useTranslation('product-ibe', fallback);
  const {
    earliestPossibleDate,
    latestPossibleDate,
    packageCodes,
    numberOfTravelers,
    promotionCode,
    checkoutUrl,
    maintenanceMode,
    handlePackageSelection,
    agencyNumber,
    isWhitelabel,
    packageType
  } = props;
  const config = useConfig();
  const appService = useAppService();
  const bs = useBookingService();

  const [currentNumberOfTravelers, setCurrentNumberOfTravelers] = useState<number>(
    numberOfTravelers || 2
  );

  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const context = useExternalSearchParams();

  const [initialStorageFilters, setInitialStorageFilters] = useState<InitialStorageFilters>({
    initialFilters: undefined,
    initialFiltersChecked: false
  });

  const [requiredCriteriaProvided, setRequiredCriteriaProvided] = useState<boolean>(false);

  useMount(() => {
    const numberOfTravelersInStorage = packageIdSessionStorage.get()?.numberOfTravelers;
    if (isNumber(numberOfTravelersInStorage)) {
      setCurrentNumberOfTravelers(numberOfTravelersInStorage);
      setInitialStorageFilters({
        initialFilters: {
          numberOfTravelers: numberOfTravelersInStorage,
          startDate: earliestPossibleDate,
          endDate: latestPossibleDate,
          origins: [],
          busStations: [],
          smallGroup: false,
          selectedPostalData: undefined
        },
        initialFiltersChecked: true
      });
    } else {
      setInitialStorageFilters({ initialFilters: undefined, initialFiltersChecked: true });
    }
  });

  const {
    packagesMappingCallback,
    getPackages,
    packages,
    possibleOrigins,
    isLoading
  } = usePackagesListFunctions(
    earliestPossibleDate,
    latestPossibleDate,
    packageCodes,
    t,
    promotionCode,
    initialStorageFilters,
    currentNumberOfTravelers,
    maintenanceMode,
    packageType
  );

  const headItems = useMemo(() => {
    return [
      { value: t(Keys.date), sortProp: 'startDate' },
      ...(packages.some(singlePackage => singlePackage.smallGroup)
        ? [{ value: t(Keys.smallGroup), sortProp: 'smallGroup' }]
        : []),
      packageType === PackageTypes.PBUS
        ? { value: t(Keys.busStop_plural) }
        : { value: t(Keys.origin), sortProp: 'origin' },
      { value: t(Keys.availability), sortProp: 'availability' },
      { value: t(Keys.pricePerPerson), sortProp: 'price' }
    ];
  }, [packages]);

  const handleFilterChange = useCallback(
    async (filters: FilterParams, ignoreNumberOfTravelers?: boolean): Promise<void> => {
      if (filters.busStations.length > 0 && filters.numberOfTravelers > 0) {
        setRequiredCriteriaProvided(true);
      } else {
        setRequiredCriteriaProvided(false);
      }
      if (!ignoreNumberOfTravelers && filters.numberOfTravelers !== currentNumberOfTravelers) {
        setCurrentNumberOfTravelers(filters.numberOfTravelers);
      }
      await getPackages(filters);
    },
    [getPackages, currentNumberOfTravelers, promotionCode]
  );

  const handlePackageSelect = useCallback(
    async (id: string, code: string, index?: number) => {
      const foundPackage = packages?.find(singlePackage => singlePackage.id === id);
      if (!!foundPackage) {
        packageItemSelectEvent.broadcast({ item: foundPackage, index: index });
      }
      packageIdSessionStorage.set({
        packageId: id,
        packageCode: code,
        numberOfTravelers: currentNumberOfTravelers,
        stationCodes: foundPackage?.stationCodes
      });

      if (!!checkoutUrl) {
        validPromotionCodeStorage.clear();
        productDetailsPageUrlStorage.set(
          `${location.href}${!location.hash ? `#${productIBEAnchor}` : ''}`
        );
        location.href = checkoutUrl;
      }

      if (!!handlePackageSelection) {
        handlePackageSelection();
      }
    },
    [currentNumberOfTravelers, checkoutUrl, packages]
  );

  const checkAvailability = async (id: string): Promise<boolean> => {
    setActionLoading(true);
    let hasError = false;
    try {
      const session = new SessionStoragePersistence<SessionData>(SessionData);
      session.useKey(context?.widgetSessionKeyPrefix + config.sessionKeyCart);

      const packageCartSession = new SessionStoragePersistence<string>();
      packageCartSession.useKey(context?.widgetSessionKeyPrefix + config.sessionKeyPackageCart);

      const bidSessionStorage = new SessionStoragePersistence<string>();
      bidSessionStorage.useKey(context?.widgetSessionKeyPrefix + BOOKINGID_STORAGE_KEY);
      bidSessionStorage.clear();

      if (session.get()?.bookingId && packageCartSession.get()) {
        bs.setApi(appService.api);
        await bs.initPackageCart(id, session.get().bookingId, packageCartSession.get());
        await bs.init(session.get().bookingId);

        bookingInitEvent.broadcast({
          booking: MaskBookingService.maskBooking(bs.booking),
          item: null,
          analytics: AnalyticsService.createAnalyticsObject(bs.booking)
        });
      } else {
        await initBookingService(
          config,
          appService.api,
          true,
          context?.widgetSessionKeyPrefix,
          id,
          true
        );
      }

      if (agencyNumber) {
        await bs.updateAffiliate(
          ApiUpdateAffiliateRequestFromJSON({ affiliateNumber: agencyNumber })
        );
      }
      stepSessionStorage.clear();
      extendedBookingStorage.clear();
      summaryOverviewSession.clear();
    } catch (err) {
      console.error(err);
      hasError = true;
    } finally {
      setActionLoading(false);
      return !hasError;
    }
  };

  return (
    <div
      className={
        packages.some(innerSinglePackage => innerSinglePackage.smallGroup)
          ? 'small-group-list__container'
          : ''
      }
    >
      <OverlayContainer>
        {packageType === PackageTypes.PBUS ? (
          <PBusFilters
            earliestPossibleDate={earliestPossibleDate}
            latestPossibleDate={latestPossibleDate}
            onChange={handleFilterChange}
            origins={possibleOrigins}
            packageCodes={packageCodes}
            maintenanceMode={maintenanceMode}
            numberOfTravelers={currentNumberOfTravelers}
            packages={packages}
            promotionCode={promotionCode}
          />
        ) : (
          <Filters
            earliestPossibleDate={earliestPossibleDate}
            latestPossibleDate={latestPossibleDate}
            onChange={handleFilterChange}
            origins={possibleOrigins}
            packageCodes={packageCodes}
            maintenanceMode={maintenanceMode}
            numberOfTravelers={currentNumberOfTravelers}
            packages={packages}
            promotionCode={promotionCode}
          />
        )}
        <LoadingOverlay className="packages-list__loading" isLoading={isLoading}>
          {packages.length > 0 && !maintenanceMode ? (
            <PackageSimpleListWrapper
              headItems={headItems}
              packages={packages}
              onSelect={handlePackageSelect}
              packagesMappingCallback={packagesMappingCallback}
              numberOfDisplayedItems={10}
              numberOfInitiallyDisplayedItems={12}
              checkAvailability={checkAvailability}
              errorRowColSpanDiff={1}
            />
          ) : (
            <></>
          )}

          {packages.length === 0 && !maintenanceMode && !isLoading ? (
            <PackageSimpleListWrapper
              headItems={headItems}
              packages={[]}
              onSelect={handlePackageSelect}
              staticNonTableContent={
                <Row className="mt-4">
                  <Col>
                    {(packageType === PackageTypes.PFLUG || requiredCriteriaProvided) && (
                      <Info message={t(Keys.noPackagesFound)} />
                    )}
                    {packageType === PackageTypes.PBUS && !requiredCriteriaProvided && (
                      <CustomInfo
                        message={t(Keys.provideSearchCriteria)}
                        messageClass={'bus-package-criteria-info'}
                      />
                    )}
                  </Col>
                </Row>
              }
            />
          ) : (
            <></>
          )}
          {maintenanceMode ? (
            <PackageSimpleListWrapper
              headItems={headItems}
              packages={[]}
              onSelect={handlePackageSelect}
              staticNonTableContent={
                <Row className="mt-4">
                  <Col>
                    <Warning message={t(Keys.maintenanceMode)} />
                  </Col>
                </Row>
              }
            />
          ) : (
            <></>
          )}
        </LoadingOverlay>
        {actionLoading && !isWhitelabel && (
          <div className="animated_spinner_container">
            <Overlay customSpinner={<CustomAnimationSpinner packageType={packageType} />} />
          </div>
        )}
        {actionLoading && isWhitelabel && <Overlay />}
      </OverlayContainer>
    </div>
  );
};

export default PackagesList;
