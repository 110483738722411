/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-01 10:05:52 */

const Keys = {
  from: 'from',
  nights: 'nights',
  roomOnly: 'roomOnly',
  with: 'with',
  choose: 'choose',
  additionalDatesAvailable: 'additionalDatesAvailable'
}; export default Keys;