import React, { Suspense, useState } from 'react';
import { MemoryRouter } from 'react-router';
import { ThemeProvider } from '@emotion/react';
import { useMount, useSearchParam } from 'react-use';
import { observer } from 'mobx-react';
import 'dayjs/locale/de';
import {
  ExternalSearchParams,
  ExternalSearchParamsContextProps,
  MEDIAQUERY_DEFAULTS,
  Overlay,
  SessionData,
  useAppService,
  useBookingService,
  useConfig,
  useMediaQuery
} from '@ibe/components';
import theme from '../Theme/theme';
import { boot, extendedConfigContext } from '../Config/config';
import initI18next from '../Translations/setup';
import Checkout from '../Pages/Checkout/Checkout';
import { ThgConfigModel } from '../Config/ThgConfigModel';
import { ConfigModel, SessionStoragePersistence } from '@ibe/services';
import RouteBroadcast from '../RouteBroadcast';
import '../Theme/Styles/widgets.scss';
import { BookingErrorProvider } from '../Util/useBookingError';
import {
  packageIdSessionStorage,
  productDetailsPageUrlStorage,
  validPromotionCodeStorage,
  whitelabelSeasonStorage
} from '../Pages/Util/utils';
import loadExternalWidgetConfig from '../Util/loadExternalWidgetConfig';
import { PackageCode } from '../Pages/Products/PackagesList';
import { AffiliateProvider } from '../Util/AffiliateProvider';
import { ApiAffiliateRequestFromJSON, ApiAffiliateResponse } from '@ibe/api';
import ApplyBranding from '../Util/ApplyBranding';
import { stepSessionStorage } from '../Util/globals';
import { ApiAdditionalCode } from '../../api';

const WhitelabelWidget = observer(
  (
    props: ExternalSearchParamsContextProps & {
      agencyNumber: string;
      agencyModeAgencyNumber?: string;
      apiKey?: string;
      rerouteUrl?: string;
      packageCode: string;
      promotionCode?: string;
      thgConfig?: Partial<ThgConfigModel>;
      earliestPossibleDate?: string;
      latestPossibleDate?: string;
    }
  ) => {
    const { mode, widgetSessionKeyPrefix: keyPrefix, thgConfig = {} } = props;
    let {
      packageCode,
      agencyNumber,
      agencyModeAgencyNumber,
      apiKey,
      rerouteUrl,
      promotionCode,
      earliestPossibleDate,
      latestPossibleDate
    } = props;

    // override html data-props via url search params
    const agencyNumberParam = useSearchParam('agencyNumber');
    agencyNumber = agencyNumberParam ? encodeURIComponent(agencyNumberParam) : agencyNumber;
    const agencyModeAgencyNumberParam = useSearchParam('agencyModeAgencyNumberParam');
    agencyModeAgencyNumber = agencyModeAgencyNumberParam
      ? encodeURIComponent(agencyModeAgencyNumberParam)
      : agencyModeAgencyNumber;

    const [cssVars, setCssVars] = useState<{ [key: string]: string } | undefined>(undefined);
    const isAgencyMode = !!agencyModeAgencyNumber;
    const apiKeyParam = useSearchParam('apiKey');
    apiKey = apiKeyParam ? encodeURIComponent(apiKeyParam) : apiKey;

    rerouteUrl = useSearchParam('rerouteUrl') || rerouteUrl;

    const packageCodeParam = useSearchParam('packageCode');
    packageCode = packageCodeParam ? encodeURIComponent(packageCodeParam) : packageCode;

    const earliestPossibleDateParam = useSearchParam('earliestPossibleDate');
    earliestPossibleDate = earliestPossibleDateParam
      ? encodeURIComponent(earliestPossibleDateParam)
      : earliestPossibleDate;

    const latestPossibleDateParam = useSearchParam('latestPossibleDate');
    latestPossibleDate = latestPossibleDateParam
      ? encodeURIComponent(latestPossibleDateParam)
      : latestPossibleDate;

    const clearData = whitelabelSeasonStorage.get()?.code !== packageCode;
    const promotionCodeParam = useSearchParam('promotionCode');
    promotionCode = promotionCodeParam ? encodeURIComponent(promotionCodeParam) : promotionCode;
    // session storage value overrides initial configured code
    const validPromotionCode = validPromotionCodeStorage.get();
    if (validPromotionCode) {
      promotionCode = validPromotionCode.code;
    }
    // end override html data-props via url search params

    const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);
    const [hasBookingError, setHasBookingError] = useState<boolean>(false);
    const matches = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xl });

    const widgetSessionKeyPrefix = keyPrefix || document.body.id || '';
    const [thgConfigState, setThgConfigState] = useState<Partial<ThgConfigModel>>(thgConfig);
    const [agencyDataValid, setAgencyDataValid] = useState<boolean>(true);
    const appService = useAppService();
    const config = useConfig();
    const bs = useBookingService();
    const [affiliateResponse, setAffiliateResponse] = useState<ApiAffiliateResponse>({
      cssVars: {},
      useCustomCMP: undefined,
      metaContent: {}
    });

    const [packageCodes, setPackageCodes] = useState<PackageCode[]>([]);

    useMount(async () => {
      let baseUrlWithContext: string | undefined;
      const widgetConfig = await loadExternalWidgetConfig();
      const configOverrides: Partial<ConfigModel> = {};

      if (widgetConfig) {
        baseUrlWithContext = widgetConfig.baseUrl;
        thgConfigState.baseUrl = baseUrlWithContext;
        thgConfigState.productApiUrl = widgetConfig.productApiUrl;
        thgConfigState.configUrl = widgetConfig.configUrl;
        thgConfigState.version = widgetConfig.version;
        thgConfigState.apiUrl = widgetConfig.apiUrl;
        thgConfigState.globistaDataSecurityLink = widgetConfig.globistaDataSecurityLink;

        configOverrides.configUrl = widgetConfig.configUrl;
      }

      if (clearData) {
        stepSessionStorage.clear();
        packageIdSessionStorage.clear();
        validPromotionCodeStorage.clear();
        const session = new SessionStoragePersistence<SessionData>(SessionData);
        session.useKey(widgetSessionKeyPrefix + config.sessionKeyCart);
        session.clear();

        const packageCartSession = new SessionStoragePersistence<string>();
        packageCartSession.useKey(widgetSessionKeyPrefix + config.sessionKeyPackageCart);
        packageCartSession.clear();
      }

      const url =
        !earliestPossibleDate && !latestPossibleDate
          ? thgConfigState.apiUrl + '/season/' + packageCode
          : thgConfigState.apiUrl +
            '/season/' +
            packageCode +
            '/' +
            earliestPossibleDate +
            '/' +
            latestPossibleDate;
      const res = await fetch(url);
      if (res.ok) {
        const seas = await res.json();
        whitelabelSeasonStorage.clear();
        whitelabelSeasonStorage.set(seas);

        const packCodes: PackageCode[] = [
          {
            code: seas.code,
            smallGroup: !!seas.smallGroup ? seas.smallGroup : false
          }
        ];

        if (!!seas.additionalCodes) {
          seas.additionalCodes.map((additional: ApiAdditionalCode) => {
            !!additional.code && additional.code.length > 0
              ? packCodes.push({
                  code: additional.code,
                  smallGroup: !!additional.isSmallGroup ? additional.isSmallGroup : false
                })
              : '';
          });
        }

        setPackageCodes(packCodes);
      }

      await initI18next(mode, thgConfigState.baseUrl, thgConfigState.version);
      const currentStep = stepSessionStorage.get();
      const isConfirmationPage = currentStep === 'confirmation';

      const bookingError = await boot(
        mode,
        true,
        widgetSessionKeyPrefix,
        configOverrides,
        isConfirmationPage,
        false,
        true,
        thgConfigState
      );
      if (bookingError) {
        setHasBookingError(true);
      }
      if (clearData) {
        bs.clearBooking();
      }
      setBootstrappingFinished(true);
      setThgConfigState(thgConfigState);

      productDetailsPageUrlStorage.set(rerouteUrl ? rerouteUrl : '');

      try {
        const affResponse = await appService.api.getAffiliateData(
          ApiAffiliateRequestFromJSON({
            number: !!agencyModeAgencyNumber ? agencyModeAgencyNumber : agencyNumber,
            apiKey: apiKey
          })
        );

        setAffiliateResponse(affResponse);
        setCssVars(affResponse.cssVars);
      } catch (e) {
        setAgencyDataValid(false);
      }
    });

    return bootstrappingFinished && packageCodes.length > 0 ? (
      agencyDataValid && packageCodes[0].code ? (
        <>
          {!!cssVars && <ApplyBranding colors={cssVars} />}
          <ThemeProvider theme={theme}>
            <div id="iso" className="iso__transparent whitelabel__container">
              <Suspense fallback={<Overlay fullOpacity />}>
                <extendedConfigContext.Provider value={thgConfigState}>
                  <MemoryRouter>
                    <BookingErrorProvider hasBookingError={hasBookingError}>
                      <AffiliateProvider res={affiliateResponse}>
                        <ExternalSearchParams
                          {...props}
                          widgetSessionKeyPrefix={widgetSessionKeyPrefix}
                          goToDetails
                        >
                          <Checkout
                            widgetType="CHECKOUT"
                            navBarOffset={matches ? 133 : 91}
                            isWhitelabel
                            isAgencyMode={isAgencyMode}
                            agencyNumber={agencyNumber}
                            rerouteUrl={rerouteUrl}
                            packageCodes={packageCodes}
                            promotionCode={promotionCode}
                            thgConfig={thgConfigState}
                          />
                          <RouteBroadcast />
                        </ExternalSearchParams>
                      </AffiliateProvider>
                    </BookingErrorProvider>
                  </MemoryRouter>
                </extendedConfigContext.Provider>
              </Suspense>
            </div>
          </ThemeProvider>
        </>
      ) : (
        <div>Wrong widget configuration!</div>
      )
    ) : (
      <div id="iso" style={{ height: '300px' }}>
        <Overlay />
      </div>
    );
  }
);

export default WhitelabelWidget;
