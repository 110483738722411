import React, { useCallback, useRef, useState } from 'react';
import SingleSelection from '../../Components/SingleSelection';
import fallback from '../../Translations/generated/product-ibe.de.json';
import Keys from '../../Translations/generated/product-ibe.de.json.keys';
import { isEqual } from 'lodash-es';
import {
  PackageDropdownFilter,
  PackageSearchParams,
  useConfig,
  useTranslation
} from '@ibe/components';
import { PackageCode, ProductIBEProps } from './PackagesList';
import { ApiBestPricePackageModel, ApiGeoUnit, ApiRentalCarOffice } from '@ibe/api';
import dayjs from 'dayjs';
import { PromotionCodeData } from '../../Components/PromotionCode';
import useThgConfig from '../../Hooks/useThgConfig';
import { FilterParams } from './Filters';
import { Col, Container, Row } from 'reactstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export type PBusFilterParams = {
  numberOfTravelers: number;
  origins: string[];
  startDate: string;
  endDate: string;
  busStations: string[];
  smallGroup: boolean;
  ownArrivalsAndDepartures: boolean;
};

const filterPropsMapper = (
  smallGroup: boolean,
  ownArrivalsAndDepartures: boolean,
  searchParams?: Partial<PackageSearchParams>
): PBusFilterParams => {
  return {
    numberOfTravelers: !!searchParams?.occupancy ? searchParams.occupancy[0].adults : 0,
    origins: searchParams?.origins?.map((origin: ApiGeoUnit) => origin.id) || [],
    startDate: searchParams?.startDate || '',
    endDate: searchParams?.endDate || '',
    busStations: ownArrivalsAndDepartures
      ? ['00000']
      : searchParams?.busStations?.map((office: ApiRentalCarOffice) => office.code) || [],
    smallGroup,
    ownArrivalsAndDepartures
  } as PBusFilterParams;
};

const PBusFilters = ({
  earliestPossibleDate,
  latestPossibleDate,
  onChange,
  origins,
  packageCodes,
  maintenanceMode,
  numberOfTravelers,
  packages = [],
  promotionCode
}: {
  earliestPossibleDate: string;
  latestPossibleDate: string;
  onChange: (filters: FilterParams, ignoreNumberOfTravelers?: boolean) => void;
  origins: string[];
  packageCodes: ProductIBEProps['packageCodes'];
  maintenanceMode?: boolean;
  numberOfTravelers: number;
  packages: ApiBestPricePackageModel[];
  promotionCode: PromotionCodeData;
}): JSX.Element => {
  const config = useConfig();
  const { t } = useTranslation('product-ibe', fallback);

  type Mode = 'selectsStart' | 'selectsEnd' | 'none';
  const dateRangeRef = useRef<{ focus?: (a: Mode) => void }>(null);

  const [smallGroupChecked, setSmallGroupChecked] = useState<boolean>(false);
  const [ownArrivalsAndDeparturesChecked, setOwnArrivalsAndDeparturesChecked] = useState<boolean>(
    false
  );
  const [packageSearchParams, setPackageSearchParams] = useState<PackageSearchParams | undefined>(
    undefined
  );

  const thgConfig = useThgConfig();
  const imgBaseUrl = thgConfig.baseUrl || config.translationsUrl;

  const handleFilterChange = useCallback(
    (searchParams: Partial<PackageSearchParams>): void => {
      if (!isEqual(searchParams, packageSearchParams)) {
        setPackageSearchParams(searchParams);
        onChange(
          filterPropsMapper(smallGroupChecked, ownArrivalsAndDeparturesChecked, searchParams)
        );
      }
    },
    [onChange, promotionCode]
  );

  const handleSmallGroupChecked = useCallback(
    (isChecked: boolean): void => {
      if (isChecked !== smallGroupChecked) {
        setSmallGroupChecked(isChecked);
        onChange(
          filterPropsMapper(isChecked, ownArrivalsAndDeparturesChecked, packageSearchParams),
          true
        );
      }
    },
    [onChange, promotionCode, smallGroupChecked]
  );

  const handleOwnArrivalsAndDeparturesCheck = useCallback(
    (isChecked: boolean): void => {
      if (isChecked !== ownArrivalsAndDeparturesChecked) {
        setOwnArrivalsAndDeparturesChecked(isChecked);
        onChange(filterPropsMapper(smallGroupChecked, isChecked, packageSearchParams), true);
      }
    },
    [onChange, promotionCode, ownArrivalsAndDeparturesChecked]
  );

  const onToggle = useCallback(
    ({ isOpen, endDate, startDate }: { isOpen?: boolean; endDate?: Date; startDate?: Date }) => {
      if (isOpen && !endDate && startDate && dateRangeRef && dateRangeRef.current?.focus) {
        dateRangeRef.current?.focus('selectsEnd');
      }
    },
    [dateRangeRef]
  );

  return (
    <div className={'bus-filter-container'}>
      <PackageDropdownFilter
        onChange={handleFilterChange}
        datepickerMaxDate={latestPossibleDate}
        datepickerMinDate={earliestPossibleDate}
        origins={origins}
        busStops={origins}
        ownArrivalsAndDepartures={ownArrivalsAndDeparturesChecked}
        disabled={maintenanceMode}
        defaultDate={{
          startDate: dayjs(earliestPossibleDate).isBefore(dayjs(new Date()))
            ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
            : earliestPossibleDate,
          endDate: latestPossibleDate
        }}
        onToggle={{
          calendar: onToggle
        }}
        dateRangeRef={dateRangeRef}
        numberOfTravelers={numberOfTravelers}
        memoDeps={[promotionCode]}
        showNumberSelector={false}
        isBusPackage={true}
      />
      <Container className={'single-selections-container'}>
        <Row>
          <Col xs="12" sm="12" md="12" lg="6">
            <SingleSelection
              selectionId={'arrAndDep'}
              isChecked={ownArrivalsAndDeparturesChecked}
              setIsChecked={handleOwnArrivalsAndDeparturesCheck}
              checkboxLabel={t(Keys.busStops)}
              icon={faInfoCircle}
              promotionCode={promotionCode}
              showTooltip={true}
              tooltipText={t(Keys.busStopsTooltip)}
            />
          </Col>
          {packageCodes.some(code => code.smallGroup) &&
          !packageCodes.every(code => code.smallGroup) &&
          (packageCodes.reduce(
            (total: string[], current: PackageCode) =>
              !total.includes(current.code) ? [...total, current.code] : [...total],
            []
          ).length > 1 ||
            !packageCodes.some(code => code.smallGroup)) &&
          packages.some(singlePackage => singlePackage.smallGroup) &&
          !maintenanceMode ? (
            <Col xs="12" sm="12" md="12" lg="6">
              <SingleSelection
                selectionId={'small-group-id'}
                isChecked={smallGroupChecked}
                setIsChecked={handleSmallGroupChecked}
                infoText={t(Keys.smallGroupInfo)}
                checkboxLabel={t(Keys.smallGroupLabel)}
                imgSrc={`${imgBaseUrl}/img/kleingruppe.svg`}
                promotionCode={promotionCode}
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PBusFilters;
