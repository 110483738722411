import React, { createRef, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { FilterParams, InitialStorageFilters } from './Filters';
import {
  ApiBaseData,
  ApiBestPricePackageModel,
  ApiGetFlightTripPackageRequestFromJSON,
  ApiItemType,
  ApiPromotionCodeFromJSON
} from '@ibe/api';
import {
  ListRow,
  ListRowItem,
  RentalCarOfficeWithDistanceWrapper,
  useApi,
  useAppService,
  useConfig
} from '@ibe/components';
import Keys from '../../Translations/generated/product-ibe.de.json.keys';
import { itemListEvent } from '@ibe/services';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { TFunction } from 'react-i18next';
import { isValidPromotionCode, PromotionCodeData } from '../../Components/PromotionCode';
import {
  BUS_STOPS_PERIMETER,
  formatPrice,
  getReducedBusStationList,
  SELF_ARRIVAL_CODE
} from '../Util/utils';
import useThgConfig from '../../Hooks/useThgConfig';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { PackageTypes } from './ProductIBE';

dayjs.extend(localeData);

export const productIBEAnchor = 'iso-product-ibe-list';

const AVAILABILITY_LIMIT = 10;

const getAvailability = (availability?: number): { value: string; class: string } => {
  if (!!availability && availability > AVAILABILITY_LIMIT) {
    return { value: `> ${AVAILABILITY_LIMIT}`, class: 'simple-list__cell__availability--level2' };
  } else if (!!availability && availability === AVAILABILITY_LIMIT) {
    return { value: `${availability}`, class: 'simple-list__cell__availability--level2' };
  } else if (!!availability && availability <= AVAILABILITY_LIMIT) {
    return { value: `${availability}`, class: 'simple-list__cell__availability--level1' };
  } else {
    return { value: `< ${AVAILABILITY_LIMIT}`, class: 'simple-list__cell__availability--level1' };
  }
};

const usePackagesListFunctions = (
  earliestPossibleDate: string,
  latestPossibleDate: string,
  packageCodes: Array<{ code: string; smallGroup: boolean }>,
  t: TFunction,
  promotionCode: PromotionCodeData,
  initialStorageFilters: InitialStorageFilters,
  numberOfTravelers?: number,
  maintenanceMode?: boolean,
  packageType?: string
) => {
  const api = useApi();
  const appService = useAppService();
  const config = useConfig();
  const thgConfig = useThgConfig();
  const imgBaseUrl = thgConfig.baseUrl || config.translationsUrl;

  const [possibleOrigins, setPossibleOrigins] = useState<string[]>([]);
  const [packages, setPackages] = useState<ApiBestPricePackageModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialRequestFinished, setInitialRequestFinished] = useState<boolean>(false);
  const promotionCodeRef = useRef<PromotionCodeData>(promotionCode);

  const getPackages = useCallback(
    async (filters?: FilterParams): Promise<void> => {
      if (maintenanceMode || !packageCodes || (!!packageCodes && packageCodes.length === 0)) {
        return;
      }
      setIsLoading(true);
      let hasPackages = false;
      try {
        const response = await api.searchFlightTripPackages(
          ApiGetFlightTripPackageRequestFromJSON({
            busStations: filters?.busStations,
            startDate: filters?.startDate || earliestPossibleDate,
            endDate: filters?.endDate || latestPossibleDate,
            roomContainer: [
              {
                adults: filters?.numberOfTravelers || numberOfTravelers || 2,
                children: 0,
                infants: 0,
                childrenAges: [],
                infantsAges: []
              }
            ],
            origins: filters?.origins,
            packageCodes: packageCodes
              ?.filter(code => !filters?.smallGroup || (filters?.smallGroup && code.smallGroup))
              .map(code => ({
                packageCode: code.code,
                smallGroupOnly: code.smallGroup
              })),
            subType: ApiItemType.PACKAGE,
            promotionCode: isValidPromotionCode(promotionCode.promotionCodeData)
              ? ApiPromotionCodeFromJSON({
                  code: promotionCode.promotionCodeData.code,
                  percentageValue: promotionCode.promotionCodeData.percentageValue,
                  absoluteValue: promotionCode.promotionCodeData.absoluteValue
                })
              : undefined
          })
        );
        if (response?.packages) {
          const bestPricePackages = response.packages;
          let filteredPackages = bestPricePackages;

          if (packageType === PackageTypes.PBUS) {
            filteredPackages = filterBusPackages(bestPricePackages);
          }

          setPackages(filteredPackages);
          itemListEvent.broadcast({ items: filteredPackages });

          if (
            filteredPackages.some(
              (singlePackage: ApiBestPricePackageModel) => !!singlePackage.origin
            )
          ) {
            setPossibleOrigins(
              filteredPackages
                .filter((singlePackage: ApiBestPricePackageModel) => !!singlePackage.origin)
                .map((singlePackage: ApiBestPricePackageModel) => singlePackage.origin)
            );
          }

          hasPackages = true;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        if (hasPackages) {
          setTimeout(() => {
            const { hash } = location;
            if (!!hash) {
              const anchor = document.getElementById(hash.substring(1));
              if (!!anchor) {
                anchor.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }, 300);
        }
      }
    },
    [api, promotionCode, packageCodes, maintenanceMode, numberOfTravelers, packageType]
  );

  const filterBusPackages = (
    busPackages: ApiBestPricePackageModel[]
  ): ApiBestPricePackageModel[] => {
    const selectedStations = sessionStorage.getItem('selectedStations');

    if (!selectedStations) return [];

    const parsedSelectedStations: RentalCarOfficeWithDistanceWrapper[] = JSON.parse(
      selectedStations
    );
    const selectedStationCodes = new Set(parsedSelectedStations.map(station => station.code));

    return busPackages.filter(busPackage =>
      busPackage.stationCodes?.some(stationCode => selectedStationCodes.has(stationCode))
    );
  };
  const hasPromotionCodeChanged = (val: PromotionCodeData): boolean => {
    return JSON.stringify(val) !== JSON.stringify(promotionCodeRef.current);
  };
  useEffect(() => {
    (async () => {
      if (
        !!packageCodes &&
        promotionCode.initialPromotionCodeChecked &&
        initialStorageFilters.initialFiltersChecked &&
        (!initialRequestFinished || hasPromotionCodeChanged(promotionCode))
      ) {
        if (!!initialStorageFilters.initialFilters) {
          await getPackages({ ...initialStorageFilters.initialFilters });
        } else {
          await getPackages();
        }
        setInitialRequestFinished(true);
        promotionCodeRef.current = promotionCode;
      }
    })();
  }, [
    earliestPossibleDate,
    latestPossibleDate,
    packageCodes,
    promotionCode,
    maintenanceMode,
    initialRequestFinished,
    initialStorageFilters
  ]);

  const selectedPostCode = sessionStorage.getItem('selectedPostCode');

  const isSelfArrivalOption = () => {
    if (selectedPostCode) {
      const parsedPostCode: ApiBaseData = JSON.parse(selectedPostCode);
      return parsedPostCode.code === SELF_ARRIVAL_CODE;
    }
    return false;
  };

  const countBusStops = (
    busStations: string[] | undefined,
    possibleBusStations: RentalCarOfficeWithDistanceWrapper[]
  ) => {
    if (isSelfArrivalOption()) {
      return 1;
    }
    const reducedList = getReducedBusStationList(busStations);
    if (possibleBusStations) {
      return possibleBusStations.filter(
        busStationData =>
          reducedList.includes(busStationData.code ? busStationData.code : 'dummy') &&
          busStationData.distance <= BUS_STOPS_PERIMETER
      ).length;
    }
    return 0;
  };

  const collectBusStopsInfo = (
    busStops: string[] | undefined,
    possibleBusStations: RentalCarOfficeWithDistanceWrapper[]
  ): React.ReactNode => {
    if (isSelfArrivalOption()) {
      return t(Keys.busStops);
    }
    const reducedList = getReducedBusStationList(busStops);
    if (possibleBusStations) {
      return possibleBusStations
        .filter(
          busStationData =>
            reducedList.includes(busStationData.code ? busStationData.code : 'dummy') &&
            busStationData.distance <= BUS_STOPS_PERIMETER
        )
        .map(actualBusStop => (
          <li key={actualBusStop.code}>
            <FontAwesomeIcon icon={faCheck} className={'bus_tooltip_icon'} />
            {actualBusStop.description + ' (' + Math.round(actualBusStop.distance) + ' km)'}
          </li>
        ));
    }
    return <></>;
  };

  const sanitizeString = (id: string) => {
    return id.replaceAll(/[= &]/g, '');
  };

  const [tooltipOpen, setTooltipOpen] = useState<Map<string, boolean>>(new Map());
  const toggle = (id: string) => {
    setTooltipOpen(new Map(tooltipOpen.set(id, tooltipOpen.has(id) ? !tooltipOpen.get(id) : true)));
  };

  const isTooltipOpen = (packageId: string) => {
    return tooltipOpen.has(packageId) ? tooltipOpen.get(packageId) : false;
  };

  const getSelectedStations = (): RentalCarOfficeWithDistanceWrapper[] => {
    const selectedStations = sessionStorage.getItem('selectedStations');
    if (!selectedStations) return [];
    return JSON.parse(selectedStations);
  };

  const packagesMappingCallback = useCallback(
    (singlePackage: ApiBestPricePackageModel): ListRow<ListRowItem> => {
      const possibleBusStations = getSelectedStations();
      const busStopCount = countBusStops(singlePackage.stationCodes, possibleBusStations);
      const tooltipTextContent = collectBusStopsInfo(
        singlePackage.stationCodes,
        possibleBusStations
      );

      const packageId = sanitizeString(singlePackage.id);

      return {
        id: singlePackage.id,
        code: singlePackage.code,
        specialRow: singlePackage.smallGroup,
        nodeRef: createRef<HTMLElement | undefined>() as RefObject<HTMLElement | undefined>,
        item: {
          startDateImg: {
            value: '',
            imgSrc: `${imgBaseUrl}/img/calendar.svg`,
            hideInDesktop: true,
            className: 'simple-list__cell__startDate--img'
          },
          startDate: {
            value: !!singlePackage.startDate
              ? `${
                  dayjs().localeData().weekdaysShort()[dayjs(singlePackage.startDate).day()]
                } ${dayjs(singlePackage.startDate).format(
                  config.displayFormatDate[appService.lang.replace('-', '_')]
                )}`
              : '',
            className: 'simple-list__cell__startDate'
          },
          ...(packages.some(innerSinglePackage => innerSinglePackage.smallGroup)
            ? {
                smallGroup: {
                  value: '',
                  imgSrc: `${imgBaseUrl}/img/kleingruppe.svg`,
                  hideInMobile: false,
                  className: `simple-list__cell__small-group ${
                    singlePackage.smallGroup ? '' : 'no-smallgroup'
                  }`
                }
              }
            : {}),
          originImg: {
            value: '',
            imgSrc:
              singlePackage.typeCode === PackageTypes.PFLUG
                ? `${imgBaseUrl}/img/airplane.svg`
                : `${imgBaseUrl}/img/bus.svg`,
            hideInDesktop: true,
            className: 'simple-list__cell__origin--img'
          },
          origin: {
            value:
              singlePackage.typeCode === 'PFLUG' ? (
                singlePackage.originName ?? ''
              ) : (
                <div id={packageId + '_cont'} className={'simple-list__cell__bus-stops'}>
                  <div
                    id={packageId + '_tooltip'}
                    onClick={() => toggle(singlePackage.id)}
                    role="button"
                    onKeyDown={() => toggle(singlePackage.id)}
                  >
                    {busStopCount} {busStopCount > 1 ? t(Keys.busStop_plural) : t(Keys.busStop)}
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={'simple-list__cell__bus-stops-icon'}
                    ></FontAwesomeIcon>
                  </div>
                  <Tooltip
                    placement={'bottom'}
                    isOpen={isTooltipOpen(singlePackage.id)}
                    target={packageId + '_tooltip'}
                    toggle={() => toggle(singlePackage.id)}
                    popperClassName="tooltip-popper"
                    innerClassName="tooltip-inner"
                    container={packageId + '_cont'}
                    onClick={() => toggle(singlePackage.id)}
                    autohide={true}
                  >
                    <div className="tooltip__text">
                      {t(Keys.possibleBusStops, { count: busStopCount })}
                    </div>
                    <div className="tooltip__info">{tooltipTextContent}</div>
                  </Tooltip>
                </div>
              ),
            className: 'simple-list__cell__origin'
          },
          availabilityImg: {
            value: '',
            imgSrc: `${imgBaseUrl}/img/seat.svg`,
            hideInDesktop: true,
            className: 'simple-list__cell__availability--img'
          },
          availability: {
            value: getAvailability(singlePackage.availability).value,
            className: `simple-list__cell__availability ${
              getAvailability(singlePackage.availability).class
            }`
          },
          price: {
            value: (
              <>
                <div
                  className={
                    isValidPromotionCode(promotionCode.promotionCodeData)
                      ? 'simple-list__cell__price__reduced'
                      : 'simple-list__cell__price__normal'
                  }
                >
                  {t(Keys.from)}{' '}
                  {!!singlePackage.price?.finalPrice
                    ? formatPrice(singlePackage.price.finalPrice)
                    : 0}
                </div>{' '}
                {isValidPromotionCode(promotionCode.promotionCodeData) && (
                  <div className={'simple-list__cell__price__striked'}>
                    {t(Keys.instead)}{' '}
                    <s>
                      {promotionCode.promotionCodeData?.absoluteValue
                        ? formatPrice(
                            singlePackage.price.finalPrice +
                              promotionCode.promotionCodeData.absoluteValue
                          )
                        : promotionCode.promotionCodeData?.percentageValue
                        ? formatPrice(
                            (singlePackage.price.finalPrice /
                              promotionCode.promotionCodeData.percentageValue) *
                              100
                          )
                        : 0}
                    </s>
                  </div>
                )}
              </>
            ),
            className: 'simple-list__cell__price'
          }
        }
      };
    },
    [packages, tooltipOpen]
  );

  return {
    packages,
    isLoading,
    possibleOrigins,
    getPackages,
    packagesMappingCallback
  };
};

export default usePackagesListFunctions;
