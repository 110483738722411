import React, { createContext, PropsWithChildren, useContext, JSX } from 'react';
import { ApiAffiliateResponse } from '@ibe/api';

export const AffiliateContext = createContext<ApiAffiliateResponse>({
  cssVars: {},
  useCustomCMP: undefined,
  metaContent: {}
});

export const AffiliateProvider = ({
  children,
  res
}: PropsWithChildren<{
  res: ApiAffiliateResponse;
}>): JSX.Element => {
  return <AffiliateContext.Provider value={res}>{children}</AffiliateContext.Provider>;
};

export const useAffiliateContext = (): ApiAffiliateResponse => {
  return useContext<ApiAffiliateResponse>(AffiliateContext);
};
