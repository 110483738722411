import React, { useCallback, useState } from 'react';
import { CustomInput, Tooltip } from 'reactstrap';
import { PromotionCodeData } from './PromotionCode';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sanitize } from '@ibe/services';

const SingleSelection = ({
  isChecked,
  setIsChecked,
  infoText,
  showInfoTextInFront,
  checkboxLabel,
  imgSrc,
  icon,
  promotionCode,
  selectionId,
  showTooltip,
  tooltipText
}: {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  imgSrc?: string;
  icon?: IconDefinition;
  infoText?: string;
  showInfoTextInFront?: boolean;
  checkboxLabel: string;
  promotionCode: PromotionCodeData;
  selectionId: string;
  showTooltip?: boolean;
  tooltipText?: string;
}): JSX.Element => {
  const handleChange = useCallback((): void => {
    setIsChecked(!isChecked);
  }, [isChecked, setIsChecked, promotionCode]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipTextContent = tooltipText ? tooltipText : '';

  return (
    <div className="small-group-selection">
      {showInfoTextInFront && (
        <div className="small-group-selection__info">
          {!!imgSrc ? <img src={imgSrc} alt="Kleingruppenreise" /> : <></>}
          <span>{infoText}</span>
        </div>
      )}
      <CustomInput
        id={selectionId}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        label={checkboxLabel}
      />
      {!showInfoTextInFront && (
        <div id={selectionId + '_cont'} className="small-group-selection__info__end">
          {!!imgSrc ? (
            <img src={imgSrc} alt="SingleSelectionImg" />
          ) : (
            !!icon && (
              <>
                <FontAwesomeIcon
                  id={selectionId + '_tooltip'}
                  icon={icon}
                  onClick={toggle}
                ></FontAwesomeIcon>
                {showTooltip && (
                  <>
                    <Tooltip
                      placement={'right'}
                      isOpen={tooltipOpen}
                      target={selectionId + '_tooltip'}
                      toggle={toggle}
                      popperClassName="tooltip-popper"
                      innerClassName="tooltip-inner"
                      container={selectionId + '_cont'}
                      onClick={toggle}
                      autohide={true}
                    >
                      <div className="tooltip__text">{''}</div>
                      <div
                        className="tooltip__info"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(tooltipTextContent)
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SingleSelection;
