import { ApiBooking, ApiTraveler } from '@ibe/api';
import { observer } from 'mobx-react';
import React, { JSX, useState } from 'react';
import SummaryContent from './Content';
import FixedElementScroll from './FixedElementScroll';
import HotLine from './HotLine';
import SummaryFixedBottomBar from './SummaryFixedBottomBar';
import SummaryModal from './SummaryModal';
import CheckoutStore, { ProductIBEMode } from '../../CheckoutStore';
import { SelectedItemsType } from '../ExtendedBooking/ContextAndProvider';
import { MEDIAQUERY_DEFAULTS, useMediaQuery } from '@ibe/components';

export interface StickySummaryProps {
  booking: ApiBooking | null;
  next: () => void;
  disabled: boolean;
  loading: boolean;
  store: CheckoutStore;
  rightBottomText?: string;
  navBarOffset?: number;
  productIBEMode?: ProductIBEMode;
  showPromotionCode?: boolean;
  handleBookingError?: (errorMessage: string) => void;
  travelers?: ApiTraveler[];
  hideMobileBar?: boolean;
  hideSummaryContent?: boolean;
  extendedBookingItems?: SelectedItemsType;
  isWhitelabel?: boolean;
}

const StickySummary = observer(
  ({
    booking,
    disabled,
    loading,
    rightBottomText,
    productIBEMode,
    showPromotionCode,
    handleBookingError,
    travelers,
    hideMobileBar,
    next,
    hideSummaryContent,
    extendedBookingItems,
    isWhitelabel,
    store
  }: StickySummaryProps): JSX.Element => {
    const isMobile = useMediaQuery({ query: MEDIAQUERY_DEFAULTS.md, type: 'max' });
    return (
      <>
        {(!isMobile || hideMobileBar) && (
          <FixedElementScroll width={100}>
            <div id="sticky-box-summary-container">
              {booking && !hideSummaryContent && (
                <div className="sticky-summary-box">
                  <SummaryContent
                    booking={booking}
                    productIBEMode={productIBEMode}
                    showPromotionCode={showPromotionCode}
                    handleBookingError={handleBookingError}
                    travelers={travelers}
                    extendedBookingItems={extendedBookingItems}
                    store={store}
                  />
                </div>
              )}
              <HotLine />
            </div>
          </FixedElementScroll>
        )}
        {booking && isMobile && !hideMobileBar && (
          <MobileBar
            booking={booking}
            next={next}
            disabled={disabled}
            loading={loading}
            rightBottomText={rightBottomText}
            productIBEMode={productIBEMode}
            showPromotionCode={showPromotionCode}
            handleBookingError={handleBookingError}
            travelers={travelers}
            isWhitelabel={isWhitelabel}
            store={store}
          />
        )}
      </>
    );
  }
);

export interface MobileBarProps {
  booking: ApiBooking;
  next: () => void;
  disabled: boolean;
  loading: boolean;
  store: CheckoutStore;
  rightBottomText?: string | undefined;
  productIBEMode?: ProductIBEMode;
  showPromotionCode?: boolean;
  handleBookingError?: (errorMessage: string) => void;
  travelers?: ApiTraveler[];
  isWhitelabel?: boolean;
}

export const MobileBar = (props: MobileBarProps) => {
  const {
    booking,
    next,
    disabled,
    loading,
    rightBottomText,
    productIBEMode,
    showPromotionCode,
    handleBookingError,
    travelers,
    isWhitelabel,
    store
  } = props;
  const [isModalOpen, setOpenModal] = useState(false);
  const [paddingBottom, setPaddingBottom] = useState(0);

  const handleHeightChange = (height: number) => {
    setPaddingBottom(height);
  };

  return (
    <div className="d-block d-md-none" style={{ paddingBottom: `${paddingBottom}px` }}>
      <SummaryFixedBottomBar
        onHeightChange={isWhitelabel ? handleHeightChange : undefined}
        booking={booking}
        next={next}
        disabled={disabled}
        loading={loading}
        showModal={() => setOpenModal(true)}
        rightBottomText={rightBottomText}
      />
      <SummaryModal hideModal={() => setOpenModal(false)} isModalOpen={isModalOpen}>
        <SummaryContent
          booking={booking}
          productIBEMode={productIBEMode}
          showPromotionCode={showPromotionCode}
          handleBookingError={handleBookingError}
          travelers={travelers}
          store={store}
        />
      </SummaryModal>
    </div>
  );
};

export default StickySummary;
