import React, { ChangeEvent, useCallback, useEffect, useRef, useState, JSX } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import Keys from '../Translations/generated/product-ibe.de.json.keys';
import fallback from '../Translations/generated/product-ibe.de.json';
import { Alert, Button, Input } from 'reactstrap';
import { LoggerFactory } from '@ibe/services';
import { useTranslation } from '@ibe/components';
import { ValidPromotionCode, validPromotionCodeStorage } from '../Pages/Util/utils';
import useSearchApi from '../Util/useSearchApi';
import { Api, ApiPromotionDataRequestFromJSON } from '../../api';
import { PackageCode } from '../Pages/Products/PackagesList';
import { useMount } from 'react-use';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const logger = LoggerFactory.get('PromotionCode');

export type PromotionCodeType = ValidPromotionCode | 'NONE' | 'ERROR' | undefined;
export type PromotionCodeData = {
  promotionCodeData: PromotionCodeType;
  initialPromotionCodeChecked: boolean;
};
type PromotionError =
  | 'DEFAULT'
  | 'USE_PREV_CODE_AGAIN'
  | 'OLD_CODE_IS_BETTER'
  | 'NEW_CODE_IS_OLD_CODE'
  | undefined;

// extend window object with the CMS function to update the current price by the given promo discount
type WindowWithCMSFunction = Window & {
  productDetailsUpdatePromoCode: (promoDiscount: number) => void;
};

declare const window: WindowWithCMSFunction;

export const isValidPromotionCode = (code: PromotionCodeType): code is ValidPromotionCode => {
  return typeof code === 'object' && code.hasOwnProperty('code') && !!code.code;
};

export const validatePromotionCode = async (
  searchApi: Api,
  packageCodes: Array<PackageCode>,
  code?: string,
  basePrice?: string
): Promise<ValidPromotionCode> => {
  let result = await searchApi.addPromotionCode(
    ApiPromotionDataRequestFromJSON({
      rkid: packageCodes.find(packageCode => !packageCode.smallGroup)?.code,
      promotionCandidate: code,
      basePrice: basePrice !== undefined ? basePrice : undefined
    })
  );
  let resPromotionCode = result?.item?.percentagePromotionCode
    ? result?.item?.percentagePromotionCode
    : result?.item?.absolutePromotionCode
    ? result?.item?.absolutePromotionCode
    : '';
  if (!resPromotionCode && !!packageCodes.find(packageCode => packageCode.smallGroup)) {
    result = await searchApi.addPromotionCode(
      ApiPromotionDataRequestFromJSON({
        rkid: packageCodes.find(packageCode => packageCode.smallGroup)?.code,
        promotionCandidate: code,
        basePrice: basePrice !== undefined ? basePrice : undefined
      })
    );
    resPromotionCode = result?.item?.percentagePromotionCode
      ? result?.item?.percentagePromotionCode
      : result?.item?.absolutePromotionCode
      ? result?.item?.absolutePromotionCode
      : '';
  }
  return {
    code: resPromotionCode,
    percentageValue: result?.item?.percentage,
    absoluteValue: result?.item?.absoluteDiscount,
    preSign: result?.item?.absolutePromotionCode ? '€ ' : '',
    sign: result?.item?.percentagePromotionCode ? ' %' : '',
    productCode: result?.item?.rkid
  };
};

interface PromotionCodeProps {
  onSubmit: (code: PromotionCodeType, fromInputSubmit?: boolean) => void;
  promotionCode: PromotionCodeType;
  setPromotionCode?: (promotionCode: PromotionCodeType) => void;
  disabled?: boolean;
  packageCodes: Array<PackageCode>;
  smallMode?: boolean;
  skipValidation?: boolean;
  numberOfTravelers?: number;
  checkForCodeInStorage?: boolean;
  hideInfoBox?: boolean;
}

const promoCodeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.749 24.874">
    <g id="discount-icon" transform="translate(-6412.747 -195.859)">
      <path
        id="Differenzmenge_2"
        data-name="Differenzmenge 2"
        d="M10.607,24.042h0L0,13.435,13.435,0,24.042,10.607,10.607,24.041Zm.63-9.33h0a3.684,3.684,0,0,0,2.069.714,2.735,2.735,0,0,0,1.96-.842,2.209,2.209,0,0,0,.526-2.325l-.891.161a1.364,1.364,0,0,1-.356,1.357,1.342,1.342,0,0,1-.979.411,2.514,2.514,0,0,1-1.336-.47l1.629-1.629-.577-.577-1.706,1.705c-.1-.085-.2-.179-.3-.28a2.741,2.741,0,0,1-.213-.229l2.045-2.046-.577-.577-1.985,1.985c-.632-.944-.659-1.785-.077-2.367a1.326,1.326,0,0,1,.994-.411,2.126,2.126,0,0,1,.219.012l.068-.967a2.206,2.206,0,0,0-.473-.052,2.27,2.27,0,0,0-1.624.705,3.073,3.073,0,0,0-.085,4.056l-.509.594.535.535.483-.484a3.4,3.4,0,0,0,.247.263,3.717,3.717,0,0,0,.271.255l-.449.518.535.535.551-.55Z"
        transform="translate(6412.747 196.297)"
        fill="#004f9e"
      />
      <ellipse
        id="Ellipse_100"
        data-name="Ellipse 100"
        cx="5.817"
        cy="3.996"
        rx="5.817"
        ry="3.996"
        transform="translate(6429.269 195.859) rotate(45)"
        fill="#004f9e"
      />
      <ellipse
        id="Ellipse_101"
        data-name="Ellipse 101"
        cx="5.556"
        cy="3.996"
        rx="5.556"
        ry="3.996"
        transform="translate(6418.449 207.223) rotate(45)"
        fill="#004f9e"
      />
    </g>
  </svg>
);

const PromotionCodeWrapper = (props: PromotionCodeProps) => {
  const { smallMode } = props;
  if (smallMode) return <PromotionCodeSmallMode {...props} />;
  return <PromotionCode {...props} />;
};

const PromotionCode = (props: PromotionCodeProps): JSX.Element => {
  const {
    code,
    handleClearClick,
    handleKeyDownSubmit,
    promotionError,
    promotionSuccess,
    submit,
    togglePromotionError,
    inputRef,
    t,
    setCode,
    getSavedOrOldCode,
    hideInfoBox
  } = usePromotionCode(props);
  const { promotionCode, disabled } = props;
  const oldCode = getSavedOrOldCode();

  return (
    <div className={`prom-code__container__outer`}>
      <div className={`prom-code__container`}>
        <div className="prom-code__left">
          <h3>{t(Keys.promotionCodeHeadline)}</h3>
          {!isValidPromotionCode(promotionCode) && !promotionError ? (
            <p>{t(Keys.promotionCodeText)}</p>
          ) : (
            <></>
          )}

          <div className="prom-code__input">
            <div className="prom-code__input__inner">
              <Input
                innerRef={inputRef}
                value={code}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.code === 'Enter') handleKeyDownSubmit(e);
                }}
                disabled={disabled}
              />
              <FontAwesomeIcon icon={faTimesCircle} onClick={handleClearClick} />
            </div>
            <Button color="primary" onClick={submit} disabled={code === ''}>
              {t(Keys.submit)}
            </Button>
          </div>
          {isValidPromotionCode(promotionCode) ? (
            <Alert className="mt-4" color="success" fade isOpen={promotionSuccess}>
              {t(Keys.promotionCodeSuccess, {
                code: promotionCode.code,
                discount: promotionCode.percentageValue || promotionCode.absoluteValue || 0,
                preSign: promotionCode.preSign,
                sign: promotionCode.sign
              })}
            </Alert>
          ) : (
            <></>
          )}
          <Alert
            className="mt-4"
            color={promotionError === 'NEW_CODE_IS_OLD_CODE' ? 'success' : 'warning'}
            fade
            isOpen={!!promotionError}
            toggle={togglePromotionError}
          >
            {promotionError === 'OLD_CODE_IS_BETTER'
              ? t(Keys.promotionErrorOldIsBetter, { oldCode })
              : promotionError === 'USE_PREV_CODE_AGAIN'
              ? t(Keys.promotionErrorAdditional, { oldCode })
              : promotionError === 'NEW_CODE_IS_OLD_CODE'
              ? t(Keys.promotionErrorNewIsOld, { oldCode })
              : t(Keys.promotionError)}
          </Alert>
        </div>

        {!hideInfoBox && (
          <div className="prom-code__right">
            <div className="prom-code__info">
              <div>
                <FontAwesomeIcon icon={faClock} />
                <span>{t(Keys.earlyBirdHeadline)}</span>
              </div>
              <p>{t(Keys.earlyBirdText)}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PromotionCodeSmallMode = (props: PromotionCodeProps) => {
  const {
    code,
    handleClearClick,
    handleKeyDownSubmit,
    promotionError,
    promotionSuccess,
    submit,
    togglePromotionError,
    inputRef,
    t,
    isEditing,
    toggleEditing,
    getSavedOrOldCode,
    setCode
  } = usePromotionCode({ ...props, checkForCodeInStorage: true });
  const { promotionCode, disabled } = props;

  const header = (
    <div className={`prom-code__container prom-code__container--small align-items-center`}>
      <div className="prom-code__icon">{promoCodeIcon}</div>
      <h3>{t(Keys.promotionCode)}</h3>
    </div>
  );
  const oldCode = getSavedOrOldCode();
  if (!isEditing) {
    return (
      <div className={`mt-3 mb-4`}>
        {header}
        <div className="prom-code__not_editing">
          <div>
            {isValidPromotionCode(promotionCode) && (
              <div className="mt-2">
                {t(Keys.promotionCodeSuccess, {
                  code: promotionCode.code,
                  discount: promotionCode.percentageValue || promotionCode.absoluteValue || 0,
                  preSign: promotionCode.absoluteValue ? `${promotionCode.sign} ` : undefined,
                  sign: promotionCode.percentageValue ? `${promotionCode.sign} ` : undefined
                })}
              </div>
            )}
          </div>
          <div>
            <Button color="secondary" onClick={() => toggleEditing()}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`prom-code__container__outer mt-3`}>
      {header}
      <div>
        {!isValidPromotionCode(promotionCode) && !promotionError && (
          <div className="mt-2">{t(Keys.promotionCodeText)}</div>
        )}
        {isValidPromotionCode(promotionCode) && promotionSuccess && (
          <div className="mt-2">
            {t(Keys.promotionCodeSuccess, {
              code: promotionCode.code,
              discount: promotionCode.percentageValue || promotionCode.absoluteValue || 0,
              preSign: promotionCode.absoluteValue ? `${promotionCode.sign} ` : undefined,
              sign: promotionCode.percentageValue ? `${promotionCode.sign} ` : undefined
            })}
          </div>
        )}
      </div>

      <div
        className={`${
          isValidPromotionCode(promotionCode) ? 'mt-3' : ''
        } prom-code__container prom-code__container--small`}
      >
        <div>
          <Alert
            className="prom-code__alert mt-2"
            color="warning"
            fade
            isOpen={!!promotionError}
            toggle={togglePromotionError}
          >
            {promotionError === 'OLD_CODE_IS_BETTER'
              ? t(Keys.promotionErrorOldIsBetter, { oldCode })
              : promotionError === 'USE_PREV_CODE_AGAIN'
              ? t(Keys.promotionErrorAdditional, { oldCode })
              : promotionError === 'NEW_CODE_IS_OLD_CODE'
              ? t(Keys.promotionErrorNewIsOld, { oldCode })
              : t(Keys.promotionError)}
          </Alert>
          <div className="prom-code__input">
            <div className="prom-code__input__inner">
              <Input
                innerRef={inputRef}
                value={code}
                placeholder={t(Keys.addHere)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.code === 'Enter') handleKeyDownSubmit(e);
                }}
                disabled={disabled}
              />
              {code ? <FontAwesomeIcon icon={faTimesCircle} onClick={handleClearClick} /> : null}
            </div>
            <Button color="secondary" onClick={submit} disabled={code === ''}>
              {t(Keys.submit)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const usePromotionCode = ({
  onSubmit,
  promotionCode,
  packageCodes,
  skipValidation,
  checkForCodeInStorage,
  setPromotionCode,
  hideInfoBox
}: PromotionCodeProps) => {
  const { t } = useTranslation('product-ibe', fallback);
  const searchApi = useSearchApi();
  const [code, setCode] = useState<string>('');
  const [promotionError, setPromotionError] = useState<PromotionError>(undefined);
  const [promotionSuccess, setPromotionSuccess] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const initialMount = useRef<boolean>(true);

  useMount(() => {
    if (checkForCodeInStorage) {
      const data = validPromotionCodeStorage.get();
      if (!!data?.code) {
        setCode(data.code);
        setIsEditing(false);
        if (!!setPromotionCode) {
          setPromotionCode(data);
        }
      }
    }
  });

  const setError = (promotionErrorString?: PromotionError, keepCode?: boolean): void => {
    setPromotionSuccess(false);
    const data = validPromotionCodeStorage.get();
    if (!keepCode) setCode(!!data?.code ? data.code : '');
    setPromotionError(promotionErrorString || (!!data?.code ? 'USE_PREV_CODE_AGAIN' : 'DEFAULT'));
  };
  const getSavedOrOldCode = () => {
    return validPromotionCodeStorage.get()?.code;
  };
  useEffect(() => {
    if (isValidPromotionCode(promotionCode)) {
      setPromotionSuccess(true);
      setPromotionError(undefined);
      setIsEditing(false);
      setCode(promotionCode.code);
    } else if (!initialMount.current) {
      setError();
    }
    initialMount.current = false;
  }, [promotionCode]);

  const togglePromotionError = useCallback(() => {
    setPromotionError(undefined);
    const data = validPromotionCodeStorage.get();
    if (!!data?.code) {
      setIsEditing(false);
      onSubmit(data, true);
    }
  }, []);

  const handleKeyDownSubmit = (e: React.KeyboardEvent) => {
    e.preventDefault();
    submit().then();
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
    setPromotionSuccess(true);
  };

  const submit = async (): Promise<void> => {
    if (code !== '') {
      setPromotionError(undefined);
      try {
        if (!skipValidation) {
          const data = await validatePromotionCode(searchApi, packageCodes, code);
          if (!!data.code) {
            const oldData = validPromotionCodeStorage.get();
            if (data.code === oldData?.code) {
              setError('NEW_CODE_IS_OLD_CODE');
            } else if (
              (!!oldData?.percentageValue &&
                !!data?.percentageValue &&
                oldData.percentageValue > data.percentageValue * 100) ||
              (!!oldData?.absoluteValue &&
                !!data?.absoluteValue &&
                oldData.absoluteValue > data.absoluteValue) ||
              (!!oldData?.percentageValue && !data.percentageValue)
            ) {
              setError('OLD_CODE_IS_BETTER');
            } else {
              data.percentageValue = data?.percentageValue ? data.percentageValue * 100 : undefined;
              validPromotionCodeStorage.set({ ...data, manuallyAdded: true });
              onSubmit(data, true);
              setCode('');
              if (data.percentageValue || data.absoluteValue) {
                // call the CMS function to update the right column season card price by the given promoDiscount
                if (window?.productDetailsUpdatePromoCode) {
                  window.productDetailsUpdatePromoCode(
                    data.percentageValue
                      ? data.percentageValue / 100
                      : (data.absoluteValue as number)
                  );
                }
              }
            }
          } else {
            setError(undefined, true);
          }
        } else {
          onSubmit({ code }, true);
          setCode('');
        }
      } catch (err) {
        logger.error(err);
        setError();
      }
    }
  };

  const handleClearClick = (): void => {
    setCode('');
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };
  return {
    handleClearClick,
    handleKeyDownSubmit,
    submit,
    togglePromotionError,
    setError,
    code,
    promotionError,
    promotionSuccess,
    inputRef,
    t,
    setCode,
    isEditing,
    toggleEditing,
    getSavedOrOldCode,
    hideInfoBox
  };
};

export default PromotionCodeWrapper;
