/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-24 15:03:13 */

const Keys = {
  from: 'from',
  nights: 'nights',
  roomOnly: 'roomOnly',
  with: 'with',
  choose: 'choose',
  additionalDatesAvailable: 'additionalDatesAvailable'
}; export default Keys;