import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Keys from '../../../../../Translations/generated/Checkout.de.json.keys';
import fallback from '../../../../../Translations/generated/Checkout.de.json';
import CommonWrapper, { RemountWrapper } from '../CommonWrapper';
import { useBookingService, useTranslation } from '@ibe/components';
import { ExtendedApiTraveler, useExtendedBooking } from '../ContextAndProvider';
import { ApiTraveler, ApiTravelerToJSON } from '@ibe/api';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import PaymentPopUp from '../../PaymentPopUp';
import { addTravelDetailsEvent } from '@ibe/services';
import { useCollapsibleWizard } from '../../CollapsibleWizard/ContextAndProvider';

const TravelersComponent = ({ idx, remount }: { idx: number; remount?: () => void }) => {
  const { selectedItems, setSelectedItems } = useExtendedBooking();

  const bs = useBookingService();
  const booking = bs.booking;
  const bookingTravelers = booking?.travelers;
  const [travelers, setTravelers] = useState<ApiTraveler[]>([]);
  const { getCurrentIndex } = useCollapsibleWizard();

  const { t } = useTranslation('Checkout', fallback);

  const getUpdatedTravelerInfo = (traveler: ExtendedApiTraveler): ExtendedApiTraveler => {
    const foundTraveler = bookingTravelers?.find(tr => tr.id === traveler.id);
    if (foundTraveler) {
      return {
        ...foundTraveler,
        position: traveler.position
      };
    }
    return traveler;
  };

  useEffect(() => {
    if (!!bookingTravelers && bookingTravelers.length > 0) {
      setTravelers([...bookingTravelers]);
      setSelectedItems({
        ...selectedItems,
        travelers: bookingTravelers,
        busStation: selectedItems.busStation
      });

      if (selectedItems.travelers) {
        const updatedSelectedItems = { ...selectedItems };

        if (updatedSelectedItems.roomsMain) {
          updatedSelectedItems.roomsMain = updatedSelectedItems.roomsMain.map(roomInfo => {
            return {
              ...roomInfo,
              travelers: roomInfo.travelers.map(getUpdatedTravelerInfo)
            };
          });
        }

        if (updatedSelectedItems.roomsOptional) {
          updatedSelectedItems.roomsOptional = updatedSelectedItems.roomsOptional.map(roomInfo => {
            return {
              ...roomInfo,
              travelers: roomInfo.travelers.map(getUpdatedTravelerInfo)
            };
          });
        }
        updatedSelectedItems.travelers = bookingTravelers;
        setSelectedItems(updatedSelectedItems);
      }
    }
  }, [bookingTravelers]);

  const onNext = async () => {
    addTravelDetailsEvent.broadcast({
      step: `${getCurrentIndex() + 1}. ` + t(Keys.extBookingTravelers),
      booking: bs.booking
    });
    await bs.updateTravelers(travelers.map(traveler => ApiTravelerToJSON({ ...traveler })));
    setSelectedItems({ ...selectedItems, travelers });
  };

  const handleTravelerChange = (
    e: ChangeEvent<HTMLInputElement>,
    property: string,
    traveler: ApiTraveler
  ) => {
    const newTravelers = [...travelers];
    const newTravelerIndex = newTravelers.findIndex(
      singleTraveler => singleTraveler.id === traveler.id
    );
    newTravelers.splice(newTravelerIndex, 1, {
      ...newTravelers[newTravelerIndex],
      [property]: e.target.value
    });
    if (newTravelerIndex >= 0) {
      setTravelers(newTravelers);
    }
  };

  const disableBtn = useCallback((): boolean => {
    return travelers.some(
      traveler =>
        traveler.firstName === null ||
        traveler.lastName === null ||
        traveler.firstName.trim() === '' ||
        traveler.lastName.trim() === ''
    );
  }, [travelers]);

  return (
    <CommonWrapper
      idx={idx}
      description={t(Keys.extBookingEnterTravelers)}
      title={t(Keys.extBookingTravelers)}
      successNotes={[t(Keys.extBookingEnteredTravelers)]}
      onNext={onNext}
      disableButton={disableBtn()}
      jumpToNextMissingSuccessfulStep
      onClose={remount}
    >
      <>
        <Container className="checkout__extras traveler p-0">
          {travelers.map((traveler: ApiTraveler, tIndex: number) => (
            <Row key={'traveler' + tIndex} className="traveler-row">
              <Col
                className={`checkout__extras__name-form__label ${
                  tIndex === 0 ? 'checkout__extras__name-form__label--first' : ''
                }`}
                xs={12}
                lg={3}
              >
                <div>
                  <p className="traveler-headline mb-0">
                    {`${tIndex + 1}. ${t(Keys.person)}`}&nbsp;&nbsp;
                  </p>
                  {tIndex === 0 && (
                    <span>
                      <span className="customer">{t(Keys.customer)}</span>
                      <PaymentPopUp text={t(Keys.customerInfo)} />
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={6} lg={4} className="form-input-container offset-lg-1">
                <Label className="search-form__label font-weight-medium">{t(Keys.firstname)}</Label>
                <div className="position-relative">
                  <Input
                    className="search-form__input"
                    value={traveler.firstName || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleTravelerChange(e, 'firstName', traveler)
                    }
                  />
                  {!traveler.firstName && !!traveler.lastName && (
                    <div className="input--error">
                      <span className="form__error">{t(Keys.firstnameEmpty)}</span>
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={6} lg={4} className="form-input-container">
                <Label className="search-form__label font-weight-medium">{t(Keys.lastname)}</Label>
                <div className="position-relative">
                  <Input
                    className="search-form__input"
                    value={traveler.lastName || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleTravelerChange(e, 'lastName', traveler)
                    }
                  />
                  {!traveler.lastName && !!traveler.firstName && (
                    <div className="input--error">
                      <span className="form__error">{t(Keys.lastnameEmpty)}</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </>
    </CommonWrapper>
  );
};

export const Travelers = ({ idx }: { idx: number }) => (
  <RemountWrapper Component={TravelersComponent} props={{ idx }} />
);
