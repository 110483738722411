import React, { JSX } from 'react';
import { sanitize } from '@ibe/services';

export interface Props {
  message: string;
  title?: string;
  messageClass?: string;
}

const CustomInfo = (props: Props): JSX.Element => {
  const { message, title, messageClass } = props;
  return (
    <div className={`alert alert-${messageClass}`} role="alert">
      {title && <div className="font-weight-bold">title</div>}
      <span dangerouslySetInnerHTML={{ __html: sanitize(message, true) }} />
    </div>
  );
};
export default CustomInfo;
