/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-01 10:05:53 */

const Keys = {
  submit: 'submit',
  busStops: 'busStops',
  busStopsTooltip: 'busStopsTooltip',
  smallGroupInfo: 'smallGroupInfo',
  smallGroupLabel: 'smallGroupLabel',
  smallGroup: 'smallGroup',
  ownArrivalsAndDepartures: 'ownArrivalsAndDepartures',
  date: 'date',
  pricePerPerson: 'pricePerPerson',
  origin: 'origin',
  busStop: 'busStop',
  busStop_plural: 'busStop_plural',
  possibleBusStops: 'possibleBusStops',
  availability: 'availability',
  night: 'night',
  night_plural: 'night_plural',
  from: 'from',
  instead: 'instead',
  headline: 'headline',
  sectionText: 'sectionText',
  promotionCodeHeadline: 'promotionCodeHeadline',
  promotionCode: 'promotionCode',
  promotionCodeText: 'promotionCodeText',
  earlyBirdHeadline: 'earlyBirdHeadline',
  earlyBirdText: 'earlyBirdText',
  promotionCodeSuccess: 'promotionCodeSuccess',
  promotionError: 'promotionError',
  promotionErrorAdditional: 'promotionErrorAdditional',
  promotionErrorOldIsBetter: 'promotionErrorOldIsBetter',
  promotionErrorNewIsOld: 'promotionErrorNewIsOld',
  maintenanceMode: 'maintenanceMode',
  noPackagesFound: 'noPackagesFound',
  provideSearchCriteria: 'provideSearchCriteria',
  addHere: 'addHere'
}; export default Keys;