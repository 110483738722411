import React, { useContext, useEffect, useState } from 'react';
import ProgressbarWrapper from './ProgressBarWrapper';
import { Button, Col, Row } from 'reactstrap';
import StickySummary from './StickySummary';
import fallback from '../../../Translations/generated/Checkout.de.json';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import { CheckoutStep, CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import { observer } from 'mobx-react';
import CheckoutPageUrl from '../CheckoutPageUrl';
import { useHistory } from 'react-router-dom';
import { workflowContext } from '../../../contexts';
import { ApiTraveler } from '@ibe/api';
import {
  Overlay,
  OverlayContainer,
  ScrollerService,
  useBookingService,
  useConfig,
  useTranslation
} from '@ibe/components';
import ContentContainer from '../../../Components/ContentContainer';
import { FlightAndAccommodationSummaryHeader } from './FlightAndAccommodationSummary';
import ErrorWrapper from './ErrorWrapper';
import HorizontalSpread from '../../../Layouts/Helper/HorizontalSpread';
import { checkoutStepEvent, MaskBookingService, SessionStorage } from '@ibe/services';
import { SessionStorageType } from '../../../Components/Search';
import { productSearchSessionKey, productSearchSessionTempKey } from '../../../Config/config';
import OptionalOffers from './OptionalOffers/OptionalOffers';
import { useMount } from 'react-use';

const scrollElementError = 'travelerErrorScroller';
const sessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);
const sessionStorageTemp = new SessionStorage<SessionStorageType>(productSearchSessionTempKey);

const Extras = observer(function Extras(props: CheckoutStepComponentProps): JSX.Element {
  const { store, rerouteUrl, navBarOffset, productIBEMode, isWhitelabel } = props;
  const { t } = useTranslation('Checkout', fallback);
  const bs = useBookingService();
  const history = useHistory();
  const workflow = useContext(workflowContext);
  const [travelers, setTravelers] = useState<ApiTraveler[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const config = useConfig();

  useMount(async () => {
    if (config.outsideElementsContainerId) {
      ScrollerService.scrollTo(config.outsideElementsContainerId, { offset: -100 });
    }
  });

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    if (!!error) {
      timer = setTimeout(() => {
        setError(undefined);
      }, 10000);
    }
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [error]);

  const switchSessionData = (sessionToTemp: boolean) => {
    const copyFrom = sessionToTemp ? sessionStorage : sessionStorageTemp;
    const copyTo = sessionToTemp ? sessionStorageTemp : sessionStorage;
    const session = copyFrom.get();
    if (!!session) {
      copyTo.set(session);
      copyFrom.clear();
    }
  };

  const handleProgressbarClick = (step: CheckoutStep): void => {
    if (step.slug === 'packages') {
      switchSessionData(false);
    }
  };

  const goBack = async () => {
    history.push(CheckoutPageUrl.SUMMARY);
  };

  const next = async (): Promise<void> => {
    checkoutStepEvent.broadcast({
      booking: MaskBookingService.maskBooking(bs.booking),
      item: null
    });
    history.push(workflow.nextStepSlug || '');
  };

  const prev = async (): Promise<void> => {
    history.push(workflow.prevStepSlug || '');
  };

  const Nav = (): JSX.Element => {
    return (
      <OverlayContainer className="checkout-nav">
        <div>
          <HorizontalSpread>
            <Button color="secondary" className="d-none d-md-block" onClick={() => prev()}>
              {t(Keys.prev)}
            </Button>
            <Button
              color="primary"
              onClick={(): void => {
                next();
              }}
              className="d-none d-md-block"
              disabled={!!error}
            >
              {t(Keys.next)}
            </Button>
          </HorizontalSpread>
        </div>
        {bs.isBusy || (loading && <Overlay />)}
      </OverlayContainer>
    );
  };

  const excursionsHeadline = (): JSX.Element => {
    return (
      <>
        <h2 className="optional-offer__headline">{t(Keys.excursionsHeadline)}</h2>
        <div className="optional-offer__sub-headline">{t(Keys.excursionsSubHeadline)}</div>
      </>
    );
  };

  const extrasHeadline = (): JSX.Element => {
    return (
      <>
        <h2 className="optional-offer__headline">{t(Keys.extrasHeadline)}</h2>
        <div className="optional-offer__sub-headline">{t(Keys.extrasSubHeadline)}</div>
      </>
    );
  };

  return (
    <div className="checkout-workflow">
      <div className="d-flex justify-content-between mb-4 checkout-nav">
        <Button color="secondary" className="d-block d-md-none" onClick={() => goBack()}>
          {t(Keys.back)}
        </Button>
      </div>
      <ProgressbarWrapper
        onClick={handleProgressbarClick}
        store={store}
        rerouteUrl={rerouteUrl}
        isWhitelabel={isWhitelabel}
      />
      <Row>
        <Col md={8} sm={12} className=" traveler-sticky-main-content">
          <div className="checkout_main_container">
            <div className="checkout_main_container--shadow">
              <OverlayContainer>
                <FlightAndAccommodationSummaryHeader store={store} />
                {productIBEMode ? (
                  <ContentContainer className="checkout__extras">
                    <Row>
                      <Col>
                        {!!error ? (
                          <ErrorWrapper error={error} scrollElementError={scrollElementError} />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <OptionalOffers
                      components={
                        !!bs.packageCart && bs.packageCart.packageModel.packageDetails
                          ? bs.packageCart.packageModel.packageDetails[0].components
                          : []
                      }
                      extrasHeadContent={
                        store?.hotelInformation?.additionalServices === 'extras'
                          ? [extrasHeadline(), excursionsHeadline()]
                          : [excursionsHeadline(), extrasHeadline()]
                      }
                      extrasGroupOrder={
                        store?.hotelInformation?.additionalServices === 'extras'
                          ? ['extras', 'attractions']
                          : ['attractions', 'extras']
                      }
                      isLoading={bs.isBusy}
                    />
                  </ContentContainer>
                ) : (
                  <></>
                )}
                {(bs.isBusy || loading) && <Overlay />}
              </OverlayContainer>
            </div>
            <Nav />
          </div>
        </Col>
        <Col sm={12} md={4} className="traveler-sticky-summary-wrapper">
          {bs.booking && (
            <StickySummary
              loading={bs.isBusy}
              booking={bs.booking}
              next={next}
              disabled={false}
              navBarOffset={navBarOffset}
              productIBEMode={productIBEMode}
              travelers={travelers}
              showPromotionCode
              isWhitelabel={isWhitelabel}
            />
          )}
        </Col>
      </Row>
    </div>
  );
});

export default Extras;
