import React, { useState } from 'react';
import { ApiHotel, ApiMetaInfo } from '@ibe/api';
import Carousel from 'react-multi-carousel';
import HotelDisplay from '../../../HotelDisplay';
import Keys from '../../../../../../Translations/generated/Checkout.de.json.keys';
import { useTranslation } from '@ibe/components';
import fallback from '../../../../../../Translations/generated/Checkout.de.json';

type ApiHotelExtended = ApiHotel & {
  metaInfo?: ApiMetaInfo & {
    contentByMetaType?: {
      accommodation?: {
        content?: {
          tileDescription?: string;
          hotelName?: string;
          more?: string;
        };
      };
    };
  };
};

interface Props {
  hotels: ApiHotelExtended[];
  selectedHotel?: ApiHotel;
  initialHotel: ApiHotel;
  onChange: (selectedHotel: ApiHotel) => void;
}

const HotelCarouselComponent = (props: Props): JSX.Element => {
  const { hotels, selectedHotel, initialHotel, onChange } = props;
  const { t } = useTranslation('Checkout', fallback);
  const [hotelModalOpen, setHotelModalOpen] = useState(false);

  const toggleHotelOpen = (isOpened: boolean) => setHotelModalOpen(isOpened);

  const LeftArrow = (arrowProps: { onClick?: () => void }) => {
    const { onClick } = arrowProps;
    return (
      <div
        className="swiper-button swiper-button-prev"
        role="button"
        tabIndex={0}
        aria-label={t(Keys.sliderPreviousHotel)}
        onClick={() => onClick && onClick()}
      />
    );
  };

  const RightArrow = (arrowProps: { onClick?: () => void }) => {
    const { onClick } = arrowProps;
    return (
      <div
        className="swiper-button swiper-button-next"
        role="button"
        tabIndex={0}
        aria-label={t(Keys.sliderPreviousHotel)}
        onClick={() => onClick && onClick()}
      />
    );
  };

  return (
    !!hotels && (
      <Carousel
        arrows
        centerMode={false}
        containerClass="carousel-slides-container py-2"
        customLeftArrow={<LeftArrow />}
        customRightArrow={<RightArrow />}
        focusOnSelect={false}
        responsive={{
          // "Buchungsübersicht" col hidden
          mobileXS: {
            breakpoint: {
              max: 575,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          mobileSM: {
            breakpoint: {
              max: 767,
              min: 576
            },
            items: 2,
            partialVisibilityGutter: 30
          },
          // "Buchungsübersicht" col displayed
          tablet: {
            breakpoint: {
              max: 991,
              min: 768
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          desktopLG: {
            breakpoint: {
              max: 1199,
              min: 992
            },
            items: 2,
            partialVisibilityGutter: 40
          },
          desktopXL: {
            breakpoint: {
              max: 3000,
              min: 1200
            },
            items: 2,
            partialVisibilityGutter: 40
          }
        }}
        swipeable={!hotelModalOpen}
        draggable={!hotelModalOpen}
        keyBoardControl={!hotelModalOpen}
      >
        {hotels.map(hotel => (
          <div className="d-flex col" key={hotel.id}>
            <HotelDisplay
              hotel={hotel}
              selectedHotel={selectedHotel}
              initialHotel={initialHotel}
              onChange={onChange}
              modalStateChanged={toggleHotelOpen}
            />
          </div>
        ))}
      </Carousel>
    )
  );
};

export default HotelCarouselComponent;
