import React from 'react';
import { CollapsibleWizardProvider, useCollapsibleWizard, Props } from './ContextAndProvider';

const CollapsibleWizardWithProvider = ({
  children,
  ...props
}: Props & { children?: React.ReactNode }) => {
  return (
    <CollapsibleWizardProvider {...props}>
      <>
        <CollapsibleWizard />
        {children ? children : null}
      </>
    </CollapsibleWizardProvider>
  );
};

export default CollapsibleWizardWithProvider;

const CollapsibleWizard = () => {
  const { partitionedItems } = useCollapsibleWizard();
  return (
    <>
      {partitionedItems.map((partitionedItem, index) => {
        if (partitionedItem[0].type === 'SEPARATOR') {
          return (
            <div key={index} className={`collapsible__wizard--separator `}>
              {partitionedItem.map(
                ({ component: Component, hide }, idx) => !hide && <Component idx={idx} key={idx} />
              )}
            </div>
          );
        }
        if (partitionedItem[5]) {
          partitionedItem[5].hide = false;
        }

        return (
          <div key={index} className={`collapsible__wizard `}>
            {partitionedItem.map(
              (item, idx) =>
                !item.hide && (
                  <CollapsibleWizardItem idx={item.itemIndex || idx} {...item} key={idx} />
                )
            )}
          </div>
        );
      })}
    </>
  );
};

const CollapsibleWizardItem = React.memo(
  ({ component: Component, idx }: Props['items'][0] & { idx: number }) => {
    return (
      <div className="collapsible__wizard__item">
        <div className={`collapsible__wizard__transition`} style={{ animationDuration: '350ms' }}>
          <Component idx={idx} />
        </div>
      </div>
    );
  }
);

export { CollapsibleWizard as CollapsibleWizardWithoutProvider };
