import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ApiDurationType, ApiItemType, ApiListOptions, ApiSortOption } from '@ibe/api';
import {
  BestPriceAvailabilitySearchStore,
  BestPriceAvailabilityStore,
  DateInputType,
  PackageDetailsSelection,
  PackageModule,
  PackageParams,
  PackageSearchStore,
  PackageStore,
  useConfig,
  useMediaQuery,
  useTranslation
} from '@ibe/components';
import { encodeObjectasUrlParam } from '@ibe/services';
import PageLayout from '../../Layouts/PageLayout';
import PageUrl from '../../Models/PageUrl';
import { ListOptionsParamsTransformer } from '../Params/ListOptionsParamsTransformer';
import useQuery from '../Util/useQuery';
import CustomizerParamsTransformer from './CustomizerParams/CustomizerParamsTransformer';
import PackageParamsTransformer from './PackageParams/PackageParamsTransformer';
import CheckoutPageUrl from '../Checkout/CheckoutPageUrl';
import fallback from '../../Translations/generated/PackageSearch.de.json';
import Keys from '../../Translations/generated/PackageSearch.de.json.keys';
import renderListItem, {
  bestPriceRenderSearchContent,
  CustomIndexedDestinationSelect,
  renderSearch,
  renderSearchContent
} from './render';
import useUrlPersistence from '../Util/useUrlPersistence';
import dayjs from 'dayjs';
import { clearStorage } from '../Util/utils';
const yOffset = -200;

export type RefsType = {
  containerRef?: RefObject<HTMLDivElement>;
  stickyRef?: RefObject<HTMLDivElement>;
};
export type IsStickyListenerType = (a: {
  isSticky: boolean;
  refs: RefsType;
  setSearchCollapsed: (a: boolean) => void;
}) => void;
export type ChangeType = 'duration' | 'Date' | 'participants' | 'origins' | 'destination';

const PackagesPage = observer(() => {
  const [exactSearchIsSticky, setExactSearchIsSticky] = useState(false);
  const stickySearchRef = useRef<HTMLElement | undefined | null>();

  const history = useHistory();
  const config = useConfig();
  const query = useQuery();
  const { t } = useTranslation('PackageSearch', fallback);
  const sessionPersistence = useUrlPersistence();

  const onSelect = (params: PackageDetailsSelection): void => {
    const searchParams = encodeObjectasUrlParam(
      CustomizerParamsTransformer.encode({
        itemId: params.packageId,
        packageDetailId: params.detailId,
        travelDateId: params.travelDateId
      })
    );
    clearStorage();
    history.push(`${PageUrl.CHECKOUT}?${searchParams}`);
  };

  const updateUrlParams = (params: PackageParams, listOptions: ApiListOptions): void => {
    const queryParams = PackageParamsTransformer.encode(params);
    const listOptionsParams = ListOptionsParamsTransformer.encode(listOptions);
    const url = `${history.location.pathname}?${queryParams}&${listOptionsParams}`;
    sessionPersistence.set({ url });
    history.push(url);
  };

  const updateUrlParamsForSearch = (params: PackageParams, listOptions: ApiListOptions): void => {
    if (params.isFromSearchButton === true) {
      params.goToDetails = true;
    }
    updateUrlParams(params, listOptions);
  };

  const searchParams = useMemo((): Partial<PackageParams> => {
    const queryParams = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    if (queryParams) queryParams.dateInputType = DateInputType.FULL;
    return queryParams;
  }, [query]);

  const onBestPriceAvailabilitySelect = (): void => {
    history.push(CheckoutPageUrl.SUMMARY);
  };

  const bestPriceIsStickyListener = useCallback<IsStickyListenerType>(
    ({ isSticky, setSearchCollapsed }) => {
      if (isSticky) {
        if (stickySearchRef.current) stickySearchRef.current.classList.add('d-none');
        setSearchCollapsed(true);
      } else {
        if (stickySearchRef.current) stickySearchRef.current.classList.remove('d-none');
        setSearchCollapsed(false);
      }
      setExactSearchIsSticky(isSticky);
    },
    [exactSearchIsSticky]
  );

  const isStickyListener = useCallback<IsStickyListenerType>(({ refs: { stickyRef } }) => {
    if (!stickySearchRef.current) {
      stickySearchRef.current = stickyRef?.current;
    }
  }, []);

  const onBestPriceDurationChange = (
    store?: BestPriceAvailabilitySearchStore,
    bestPriceStore?: BestPriceAvailabilityStore
  ) => {
    const actualBestPriceSearchFullEndDate = store?.fullEndDate;
    const actualBestPriceSearchFullStartDate = store?.fullStartDate;

    if (actualBestPriceSearchFullStartDate && actualBestPriceSearchFullEndDate) {
      const numberDayBetweenStartAndEndDate = dayjs(actualBestPriceSearchFullEndDate).diff(
        dayjs(actualBestPriceSearchFullStartDate),
        'days'
      );

      const duration = store?.duration;
      if (duration && duration > numberDayBetweenStartAndEndDate) {
        bestPriceStore?.setNoticeWarning(
          'BEST_PRICE_DURATION_CHANGE',
          t(Keys.noticeBestPriceEndDateChange)
        );
      } else {
        if (bestPriceStore?.noticeWarning.BEST_PRICE_DURATION_CHANGE)
          bestPriceStore?.setNoticeWarning('BEST_PRICE_DURATION_CHANGE', null);
      }
    }
  };

  const onBestPriceSearchChange = (a: {
    bestPriceStore?: BestPriceAvailabilityStore;
    bestPriceSearchStore?: BestPriceAvailabilitySearchStore;
    packageStore?: PackageStore;
    changeType: ChangeType;
  }) => {
    if (a.changeType === 'duration' || a.changeType === 'Date')
      return onBestPriceDurationChange(a.bestPriceSearchStore, a.bestPriceStore);
  };

  const bestPriceStickyOffset = useMediaQuery({ query: '576', type: 'max' }) ? 270 : 0;

  const onPageChangeListener = useCallback(() => {
    const { outsideElementsContainerId } = config;
    const findContainer = document.getElementById(outsideElementsContainerId || 'iso');
    const y = (findContainer?.getBoundingClientRect().top || 0) + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }, []);

  return (
    <PageLayout>
      <PackageModule
        onSelect={onSelect}
        onSearch={updateUrlParamsForSearch}
        onDetail={updateUrlParams}
        searchParams={searchParams}
        showLabels
        subType={ApiItemType.PREDEFINEDPACKAGE}
        showMultipleDurations={false}
        showTotalPriceOnly
        destinationMandatory
        endDateMandatory
        withDateRangePicker={true}
        withGroupRequestForm={false}
        withRoomCountSelector={false}
        renderSearch={renderSearch}
        renderSearchContent={renderSearchContent}
        sortingType={{
          GROUP: [],
          SINGLE: [ApiSortOption.NAME, ApiSortOption.PRICE]
        }}
        sorting={[ApiSortOption.NAME, ApiSortOption.PRICE]}
        minDateRangeSpan={7}
        maxDateRangeSpan={200}
        renderListItem={renderListItem}
        doBestPriceSearch={true}
        bestPriceMaxDateRangeSpan={42}
        onBestPriceAvailabilitySelect={onBestPriceAvailabilitySelect}
        minSelectableChildAge={2}
        noDatePickerOverflow
        selectLabelSort
        desktopSearchCollapse
        stickySearch
        withChildrenSelection={false}
        onlyAdultSelect
        changeOnBlur
        bestPriceStickySearch
        bestPriceRenderSearchContent={bestPriceRenderSearchContent}
        bestPriceIsStickyListener={bestPriceIsStickyListener}
        isStickyListener={isStickyListener}
        widgetClickable={true}
        onBestPriceSearchChange={onBestPriceSearchChange}
        bestPriceStickyOffset={bestPriceStickyOffset}
        packageOriginMustBeSet={false}
        packageOriginWithRemoveAll
        customIndexedDestinationSelect={(store: PackageSearchStore) => (
          <CustomIndexedDestinationSelect store={store} />
        )}
        dateRangeDurationProps={{
          withDuration: true,
          value: { duration: 7, type: ApiDurationType.DAY }
        }}
        bestPriceSubmitOnChange
        hideSearchInDetails
        paginationProps={{
          onPageChangeListener
        }}
      />
    </PageLayout>
  );
});

export default PackagesPage;
