import { ApiProductCard } from '../../api';
import ProductItemListEvent from './productItemListEvent';
import {
  ApiBestPricePackageModel,
  ApiBooking,
  ApiCustomer,
  ApiItem,
  ApiItemType,
  ApiPriceModifierType
} from '@ibe/api';
import { PackageModelId } from '@ibe/components';
import dayjs from 'dayjs';
import { PREVIOUS_ITEM_LIST_ID, PREVIOUS_ITEM_LIST_NAME } from '../Util/sessionStorageHelper';
import { TrackingEvents } from './events';
import { TravelDetailsEvent } from '@ibe/services';

type DataLayer = {
  event: string;
  ecommerce: Ecommerce;
  step?: string;
};
type WindowWithDataLayer = Window & {
  dataLayer: DataLayer[];
};

declare const window: WindowWithDataLayer;

class TrackingService {
  private readonly itemBrandName = 'trendtours';

  private readonly affiliationName = this.itemBrandName + ' Online Store';

  private readonly daysStr = ' Tage';

  private readonly travelYearStr = 'Reisejahr ';

  trackProductListView(payload: ProductItemListEvent, eventName: string): void {
    const data = this.mapProductsToItems(payload.items);
    this.pushToWindowDataLayer(eventName, data);
  }

  trackPackageListView(payload: ApiBestPricePackageModel[], eventName: string): void {
    const previousItemListId = localStorage.getItem(PREVIOUS_ITEM_LIST_ID);
    const previousItemListName = localStorage.getItem(PREVIOUS_ITEM_LIST_NAME);
    const items: Item[] = [];
    payload.forEach((it, index) => {
      const item: Item = this.mapItem(it, index);
      item.item_list_id = previousItemListId ?? '';
      item.item_list_name = previousItemListName ?? '';
      items.push(item);
    });
    const data: Ecommerce = {
      item_list_id: previousItemListId ?? '',
      item_list_name: previousItemListName ?? '',
      items
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackItem(payload: ApiItem, eventName: string): void {
    const item = this.mapItem(payload);
    const data: Ecommerce = {
      currency: payload.price.currencyCode,
      value: payload.price.finalPrice,
      items: [item]
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  beginCheckout(payload: ApiBooking | null): void {
    if (payload?.bookedItems) {
      const hasCheckoutStarted = this.getLatestDataLayer(TrackingEvents.BEGIN_CHECKOUT);
      if (!hasCheckoutStarted) {
        const data = this.mapBookedItems(payload);
        this.pushToWindowDataLayer(TrackingEvents.BEGIN_CHECKOUT, data);
      }
    }
  }

  private mapTravelDetails(payload: TravelDetailsEvent): Ecommerce | undefined {
    if (payload.booking?.bookedItems) {
      const mappedBookedItemsEcommerce = this.mapBookedItems(payload.booking);
      const bookedItems = payload.booking.bookedItems;
      const packageItem = bookedItems.find(it => it.itemType === ApiItemType.PACKAGE);
      let flightDepartureAirport;
      const flightItem = bookedItems.find(it => it.itemType === ApiItemType.FLIGHT);

      if (flightItem?.idParent) {
        const decodedIdParent = JSON.parse(atob(flightItem.idParent));
        const decodedIdParentId = JSON.parse(atob(decodedIdParent.id));
        flightDepartureAirport = decodedIdParentId.startingPointCode;
      }

      mappedBookedItemsEcommerce.items = [
        {
          ...mappedBookedItemsEcommerce.items[0],
          travel_start_date: packageItem?.startDate,
          travel_end_date: payload.booking.bookedItems[0].endDate,
          travel_duration: payload.travel_duration,
          flight_boarding: flightDepartureAirport,
          amount_travellers: payload.booking?.travelers.length,
          hotel: undefined,
          amount_rooms: undefined,
          hotel_meals: undefined
        }
      ];
      return mappedBookedItemsEcommerce;
    }
  }

  addTravelDetails(payload: TravelDetailsEvent, step?: string): void {
    const latestEvent = this.getLatestDataLayer(TrackingEvents.ADD_TRAVEL_DETAILS);
    // Default travel details within the booking
    const mappedTravelDetails = this.mapTravelDetails(payload);
    let updatedData: Ecommerce | undefined;

    if (latestEvent) {
      const latestEventItem = latestEvent.ecommerce.items[0];
      // Determine which value has changed
      for (const payloadKey in payload) {
        const travelDetailKey = payloadKey as keyof TravelDetailsEvent;
        for (const latestEventKey in latestEventItem) {
          const itemKey = latestEventKey as keyof Item;
          if (
            latestEventKey === payloadKey &&
            payload[travelDetailKey] &&
            latestEventItem[itemKey] !== payload[travelDetailKey]
          ) {
            updatedData = latestEvent.ecommerce;
            updatedData.items = [
              {
                ...updatedData.items[0],
                [travelDetailKey]: payload[travelDetailKey]
              }
            ];
          }
        }
      }
    }
    // Push will happen either when the data is updated, the step changes or if the event doesn't exist
    if (updatedData || latestEvent?.step !== step) {
      const ecommerce = updatedData ?? latestEvent?.ecommerce ?? mappedTravelDetails;
      this.pushToWindowDataLayer(
        TrackingEvents.ADD_TRAVEL_DETAILS,
        ecommerce ?? { items: [] },
        step
      );
    }
  }

  checkoutStep(step?: string): void {
    const latestEvent =
      this.getLatestDataLayer(TrackingEvents.CHECKOUT_STEP) ||
      this.getLatestDataLayer(TrackingEvents.ADD_ADDITIONAL_SERVICES) ||
      this.getLatestDataLayer(TrackingEvents.ADD_TRAVEL_DETAILS);
    if (latestEvent) {
      // Deleting those, that are not needed for this event
      delete latestEvent.ecommerce.service_name;
      delete latestEvent.ecommerce.service_type;
      this.pushToWindowDataLayer(TrackingEvents.CHECKOUT_STEP, latestEvent?.ecommerce, step);
    }
  }

  additionalServices(
    service_type: string,
    service_name: string,
    additional_service: string,
    remove?: boolean
  ): void {
    const latestEvent =
      this.getLatestDataLayer(TrackingEvents.ADD_ADDITIONAL_SERVICES) ||
      this.getLatestDataLayer(TrackingEvents.CHECKOUT_STEP) ||
      this.getLatestDataLayer(TrackingEvents.ADD_TRAVEL_DETAILS);

    if (latestEvent) {
      latestEvent.ecommerce.service_type = service_type;
      latestEvent.ecommerce.service_name = service_name;
      let currentAdditionalServices = latestEvent.ecommerce.items[0].additional_services || [];
      if (remove) {
        currentAdditionalServices = currentAdditionalServices?.filter(
          service => service !== additional_service
        );
      } else {
        currentAdditionalServices?.push(additional_service);
      }
      latestEvent.ecommerce.items = [
        {
          ...latestEvent.ecommerce.items[0],
          additional_services: currentAdditionalServices
        }
      ];
      this.pushToWindowDataLayer(TrackingEvents.ADD_ADDITIONAL_SERVICES, latestEvent.ecommerce);
    }
  }

  purchase(payload: ApiBooking | null): void {
    const latestEvent =
      this.getLatestDataLayer(TrackingEvents.CHECKOUT_STEP) ||
      this.getLatestDataLayer(TrackingEvents.ADD_ADDITIONAL_SERVICES) ||
      this.getLatestDataLayer(TrackingEvents.ADD_TRAVEL_DETAILS);

    if (payload && latestEvent) {
      const latestEcommerceObject = latestEvent.ecommerce;
      const tax = payload.price.modifiers.find(pr => pr.type === ApiPriceModifierType.TAX)
        ?.absolute;
      latestEcommerceObject.transaction_id = +payload.bookingNumber;
      latestEcommerceObject.tax = tax;

      const client = payload?.client as ApiCustomer;
      latestEcommerceObject.first_name = client.firstName;
      latestEcommerceObject.last_name = client.lastName;
      latestEcommerceObject.postal_code = client.address.postalCode;
      latestEcommerceObject.country = client.address.countryCode;
      latestEcommerceObject.email = client.communicationDetails.email;
      latestEcommerceObject.phone = client.communicationDetails.phone;
      this.pushToWindowDataLayer(TrackingEvents.PURCHASE, latestEvent.ecommerce);
    }
  }

  private getItemCategory(typeCode?: string): string {
    switch (typeCode) {
      case 'SB':
        return 'Sonnenhits';
      case 'PFLUG':
        return 'Flugreise';
      default:
        return '';
    }
  }

  private mapItem(packageItem: Partial<ApiBestPricePackageModel>, index = 0): Item {
    const code = packageItem.code;
    const name = packageItem.description ? packageItem.description : packageItem.name;

    // TODO: Check price & discount
    const priceModifiers = packageItem.price?.modifiers?.find(
      pr => pr.type === ApiPriceModifierType.DISCOUNT
    );
    let coupon = '';
    if (packageItem?.id) {
      const decodeId = JSON.parse(atob(packageItem.id));
      coupon = decodeId.promotionCode ? decodeId.promotionCode : '';
    }
    let discount = priceModifiers?.absolute;
    discount = discount ? Math.abs(discount) : 0;

    const itemCategory = this.getItemCategory(packageItem.typeCode);
    const itemCategory2 = packageItem?.geoAssignment?.geoUnit?.name;
    const endDate = dayjs(packageItem.endDate);
    const itemCategory3 = this.travelYearStr + endDate.year();

    // TODO: check if shown duration matches the selected package (e.g.: '15-Tägige Reise' --> 15)
    const duration = packageItem.duration?.duration + this.daysStr;
    const price = packageItem.price?.finalPrice;

    return {
      item_id: code,
      item_name: name,
      affiliation: this.affiliationName,
      coupon: coupon,
      discount: discount,
      index: index,
      item_brand: this.itemBrandName,
      item_category: itemCategory,
      item_category2: itemCategory2,
      item_category3: itemCategory3,
      item_variant: duration,
      price: price,
      quantity: 1
    };
  }

  private mapPackageItem(payload: ApiBooking | null): Item | undefined {
    const bookedItems = payload?.bookedItems;

    const packageItem = bookedItems?.find(it => it.itemType === ApiItemType.PACKAGE);
    if (packageItem) {
      const item: Item = {};
      const decodedPackageId = packageItem.id ? JSON.parse(atob(packageItem.id)) : undefined;

      let decodedPackageModelId: Partial<PackageModelId>;
      if (decodedPackageId) {
        decodedPackageModelId = JSON.parse(atob(decodedPackageId.packageModelId));
        const typeCode = decodedPackageModelId.typeCode;
        item.item_id = decodedPackageModelId?.code;
        item.item_category = this.getItemCategory(typeCode);
      }
      item.item_name = packageItem.name;
      const endDate = dayjs(packageItem.endDate);
      item.item_variant = this.travelYearStr + endDate.year();
      return item;
    }
  }

  private mapBookedItems(booking: ApiBooking | null): Ecommerce {
    const packageItem = this.mapPackageItem(booking);
    const coupon = booking?.promoCodes[0];
    let discount = booking?.totalDiscount?.finalPrice;
    discount = discount ? Math.abs(discount) : 0;

    const previousItemListId = localStorage.getItem(PREVIOUS_ITEM_LIST_ID);
    const previousItemListName = localStorage.getItem(PREVIOUS_ITEM_LIST_NAME);

    const item: Item[] = [
      {
        ...packageItem,
        affiliation: this.affiliationName,
        coupon: coupon,
        discount: discount,
        index: 0,
        item_brand: this.itemBrandName,
        item_list_id: previousItemListId ?? '',
        item_list_name: previousItemListName ?? '',
        price: booking?.price.finalPrice,
        quantity: 1
      }
    ];

    const currency = booking?.price.currencyCode;
    const value = booking?.price.finalPrice;

    return {
      currency: currency,
      value: value,
      checkout_type: 'iso',
      coupon: coupon,
      items: item
    };
  }

  private mapProductsToItems(payload: ApiProductCard[]): Ecommerce {
    return {
      items: payload.map((card, index) => {
        const price =
          card.strikeThroughPrice && card.strikeThroughPrice.amount
            ? card.strikeThroughPrice
            : card.price;

        const name = this.replaceHtmlAndUmlauts(card.name);
        const seasonName = this.replaceHtmlAndUmlauts(card.seasonName);
        const previousItemListId = localStorage.getItem(PREVIOUS_ITEM_LIST_ID);
        const previousItemListName = localStorage.getItem(PREVIOUS_ITEM_LIST_NAME);
        const item: Item = {
          item_id: card.code,
          item_name: name,
          affiliation: this.affiliationName,
          coupon: card.selectedPromotionCode || '',
          discount: card.selectedPromotionCode ? parseFloat(card.selectedPromotionValue) : '',
          index,
          item_brand: this.itemBrandName,
          item_category: card.productTypes.join(', '),
          item_category2: card.country.name,
          item_category3: seasonName || '',
          price: price.amount,
          quantity: 1,
          item_list_id: previousItemListId ?? '',
          item_list_name: previousItemListName ?? ''
        };
        return item;
      })
    };
  }

  private pushToWindowDataLayer(eventName: string, data: Ecommerce, step?: string): void {
    if (step) {
      window.dataLayer.push({
        event: eventName,
        step: step,
        ecommerce: data
      });
    } else {
      window.dataLayer.push({
        event: eventName,
        ecommerce: data
      });
    }
  }

  private getLatestDataLayer(eventName: string): DataLayer | undefined {
    const existingEventIndex = window.dataLayer.filter(event => event.event === eventName);
    if (existingEventIndex.length > 0) {
      return existingEventIndex.at(-1);
    }
  }

  private replaceHtmlAndUmlauts(input: string) {
    if (!input) {
      return input;
    }
    let output = this.clearHTMLTags(input);
    output = output.replaceAll('&auml;', 'ä');
    output = output.replaceAll('&Auml;', 'Ä');
    output = output.replaceAll('&ouml;', 'ö');
    output = output.replaceAll('&Ouml;', 'Ö');
    output = output.replaceAll('&uuml;', 'ü');
    output = output.replaceAll('&Uuml;', 'Ü');
    return output.trim();
  }

  private clearHTMLTags = (input: string) => {
    const output = new DOMParser().parseFromString(input, 'text/html');
    return output.body.textContent || '';
  };
}

export default TrackingService;
