import React, { Dispatch, RefObject, useCallback, useEffect, useState, JSX } from 'react';
import { Element as ScrollTo } from 'react-scroll';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Keys from '../../../Translations/generated/Checkout.de.json.keys';
import fallback from '../../../Translations/generated/Checkout.de.json';
import { BookingService } from '@ibe/services';
import { ApiBaseData, ApiTraveler, ApiTravelerType } from '@ibe/api';
import { CustomInput, FormBuilder, FormRef, useConfig, useTranslation } from '@ibe/components';
import ContentContainer from '../../../Components/ContentContainer';
import { FormItemsProps } from '../../../Config/useTravellerFormConfigProductIBE';
import { getFormConfig, getSalutations } from './externals';
import { scrollElementErrorTravelerBase } from './Traveler';
import getTravelerMockData from '../../../Config/mockData';

interface Props {
  travelers: ApiTraveler[];
  setTravelers: Dispatch<React.SetStateAction<ApiTraveler[]>>;
  bs: BookingService;
  sameAddressForms: ApiTraveler[];
  setSameAddressForms: Dispatch<React.SetStateAction<ApiTraveler[]>>;
  formRefs: RefObject<FormRef<Record<string, unknown>>[]>;
  externalData: {
    COUNTRIES: ApiBaseData[];
    SALUTATIONS: ApiBaseData[];
    NATIONALITIES: ApiBaseData[];
  };
  initialValues?: Array<{ [key: string]: string }>;
}

const TravelerForms = (props: Props): JSX.Element => {
  const {
    travelers,
    setTravelers,
    bs,
    sameAddressForms,
    setSameAddressForms,
    formRefs,
    externalData,
    initialValues
  } = props;
  const { t } = useTranslation('Checkout', fallback);
  const config = useConfig();

  const [initialMockValues, setInitialMockValues] = useState<Record<string, unknown>[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (!!initialMockValues) {
      formRefs.current.map((ref: FormRef<Record<string, unknown>>, idx: number) => {
        ref?.resetForm(initialMockValues[idx] || initialMockValues[0]);
      });
    }
  }, [initialMockValues]);

  const handleCheckboxChange = (formItem: ApiTraveler): void => {
    setSameAddressForms(value =>
      value.find(form => form.id === formItem.id)
        ? value.filter(form => form.id !== formItem.id)
        : [...value, formItem]
    );
  };

  const onFormFieldsChange = useCallback(
    (values: Record<string, string>, id: string) => {
      const formValues = values as FormItemsProps;
      const changedTraveler = travelers.find(traveler => traveler.id === id);

      if (!!changedTraveler && changedTraveler.title !== formValues.title) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            title: traveler.id === id ? formValues.title : traveler.title
          }))
        );
      }
      if (!!changedTraveler && changedTraveler.firstName !== formValues.firstname) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            firstName: traveler.id === id ? formValues.firstname : traveler.firstName
          }))
        );
      }
      if (!!changedTraveler && changedTraveler.lastName !== formValues.lastname) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            lastName: traveler.id === id ? formValues.lastname : traveler.lastName
          }))
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.birthDate &&
        changedTraveler.birthDate !== formValues.birthDate
      ) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            birthDate: traveler.id === id ? formValues.birthDate : traveler.birthDate
          }))
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.salutation &&
        changedTraveler.salutation !== formValues.salutation
      ) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            salutation: traveler.id === id ? formValues.salutation : traveler.salutation
          }))
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.nationality &&
        changedTraveler.nationality !== formValues.nationality
      ) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            nationality: traveler.id === id ? formValues.nationality : traveler.nationality
          }))
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.city &&
        changedTraveler.address?.city !== formValues.city
      ) {
        setTravelers(value =>
          value.map(traveler => {
            return traveler.id === id && !!formValues.city
              ? {
                  ...traveler,
                  address: {
                    ...traveler?.address,
                    countryCode: traveler.address?.countryCode || '',
                    postalCode: traveler.address?.postalCode || '',
                    street: traveler.address?.street || '',
                    zipCode: traveler.address?.postalCode || '',
                    city: formValues.city || ''
                  }
                }
              : { ...traveler };
          })
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.country &&
        changedTraveler.address?.countryCode !== formValues.country
      ) {
        setTravelers(value =>
          value.map(traveler => {
            return traveler.id === id && !!formValues.country
              ? {
                  ...traveler,
                  address: {
                    ...traveler?.address,
                    city: traveler.address?.city || '',
                    postalCode: traveler.address?.postalCode || '',
                    street: traveler.address?.street || '',
                    zipCode: traveler.address?.postalCode || '',
                    countryCode: formValues.country || ''
                  }
                }
              : { ...traveler };
          })
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.street &&
        changedTraveler.address?.street !== formValues.street
      ) {
        setTravelers(value =>
          value.map(traveler => {
            return traveler.id === id && !!formValues.street
              ? {
                  ...traveler,
                  address: {
                    ...traveler?.address,
                    city: traveler.address?.city || '',
                    countryCode: traveler.address?.countryCode || '',
                    postalCode: traveler.address?.postalCode || '',
                    zipCode: traveler.address?.postalCode || '',
                    street: formValues.street || ''
                  }
                }
              : { ...traveler };
          })
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.zipCode &&
        changedTraveler.address?.postalCode !== formValues.zipCode
      ) {
        setTravelers(value =>
          value.map(traveler => {
            return traveler.id === id && !!formValues.zipCode
              ? {
                  ...traveler,
                  address: {
                    ...traveler?.address,
                    city: traveler.address?.city || '',
                    countryCode: traveler.address?.countryCode || '',
                    street: traveler.address?.street || '',
                    zipCode: traveler.address?.postalCode || '',
                    postalCode: formValues.zipCode || ''
                  }
                }
              : { ...traveler };
          })
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.email &&
        changedTraveler.communicationDetails?.email !== formValues.email
      ) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            communicationDetails: {
              ...traveler.communicationDetails,
              email: traveler.id === id ? formValues.email : traveler.communicationDetails?.email
            }
          }))
        );
      }
      if (
        !!changedTraveler &&
        !!formValues.phone &&
        changedTraveler.communicationDetails?.phone !== formValues.phone
      ) {
        setTravelers(value =>
          value.map(traveler => ({
            ...traveler,
            communicationDetails: {
              ...traveler.communicationDetails,
              phone: traveler.id === id ? formValues.phone : traveler.communicationDetails?.phone
            }
          }))
        );
      }
    },
    [travelers]
  );

  return (
    <ContentContainer className={`travellerData ibeFlightClassName`}>
      <div className="traveler-header font-weight-bold mb-4">{t(Keys.fillInParticipantsData)}</div>
      <div className="info-name-badge mb-4">
        <FontAwesomeIcon icon={faInfoCircle} />
        <div>{t(Keys.customerNameInfo)}</div>
      </div>
      {config?.traveler?.mockAvailable && (
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            className="mb-4 mr-3"
            onClick={(): void => {
              setInitialMockValues(
                [...Array(travelers.length).keys()].map(() => getTravelerMockData())
              );
            }}
          >
            Fill with mock data
          </Button>
        </div>
      )}
      {travelers.map((item, idx) => {
        const personIndex =
          (travelers
            .filter(localItem => localItem.type === item.type)
            .findIndex(localItem => localItem.id === item.id) || 0) + 1;
        const isFirstAdult = personIndex === 1 && item.type === ApiTravelerType.ADULT;
        const useFirstAddress = !!sameAddressForms.find(form => form.id === item.id);

        return (
          <ScrollTo key={item.id} name={scrollElementErrorTravelerBase + idx}>
            <div className="mb-4" key={item.id}>
              <div className="form-module__headline">
                <Row>
                  <Col lg={isFirstAdult ? 5 : 12}>
                    <div>
                      {`${personIndex}. ${t((Keys as Record<string, string>)[item.type])}`}&nbsp;
                      {isFirstAdult ? (
                        <span className="form-module__headline--suffix">({t(Keys.customer)})</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                  {isFirstAdult && (
                    <Col lg={7}>
                      <p>{t(Keys.customerInfo)}</p>
                    </Col>
                  )}
                </Row>
              </div>
              <FormBuilder
                key={item.id}
                formConfig={getFormConfig(
                  useFirstAddress,
                  idx === 0,
                  !!initialValues ? initialValues[idx] : undefined,
                  config
                )}
                onFormFieldsChange={(values: Record<string, string>) =>
                  onFormFieldsChange(values, item.id)
                }
                // eslint-disable-next-line
                // @ts-ignore
                ref={(ref: FormRef<Record<string, unknown>> | null): void => {
                  if (ref) {
                    formRefs.current[idx] = ref;
                  }
                }}
                hideSubmitButton
                scrollToError
                initialValues={{ id: item.id }}
                externalData={{
                  countries: externalData.COUNTRIES,
                  salutations: getSalutations(externalData.SALUTATIONS, item.type),
                  nationalities: externalData.NATIONALITIES,
                  hideFields: !!sameAddressForms.find(form => form.id === item.id),
                  isFirstAdult: isFirstAdult,
                  yupContext: {
                    adultMinAge: config.traveler.maxChildAge + 1,
                    childMinAge: 0,
                    startDate: bs.booking?.travelStartDate
                  }
                }}
                mode="onChange"
                reValidateMode="onChange"
              >
                <div />
                {!isFirstAdult && (
                  <div>
                    <CustomInput
                      type="checkbox"
                      onChange={(): void => handleCheckboxChange(item)}
                      checked={useFirstAddress}
                      label={t(Keys.sameAddressAsFirstAdult)}
                      id={`checkoutSameAddress-${item.id}`}
                      className="font-weight-bold pt-3"
                    />
                  </div>
                )}
              </FormBuilder>
              <Row>
                <Col md={10} className="d-flex justify-content-end">
                  <div className="form-module__lower-label">{t(Keys.requiredFields)}</div>
                </Col>
              </Row>
            </div>
          </ScrollTo>
        );
      })}
    </ContentContainer>
  );
};

export default TravelerForms;
